import { combineReducers, configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "../slices/dashboard.slice";
import InsightsSlice from "../slices/insights.slice";
import uploadimageSlice from "../slices/upload-image.slice";
import uploadBulkimageSlice from "../slices/upload-bulk-image.slice";
import getAllMediaSlice from "../slices/get-all-media.slice";
import underRevisionSlice from "../slices/under-revision.slice";
import rejectedSlice from "../slices/rejected.slice"
import publishedSlice from "../slices/published.slice"
import userInfoSlice from "../slices/get-user-info"
import updateUserInfoSlice from "../slices/put-user-info.slice"
import getsNonSetsSlice from "../slices/gets-non-sets.slice";
import getSetsAssetsSlice from "../slices/get-sets-assets.slice";
import updateSetsByIdSlice from "../slices/update-sets-by-id.slice";
import getSetsSlice from "../slices/get-sets.slice";
import getAllSetsSlice from "../slices/get-all-sets.slice";
import setsAssetsDeleteBulkSlice from "../slices/delete-bulk-sets-assets.slice";
import fileTypeSlice from "../slices/user-avatar-slice";
import getDashboardEarningsSlice from "../slices/get-dashboard-earnings.slice";
import getAllEarningsSlice from "../slices/get-all-earnings-slice";
import DeleteAssetSlice from "../slices/delete-asset-slice";
import allTopSalesSlice from "../slices/get-top-sales-slice";
import resetPasswordSlice from "../slices/post-reset-password-slice";
import userProfileSlice from "../slices/user-avatar-slice";
import userImageSlice from "../slices/avatar-upload-slice"

const reducers = combineReducers({
    dashboard: dashboardSlice,
    insights: InsightsSlice,
    uploadImage: uploadimageSlice,
    bulkUploadImage: uploadBulkimageSlice,
    getAllMedia: getAllMediaSlice,
    underRevision: underRevisionSlice,
    rejected: rejectedSlice,
    published: publishedSlice,
    getUserInfo: userInfoSlice,
    updateUserInfo: updateUserInfoSlice,
    getNonSets: getsNonSetsSlice,
    getSetsAssets: getSetsAssetsSlice,
    updateSetsById: updateSetsByIdSlice,
    getSets: getSetsSlice,
    getAllSets: getAllSetsSlice,
    deleteSetsAssets: setsAssetsDeleteBulkSlice,
    fileType: fileTypeSlice,
    dashboardEarnings: getDashboardEarningsSlice,
    earnings: getAllEarningsSlice,
    deleteAsset: DeleteAssetSlice,
    topSales: allTopSalesSlice,
    resetPassword: resetPasswordSlice,
    userProfile: userProfileSlice,
    userAvatar: userImageSlice


});

const store = configureStore({
    reducer: reducers,
    middleware: ((getDefaultMiddleware) => getDefaultMiddleware()),
});
export default store