import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = () => {

    let userToken = localStorage.getItem("accessToken") ?? null;
    return (

        // userToken?.length ? 
        <Outlet />
        // : <Navigate to="/log-in" />

    )
}

export default PrivateRoute
