

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postProfileImage } from "../../api/create";



const initialState = {
    loading: false,
    storeResponseData: [],
    error: null
}

export const fetchUploadProfile = createAsyncThunk(
    "profile/fetchUploadProfile",
    async (params, { dispatch }) => {
        dispatch(addUploadProfileRequest());
        const response = await postProfileImage(params);
        if (response) {
            dispatch(addUploadProfileData(response?.data));
            return response;
        } else {
            dispatch(addUploadProfileError(response?.error));
            return response;
        }
    }
)

const userProfileSlice = createSlice({
    name: "imageUpload",
    initialState: initialState,
    reducers: {
        addUploadProfileRequest: (state) => {
            state.loading = true
        },
        addUploadProfileData: (state, action) => {
            state.storeResponseData = action.payload;
            state.loading = false;
        },
        addUploadProfileError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default userProfileSlice.reducer;

const { addUploadProfileRequest, addUploadProfileData, addUploadProfileError } = userProfileSlice.actions;


















//  postProfileImage
// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//     fileType: null,
// };

// const fileTypeSlice = createSlice({
//     name: 'fileType',
//     initialState,
//     reducers: {
//         setFileType: (state, action) => {
//             state.fileType = action.payload;
//         },
//         clearFileType: (state) => {
//             state.fileType = null;
//         },
//     },
// });

// export const { setFileType, clearFileType } = fileTypeSlice.actions;

// export const selectFileType = (state) => state.fileType.fileType;

// export default fileTypeSlice.reducer;
