import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserInfo } from '../../redux/slices/get-user-info';
import { updateUserInfo } from '../../redux/slices/put-user-info.slice';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Skeleton from 'react-loading-skeleton';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { fetchUploadProfile } from "../../redux/slices/user-avatar-slice";
import './my-account.css';
import { setImageUrl } from '../../redux/slices/avatar-upload-slice';
import { useNavigate } from 'react-router-dom';
import { hostConfig } from '../../config';
import { RxAvatar } from "react-icons/rx";


const MyAccount = () => {
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [showProfileEdit, setShowProfileEdit] = useState(false);
    const [showPasswordEdit, setShowPasswordEdit] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    // const [profileImageUrl, setProfileImageUrl] = useState('');
    const avatar = useSelector((state) => state.userAvatar.imageUrl);
    const dispatch = useDispatch();
    const data = useSelector((state) => state?.getUserInfo?.storeUserData);
    // const [avatar, setAvatar] = useState(false)
    const handleCloseProfileEdit = () => {
        setShowProfileEdit(false);
        formikProfile.resetForm()
    };
    const handleShowProfileEdit = () => setShowProfileEdit(true);
    const handleClosePasswordEdit = () => setShowPasswordEdit(false);
    const handleShowPasswordEdit = () => setShowPasswordEdit(true);
    const navigate = useNavigate();





    const handleUpload = async (e) => {
        const file = e.target.files[0];
        const fileSizeMB = file.size / 1024 / 1024; // Convert file size to MB

        if (fileSizeMB < 1 || fileSizeMB > 5) {
            toast.error('File size should be between 1MB and 5MB');
            return;
        }

        try {
            await dispatch(fetchUploadProfile({ file })).then((res) => {
                if (res) {
                    toast.success('Profile updated');
                    const newImageUrl = URL.createObjectURL(file);
                    dispatch(setImageUrl(newImageUrl));
                } else {
                    toast.error('Failed to upload profile image');
                }
            });
        } catch (error) {
            toast.error('Failed to upload profile image');
        }
    };





    useEffect(() => {
        dispatch(fetchUserInfo()).then(() => setLoading(false)).then(() => {
        });
    }, []);


    const profileRegExp = /^[A-Za-z]+$/;
    const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()<>?_+\-=`~])[A-Za-z\d!@#$%^&*()<>?_+\-=.`~]{8,}$/;

    const profileValidationSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'First name must be at least 2 characters')
            .required('First name is required')
            .matches(profileRegExp, 'Only alphabets are allowed'),
        lastName: Yup.string()
            .min(1, 'Last name must be at least one character long')
            .required('Last name is required')
            .matches(profileRegExp, 'Only alphabets are allowed'),
    });

    const passwordValidationSchema = Yup.object().shape({
        oldPassword: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('Password is required')
            .matches(passwordRegExp, 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'),
        newPassword: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('New password is required')
            .matches(passwordRegExp, 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character')
            .notOneOf([Yup.ref('oldPassword'), null], 'New password cannot be the same as old password'),
        confirmNewPassword: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('Confirm password is required')
            .matches(passwordRegExp, 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character')
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    });

    const formikProfile = useFormik({
        initialValues: {
            firstName: data?.seller?.firstName || '',
            lastName: data?.seller?.lastName || '',
        },
        validationSchema: profileValidationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            dispatch(updateUserInfo({ id: data?.seller?.id, params: values }))
                .then(() => dispatch(fetchUserInfo()))
                .then(() => {
                    setShowProfileEdit(false);
                    // setCurrentUser(values);
                    formikProfile.resetForm();
                });
        },
    });

    const spaceValidate = (event) => {
        const { name, value } = event.target;
        const newValue = value.replace(/^\s+/g, '').replace(/\s+/g, ' ');
        formikProfile.setFieldValue(name, newValue);
    };

    const passwordSpaceValidate = (event) => {
        const { name, value } = event.target;
        const newValue = value.replace(/\s/g, '');
        formikPassword.setFieldValue(name, newValue);
    };



    const formikPassword = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        },
        validationSchema: passwordValidationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            dispatch(updateUserInfo({
                id: data?.seller?.id,
                params: {
                    firstName: data?.seller?.firstName,
                    lastName: data?.seller?.lastName,
                    oldPassword: values?.oldPassword,
                    password: values?.newPassword,
                }
            })).then((res) => {
                if (res?.payload?.data) {
                    dispatch(fetchUserInfo());
                    setShowPasswordEdit(false);
                    formikPassword.resetForm();
                } else {
                    toast.error(res?.payload?.messages);
                }
            });
        },
    });

    return (
        <div className='container p-1 md:p-5 '>
            <p className='lg:ms-[0px] ms-[10px] my-account-text mt-5'>
                My Account
            </p>
            <div className='profile-wrapper'>
                <div className='d-flex items-center profile-img justify-center'>


                    {avatar ?
                        <img className="w-[100%] h-[100%] object-cover" src={avatar} alt="" /> : !imageError ?
                            <img onError={() => setImageError(true)} className="w-[100%] h-[100%] object-cover" src={hostConfig?.HOSTING_URL + `/seller/profile/${data?.seller?.id}`} alt="" /> :
                            <RxAvatar className='w-full h-full text-gray-600' />

                    }
                </div>
                <div className=''>
                    <input
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        onChange={handleUpload}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                    />
                    <button
                        type="button"
                        className="text-capitalize edit-profile-picture"
                        onClick={() => fileInputRef.current.click()}
                    >
                        Edit profile picture
                    </button>
                </div>
            </div>
            <div className='card my-5 w-[100%] md:w-[60%]'>
                <div className='card-body d-flex align-items-center border profile py-4 '>
                    <p className='col-lg-4 col-4 ms-[0px] lg:ms-[50px] mb-0 text-[#666666]'>First Name</p>
                    <p className='col-lg-4 col-7 mb-0 text-[#262626]'>
                        {loading ? <Skeleton width={120} /> : data?.seller?.firstName}
                    </p>
                    <div className='col-lg-2'></div>
                    <div className='col-lg-2 cursor-pointer text-[#ed652b]' onClick={handleShowProfileEdit}>Edit</div>
                </div>
                <div className='card-body d-flex align-items-center border profile py-4 '>
                    <p className='col-lg-4 col-4 ms-[0px] lg:ms-[50px] mb-0 text-[#666666]'>Last Name</p>
                    <p className='col-lg-4 col-7 mb-0 text-[#262626]'>
                        {loading ? <Skeleton width={80} /> : data?.seller?.lastName}
                    </p>
                </div>
                <div className='card-body d-flex align-items-center border border-b-3 profile py-4 '>
                    <p className='col-lg-4 col-4 ms-[0px] lg:ms-[50px] mb-0 text-[#666666]'>Email</p>
                    <p className='col-lg-4 col-7 mb-0 text-[#262626]'>
                        {loading ? <Skeleton width={200} /> : data?.seller?.email}
                    </p>
                </div>
                <div className='card-body d-flex align-items-center border profile py-4 '>
                    <p className='col-lg-4 col-4 ms-[0px] lg:ms-[50px] mb-0 text-[#666666]'>Password</p>
                    <p className='col-lg-4 col-7 mb-0 text-[#262626]'>**************</p>
                    <div className='col-lg-2'></div>
                    <div className='col-lg-2 cursor-pointer text-[#ed652b]' onClick={handleShowPasswordEdit}>Edit</div>
                </div>
            </div>

            <Modal
                show={showProfileEdit}
                onHide={handleCloseProfileEdit}
                backdrop="static"
                keyboard={false}
            >
                <form action="" onSubmit={formikProfile.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title className=' text-capitalize'>Edit Profile</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className='d-flex flex-column'>
                        <label htmlFor="firstName">First Name : </label>
                        <input onChange={(e) => spaceValidate(e)}
                            value={formikProfile.values.firstName} className='py-1   border-2 outline-none border-[#7f8c8d] rounded px-2' type="text" name="firstName" id="firstName" />
                        {formikProfile.touched.firstName && formikProfile.errors.firstName && (
                            <div className='text-danger text-[12px] '>{formikProfile.errors.firstName}</div>
                        )}

                        <label htmlFor="lastName" className='mt-3'>Last Name : </label>

                        <input onChange={(e) => spaceValidate(e)} value={formikProfile.values.lastName} className='py-1 px-2 border-2 outline-none border-[#7f8c8d] rounded ' type="text" name="lastName" id="lastName" />
                        {formikProfile.touched.lastName && formikProfile.errors.lastName && (
                            <div className='text-danger text-[12px]'>{formikProfile.errors.lastName}</div>
                        )}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseProfileEdit}>
                            Close
                        </Button>
                        <Button variant="" style={{ backgroundColor: "#ed652b" }} type="submit" className=' text-white'>Save Changes</Button>
                    </Modal.Footer>
                </form>
            </Modal>





            {/* Model pop-up for password */}

            <Modal
                show={showPasswordEdit}
                onHide={handleClosePasswordEdit}
                backdrop="static"
                keyboard={false}
            >
                <form action="" onSubmit={formikPassword.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title className=' text-capitalize'>Edit Password</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className='d-flex flex-column'>

                        <label htmlFor="oldPassword"> Old Password : </label>


                        <div className='position-relative'>
                            <input onChange={(e) => passwordSpaceValidate(e)}
                                value={formikPassword.values.oldPassword} className='py-1 w-full  border-2 outline-none border-[#7f8c8d] rounded px-2' type={showOldPassword ? "text" : "password"} name="oldPassword" id="oldPassword" />
                            {showOldPassword ? <FaRegEye className='position-absolute top-3 right-2 cursor-pointer' onClick={() => setShowOldPassword(!showOldPassword)} /> :
                                <FaRegEyeSlash className='position-absolute top-3 right-2 cursor-pointer' onClick={() => setShowOldPassword(!showOldPassword)} />
                            }
                        </div>

                        {formikPassword.touched.oldPassword && formikPassword.errors.oldPassword && (
                            <div className='text-danger text-[12px]'>{formikPassword.errors.oldPassword}</div>
                        )}




                        <label className='mt-3' htmlFor="newPassword">New Password : </label>
                        <div className='position-relative'>
                            <input onChange={(e) => passwordSpaceValidate(e)} value={formikPassword.values.newPassword} className='py-1 px-2 border-2 outline-none border-[#7f8c8d] w-full rounded ' type={showNewPassword ? "text" : "password"} name="newPassword" id="newPassword" />

                            {showNewPassword ? <FaRegEye className='position-absolute top-3 right-2 cursor-pointer' onClick={() => setShowNewPassword(!showNewPassword)} /> :
                                <FaRegEyeSlash className='position-absolute top-3 right-2 cursor-pointer' onClick={() => setShowNewPassword(!showNewPassword)} />
                            }

                        </div>

                        {formikPassword.touched.newPassword && formikPassword.errors.newPassword && (
                            <div className='text-danger text-[12px]'>{formikPassword.errors.newPassword}</div>
                        )}





                        <label className='mt-3' htmlFor="confirmNewPassword">Confirm New Password : </label>
                        <div className='position-relative'>
                            <input onChange={(e) => passwordSpaceValidate(e)} value={formikPassword.values.confirmNewPassword} className='py-1 px-2 border-2 outline-none border-[#7f8c8d] w-full rounded ' type={showConfirmNewPassword ? "text" : "password"} name="confirmNewPassword" id="confirmNewPassword" />

                            {showConfirmNewPassword ? <FaRegEye className='position-absolute top-3 right-2 cursor-pointer' onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} /> :
                                <FaRegEyeSlash className='position-absolute top-3 right-2 cursor-pointer' onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} />
                            }

                        </div>
                        {formikPassword.touched.confirmNewPassword && formikPassword.errors.confirmNewPassword && (
                            <div className='text-danger text-[12px]'>{formikPassword.errors.confirmNewPassword}</div>
                        )}


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { handleClosePasswordEdit(); formikPassword.resetForm() }}>
                            Close
                        </Button>
                        <Button type="submit" variant="" style={{ backgroundColor: "#ed652b", color: "white" }}>Save Changes</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
};

export default MyAccount;
