import React from "react";
// import { TfiUpload } from "react-icons/tfi";
import { Link } from "react-router-dom";
import Upload from "../../assets/upload.svg";
import Button from "../button";
import "./drag.css";
const Index = () => {
  return (
    <div>
      <div
        className="drag-card position-relative d-flex align-items-center justify-content-center"
      
        
      >
        {/* <input
          type="file"
          id="file"
          name="file"
          //   onBlur={formik.handleBlur}
          className="input-import"
          onChange={(event) => {
            // formik.setFieldValue("file", event.currentTarget.files[0]);
          }}
        /> */}
        
        <div className="text-center">
          <span className="browse-text">Click here to </span>
          <br />
          &nbsp; 
           <span className="browse-text">Upload Images</span>
       
          <Link
            to="/image-upload"
            onClick={(e) => e.stopPropagation()}
            style={{ textDecoration: "none" }}
          >
            <Button buttonStyles="supported-text text-center d-flex align-items-center">
              <img src={Upload} alt="upload" />
              Upload
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Index;
