/* eslint-disable */
/** ********************************* Import URL ************************************* */
import { hostConfig } from "../config"; // env
import { URL_CONSTANTS } from "./urls";

/** ****************************** Response Handler *********************************** */

const token = localStorage.getItem("accessToken");


const reLogin = () => {
  if (!localStorage.getItem("loggedUser")) {
    return { error: "Invalid emailId or password" };
  } else if (localStorage.getItem("accessExpiry")) {
    const accessExipryTime = new Date(localStorage.getItem("accessExipry"));
    const currentDate = new Date();
    if (accessExipryTime < currentDate) {
      const refreshToken = localStorage.getItem("refreshToken");
      const params = { refreshToken: refreshToken };
      return fetch(`${hostConfig.TEST_URL}${URL_CONSTANTS.refreshToken}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(params),
      })
        .then((response) => {
          return response;
        })
        .then(async (resp) => {
          const res = await resp.json();
          const accessToken = res.access.token;
          const refreshToken = res.refresh.token;
          const accessExpiry = res.access.expires;
          const refreshExpiry = res.refresh.expires;
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("accessExpiry", accessExpiry);
          localStorage.setItem("refreshExpiry", refreshExpiry);
          window.location.reload();
        })
        .catch((err) => {
          errorHandler(err);
        });
    }
  } else {
    localStorage.clear();
    window.location.href = "/";
  }
};
const responseStatusHandler = (response) => {
  switch (response.status) {
    case 400:
      return response;
    case 401:
      // return { error: "Payment Required" };
      window.location.href = "/log-in";
    case 402:
      return { error: "Payment Required" };
    case 403:
      return { error: "Forbidden" };
    case 404:
      return { error: "Not Found" };
    case 405:
      return { error: "Method Not Allowed" };
    case 406:
      return { error: "Not Acceptable" };
    case 408:
      return { error: "Request Timeout" };
    case 409:
      return response;
    case 410:
      return { error: "permanently deleted from server" };
    case 500:
      return { error: "Internal Server Error" };
    case 501:
      return { error: "Not Implemented" };
    case 502:
      return { error: "Bad Gateway" };
    case 503:
      return { error: "Service Unavailable" };
    case 504:
      return { error: " Gateway Timeout" };
    case 511:
      return { error: " Network Authentication Required" };
    case 200:
    case 201:
      return response;
    default:
      return false;
  }
};

/** ****************************** Error Handler *********************************** */
const errorHandler = (error) => error;

/** ****************************** Create Api *********************************** */
export const postDataApiForm = (requestUrl, params, service) => {
  return fetch(
    `${service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }
  )
    .then((response) => {
      if (!response.ok) {
        // throw new Error("Network response was not ok");
        return response.json(); // Parse response body as JSON
      }
      // Return response object to chain with subsequent .then()
      return response.json(); // Parse response body as JSON

    })
    .catch((err) => {
      errorHandler(err);

    });

};
export const postDataApiFormData = (requestUrl, params, service) => {

  // Create a new FormData object
  const formData = new FormData();

  // Append each key-value pair from params to the FormData object
  for (const key in params) {
    formData.append(key, params[key]);
  }

  return fetch(
    `${service === "JSONSERVER"
      ? hostConfig.JSON_SERVER_URL
      : hostConfig.API_URL
    }${requestUrl}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData, // Use formData as the body
    }
  )
    .then((response) => {
      if (!response.ok) {
        // throw new Error("Network response was not ok");
        return response.json(); // Parse response body as JSON
      }
      // Return response object to chain with subsequent .then()
      return response.json(); // Parse response body as JSON
    })
    .catch((err) => {
      errorHandler(err);
    });
};


export const postDataApi = (requestUrl, params, service) => {
  return fetch(
    `${service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    }
  )
    .then((response) => {
      return responseStatusHandler(response);
    })
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400 || result.status === 409
        ? result.json()
        : result
    )
    .catch((err) => {
      errorHandler(err);
    });
};

export const postDataApiByID = (requestUrl, params, paramId) => {
  let getParams = "?";
  if (params?.type) {
    getParams += `&type=${params.type}`;
  }
  return fetch(`${hostConfig.API_URL}${requestUrl}/${paramId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400 || result.status === 409
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};

/** ****************************** View with query Api *********************************** */
export const getListByApi = (requestUrl, params, service) => {
  let getParams = "?";
  if (
    params &&
    params.limit &&
    params.limit !== null &&
    params.limit !== undefined
  ) {
    getParams += `limit=${params.limit}`;
  }

  if (
    params &&
    params.unassigned_filter &&
    params.unassigned_filter !== null &&
    params.unassigned_filter !== undefined
  ) {
    getParams += `&unassigned_filter=${params.unassigned_filter}`;
  }

  if (params && params.assignTo) {
    getParams += `&assignTo=${params.assignTo}`;
  }
  if (params && params.taskType) {
    getParams += `&taskType=${params.taskType}`;
  }
  if (params?.type) {
    getParams += `&type=${params.type}`;
  }
  if (params?.status) {
    getParams += `&status=${params.status}`;
  }
  if (params && params.workLocation) {
    getParams += `&workLocation=${params.workLocation}`;
  }
  if (params && params.candidateId) {
    getParams += `candidateId=${params.candidateId}`;
  }
  if (params && params.category) {
    getParams += `&category=${params.category}`;
  }
  if (params && params.details) {
    getParams += `&details=${params.details}`;
  }
  if (params && params.max_revenue) {
    getParams += `&max_revenue=${params.max_revenue}`;
  }
  if (params && params.min_revenue) {
    getParams += `&min_revenue=${params.min_revenue}`;
  }
  if (
    params &&
    params.revenue &&
    params.revenue !== null &&
    params.revenue !== undefined
  ) {
    getParams += `&revenue=${params.revenue}`;
  }
  if (
    params &&
    params.assign_to &&
    params.assign_to !== null &&
    params.assign_to !== undefined
  ) {
    getParams += `&assign_to=${params.assign_to}`;
  }

  if (
    params &&
    params.assign_to_filter &&
    params.assign_to_filter !== null &&
    params.assign_to_filter !== undefined
  ) {
    getParams += `&assign_to_filter=${params.assign_to_filter}`;
  }
  if (
    params &&
    params.status_filter &&
    params.status_filter !== null &&
    params.status_filter !== undefined
  ) {
    getParams += `&status_filter=${params.status_filter}`;
  }

  if (
    params &&
    params.lead_source &&
    params.lead_source !== null &&
    params.lead_source !== undefined
  ) {
    getParams += `&lead_source=${params.lead_source}`;
  }
  if (
    params &&
    params.lead_score &&
    params.lead_score !== null &&
    params.lead_score !== undefined
  ) {
    getParams += `&lead_score=${params.lead_score}`;
  }
  if (
    params &&
    params.country &&
    params.country !== null &&
    params.country !== undefined
  ) {
    getParams += `&country=${params.country}`;
  }
  if (
    params &&
    params.organization &&
    params.organization !== null &&
    params.organization !== undefined
  ) {
    getParams += `&organization=${params.organization}`;
  }
  if (
    params &&
    params.job_title &&
    params.job_title !== null &&
    params.job_title !== undefined
  ) {
    getParams += `&job_title=${params.job_title}`;
  }
  if (
    params &&
    params.industry &&
    params.industry !== null &&
    params.industry !== undefined
  ) {
    getParams += `&industry=${params.industry}`;
  }
  if (
    params &&
    params.assign_members &&
    params.assign_members !== null &&
    params.assign_members !== undefined
  ) {
    getParams += `&assign_members=${params.assign_members}`;
  }
  if (
    params &&
    params.leads &&
    params.leads !== null &&
    params.leads !== undefined
  ) {
    getParams += `&leads=${params.leads}`;
  }
  if (
    params &&
    params.priority &&
    params.priority !== null &&
    params.priority !== undefined
  ) {
    getParams += `&priority=${params.priority}`;
  }
  if (
    params &&
    params.deal_service_type &&
    params.deal_service_type !== null &&
    params.deal_service_type !== undefined
  ) {
    getParams += `&deal_service_type=${params.deal_service_type}`;
  }
  if (
    params &&
    params.export_option &&
    params.export_option !== null &&
    params.export_option !== undefined
  ) {
    getParams += `&export_option=${params.export_option}`;
  }
  if (
    params &&
    params.from_date &&
    params.from_date !== null &&
    params.from_date !== undefined
  ) {
    getParams += `&from_date=${params.from_date}`;
  }
  if (
    params &&
    params.to_date &&
    params.to_date !== null &&
    params.to_date !== undefined
  ) {
    getParams += `&to_date=${params.to_date}`;
  }
  if (params && params.category_path) {
    getParams += `&category_path=${params.category_path}`;
  }
  if (
    params &&
    params.company_size &&
    params.company_size !== null &&
    params.company_size !== undefined
  ) {
    getParams += `&company_size=${params.company_size}`;
  }
  if (
    params &&
    params.is_invest &&
    params.is_invest !== null &&
    params.is_invest !== undefined
  ) {
    getParams += `&is_invest=${params.is_invest}`;
  }
  if (params && params.category_type) {
    getParams += `&category_type=${params.category_type}`;
  }
  if (params && params.price) {
    getParams += `&price=${params.price}`;
  }
  if (params && params.page) {
    getParams += `&page=${params.page}`;
  }
  if (
    params &&
    params.duration_filter &&
    params.duration_filter !== null &&
    params.duration_filter !== undefined
  ) {
    getParams += `&duration_filter=${params.duration_filter}`;
  }

  if (params && params.response_type) {
    getParams += `&response_type=${params.response_type}`;
  }

  if (
    params &&
    params.token &&
    params.token !== null &&
    params.token !== undefined
  ) {
    getParams += `token=${params.token}`;
  }

  if (
    params &&
    params.currentPage &&
    params.currentPage !== null &&
    params.currentPage !== undefined
  ) {
    getParams += `&page=${params.currentPage}`;
  }

  if (
    params &&
    params.organizationName !== null &&
    params.organizationName !== undefined
  ) {
    getParams += `&organizationName=${params.organizationName}`;
  }

  if (
    params &&
    params.sortBy &&
    params.sortBy !== null &&
    params.sortBy !== undefined
  ) {
    getParams += `&sortBy=${params.sortBy}`;
  }
  if (
    params &&
    params.category_id &&
    params.category_id !== null &&
    params.category_id !== undefined
  ) {
    getParams += `&category_id=${params.category_id}`;
  }
  if (params && params._id && params._id !== null && params._id !== undefined) {
    getParams += `&_id=${params._id}`;
  }

  if (
    params &&
    params.user_id &&
    params.user_id !== null &&
    params.user_id !== undefined
  ) {
    getParams += `&user_id=${params.user_id}`;
  }
  if (
    params &&
    params.order_id &&
    params.order_id !== null &&
    params.order_id !== undefined
  ) {
    getParams += `&order_id=${params.order_id}`;
  }
  if (
    params &&
    params.product_price &&
    params.product_price !== null &&
    params.product_price !== undefined
  ) {
    getParams += `&product_price=${params.product_price}`;
  }
  if (
    params &&
    params.search &&
    params.search !== null &&
    params.search !== undefined
  ) {
    getParams += `&search=${params.search}`;
  }
  if (
    params &&
    params.isActive !== null &&
    params.isActive !== "" &&
    params.isActive !== undefined
  ) {
    getParams += `&isActive=${params.isActive}`;
  }

  if (params && params.role !== null && params.role !== undefined) {
    getParams += `&role=${params.role}`;
  }

  if (
    params &&
    params.action !== null &&
    params.action !== "" &&
    params.action !== undefined
  ) {
    getParams += `&action=${params.action}`;
  }
  if (
    params &&
    params.lastkey !== null &&
    params.lastkey !== "" &&
    params.lastkey !== undefined
  ) {
    getParams += `&lastkey=${params.lastkey}`;
  }

  if (
    params &&
    params.orderStatus !== null &&
    params.orderStatus !== "" &&
    params.orderStatus !== undefined
  ) {
    getParams += `&orderStatus=${params.orderStatus}`;
  }
  if (
    params &&
    params.productType !== null &&
    params.productType !== "" &&
    params.productType !== undefined
  ) {
    getParams += `&productType=${params.productType}`;
  }
  if (
    params &&
    params.order_status !== null &&
    params.order_status !== "" &&
    params.order_status !== undefined
  ) {
    getParams += `&order_status=${params.order_status}`;
  }

  if (
    params &&
    params.shipped_status !== null &&
    params.shipped_status !== "" &&
    params.shipped_status !== undefined
  ) {
    getParams += `&shipped_status=${params.shipped_status}`;
  }

  if (
    params &&
    params.outForDelivery_status !== null &&
    params.outForDelivery_status !== "" &&
    params.outForDelivery_status !== undefined
  ) {
    getParams += `&outForDelivery_status=${params.outForDelivery_status}`;
  }

  if (
    params &&
    params.delivered_status !== null &&
    params.delivered_status !== "" &&
    params.delivered_status !== undefined
  ) {
    getParams += `&delivered_status=${params.delivered_status}`;
  }

  if (
    params &&
    params.sort !== null &&
    params.sort !== "" &&
    params.sort !== undefined
  ) {
    getParams += `&sort=${params.sort}`;
  }
  if (
    params &&
    params.min_price !== null &&
    params.min_price !== "" &&
    params.min_price !== undefined
  ) {
    getParams += `&min_price=${params.min_price}`;
  }

  if (
    params &&
    params.max_price !== null &&
    params.max_price !== "" &&
    params.max_price !== undefined
  ) {
    getParams += `&max_price=${params.max_price}`;
  }

  // if (
  //   params &&
  //   params.category_path !== null &&
  //   params.category_path !== "" &&
  //   params.category_path !== undefined
  // ) {
  //   getParams += `&category_path=${params.category_path}`;
  // }
  if (
    params &&
    params.latest !== null &&
    params.latest !== "" &&
    params.latest !== undefined
  ) {
    getParams += `&latest=${params.latest}`;
  }
  if (
    params &&
    params.alphabetsort !== null &&
    params.alphabetsort !== "" &&
    params.alphabetsort !== undefined
  ) {
    getParams += `&alphabetsort=${params.alphabetsort}`;
  }
  if (
    params &&
    params.quantity_sort !== null &&
    params.quantity_sort !== "" &&
    params.quantity_sort !== undefined
  ) {
    getParams += `&quantity_sort=${params.quantity_sort}`;
  }
  if (
    params &&
    params.searchvalue !== null &&
    params.searchvalue !== "" &&
    params.searchvalue !== undefined
  ) {
    getParams += `&searchvalue=${params.searchvalue}`;
  }
  if (
    params &&
    params.search_value !== null &&
    params.search_value !== "" &&
    params.search_value !== undefined
  ) {
    getParams += `&search_value=${params.search_value}`;
  }
  if (
    params &&
    params.year !== null &&
    params.year !== "" &&
    params.year !== undefined
  ) {
    getParams += `&year=${params.year}`;
  }
  if (
    params &&
    params.orderyear !== null &&
    params.orderyear !== "" &&
    params.orderyear !== undefined
  ) {
    getParams += `&orderyear=${params.orderyear}`;
  }

  if (
    params &&
    params.off_percentage !== null &&
    params.off_percentage !== "" &&
    params.off_percentage !== undefined
  ) {
    getParams += `&off_percentage=${params.off_percentage}`;
  }
  if (
    params &&
    params.min_off_percentage !== null &&
    params.min_off_percentage !== "" &&
    params.min_off_percentage !== undefined
  ) {
    getParams += `&min_off_percentage=${params.min_off_percentage}`;
  }
  if (
    params &&
    params.max_off_percentage !== null &&
    params.max_off_percentage !== "" &&
    params.max_off_percentage !== undefined
  ) {
    getParams += `&max_off_percentage=${params.max_off_percentage}`;
  }
  if (
    params &&
    params.rating !== null &&
    params.rating !== "" &&
    params.rating !== undefined
  ) {
    getParams += `&rating=${params.rating}`;
  }
  if (
    params &&
    params.searchuser !== null &&
    params.searchuser !== "" &&
    params.searchuser !== undefined
  ) {
    getParams += `&searchuser=${params.searchuser}`;
  }
  if (
    params &&
    params.sort_key !== null &&
    params.sort_key !== "" &&
    params.sort_key !== undefined
  ) {
    getParams += `&sort_key=${params.sort_key}`;
  }
  if (
    params &&
    params.sort_flag !== null &&
    params.sort_flag !== "" &&
    params.sort_flag !== undefined
  ) {
    getParams += `&sort_flag=${params.sort_flag}`;
  }
  if (
    params &&
    params.region !== null &&
    params.region !== "" &&
    params.region !== undefined
  ) {
    getParams += `&region=${params.region}`;
  }

  if (
    params &&
    params.month &&
    params.years
  ) {
    getParams += `month=${params.month}&year=${params.years}&lifetime=false`;
  }

  return fetch(`${hostConfig.API_URL}${requestUrl}${getParams}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>

      result.status === 200 || result.status === 201 || result.status === 400 || result.status === 409 || result.status === 401
        ? result.json()
        : result

    )
    .catch((error) => {
      errorHandler(error);
    });

};

export const getListByApiFile = (requestUrl, params, service) => {
  let getParams = "?";
  if (
    params &&
    params.limit &&
    params.limit !== null &&
    params.limit !== undefined
  ) {
    getParams += `limit=${params.limit}`;
  }
  if (params && params.assignTo) {
    getParams += `&assignTo=${params.assignTo}`;
  }
  if (params && params.taskType) {
    getParams += `&taskType=${params.taskType}`;
  }
  if (params?.type) {
    getParams += `&type=${params.type}`;
  }
  if (params?.status) {
    getParams += `&status=${params.status}`;
  }
  if (params && params.workLocation) {
    getParams += `&workLocation=${params.workLocation}`;
  }
  if (params && params.candidateId) {
    getParams += `candidateId=${params.candidateId}`;
  }
  if (params && params.category) {
    getParams += `&category=${params.category}`;
  }
  if (params && params.details) {
    getParams += `&details=${params.details}`;
  }
  if (params && params.max_revenue) {
    getParams += `&max_revenue=${params.max_revenue}`;
  }
  if (params && params.min_revenue) {
    getParams += `&min_revenue=${params.min_revenue}`;
  }
  if (
    params &&
    params.revenue &&
    params.revenue !== null &&
    params.revenue !== undefined
  ) {
    getParams += `&revenue=${params.revenue}`;
  }
  if (
    params &&
    params.lead_source &&
    params.lead_source !== null &&
    params.lead_source !== undefined
  ) {
    getParams += `&lead_source=${params.lead_source}`;
  }
  if (
    params &&
    params.lead_score &&
    params.lead_score !== null &&
    params.lead_score !== undefined
  ) {
    getParams += `&lead_score=${params.lead_score}`;
  }
  if (
    params &&
    params.country &&
    params.country !== null &&
    params.country !== undefined
  ) {
    getParams += `&country=${params.country}`;
  }
  if (
    params &&
    params.organization &&
    params.organization !== null &&
    params.organization !== undefined
  ) {
    getParams += `&organization=${params.organization}`;
  }
  if (
    params &&
    params.job_title &&
    params.job_title !== null &&
    params.job_title !== undefined
  ) {
    getParams += `&job_title=${params.job_title}`;
  }
  if (
    params &&
    params.industry &&
    params.industry !== null &&
    params.industry !== undefined
  ) {
    getParams += `&industry=${params.industry}`;
  }
  if (
    params &&
    params.assign_members &&
    params.assign_members !== null &&
    params.assign_members !== undefined
  ) {
    getParams += `&assign_members=${params.assign_members}`;
  }
  if (
    params &&
    params.leads &&
    params.leads !== null &&
    params.leads !== undefined
  ) {
    getParams += `&leads=${params.leads}`;
  }
  if (
    params &&
    params.priority &&
    params.priority !== null &&
    params.priority !== undefined
  ) {
    getParams += `&priority=${params.priority}`;
  }
  if (
    params &&
    params.deal_service_type &&
    params.deal_service_type !== null &&
    params.deal_service_type !== undefined
  ) {
    getParams += `&deal_service_type=${params.deal_service_type}`;
  }
  if (
    params &&
    params.export_option &&
    params.export_option !== null &&
    params.export_option !== undefined
  ) {
    getParams += `&export_option=${params.export_option}`;
  }
  if (
    params &&
    params.from_date &&
    params.from_date !== null &&
    params.from_date !== undefined
  ) {
    getParams += `&from_date=${params.from_date}`;
  }
  if (
    params &&
    params.to_date &&
    params.to_date !== null &&
    params.to_date !== undefined
  ) {
    getParams += `&to_date=${params.to_date}`;
  }
  if (params && params.category_path) {
    getParams += `&category_path=${params.category_path}`;
  }
  if (params && params.company_size) {
    getParams += `&company_size=${params.company_size &&
      params.company_size !== null &&
      params.company_size !== undefined
      }`;
  }
  if (
    params &&
    params.is_invest &&
    params.is_invest !== null &&
    params.is_invest !== undefined
  ) {
    getParams += `&is_invest=${params.is_invest}`;
  }
  if (params && params.category_type) {
    getParams += `&category_type=${params.category_type}`;
  }
  if (params && params.price) {
    getParams += `&price=${params.price}`;
  }
  if (params && params.page) {
    getParams += `&page=${params.page}`;
  }
  if (
    params &&
    params.duration_filter &&
    params.duration_filter !== null &&
    params.duration_filter !== undefined
  ) {
    getParams += `&duration_filter=${params.duration_filter}`;
  }

  if (params && params.response_type) {
    getParams += `&response_type=${params.response_type}`;
  }

  if (
    params &&
    params.token &&
    params.token !== null &&
    params.token !== undefined
  ) {
    getParams += `token=${params.token}`;
  }

  if (
    params &&
    params.currentPage &&
    params.currentPage !== null &&
    params.currentPage !== undefined
  ) {
    getParams += `&page=${params.currentPage}`;
  }

  if (
    params &&
    params.organizationName !== null &&
    params.organizationName !== undefined
  ) {
    getParams += `&organizationName=${params.organizationName}`;
  }

  if (
    params &&
    params.sortBy &&
    params.sortBy !== null &&
    params.sortBy !== undefined
  ) {
    getParams += `&sortBy=${params.sortBy}`;
  }
  if (
    params &&
    params.category_id &&
    params.category_id !== null &&
    params.category_id !== undefined
  ) {
    getParams += `&category_id=${params.category_id}`;
  }
  if (params && params._id && params._id !== null && params._id !== undefined) {
    getParams += `&_id=${params._id}`;
  }

  if (
    params &&
    params.user_id &&
    params.user_id !== null &&
    params.user_id !== undefined
  ) {
    getParams += `&user_id=${params.user_id}`;
  }
  if (
    params &&
    params.order_id &&
    params.order_id !== null &&
    params.order_id !== undefined
  ) {
    getParams += `&order_id=${params.order_id}`;
  }
  if (
    params &&
    params.product_price &&
    params.product_price !== null &&
    params.product_price !== undefined
  ) {
    getParams += `&product_price=${params.product_price}`;
  }
  if (
    params &&
    params.search &&
    params.search !== null &&
    params.search !== undefined
  ) {
    getParams += `&search=${params.search}`;
  }
  if (
    params &&
    params.isActive !== null &&
    params.isActive !== "" &&
    params.isActive !== undefined
  ) {
    getParams += `&isActive=${params.isActive}`;
  }

  if (params && params.role !== null && params.role !== undefined) {
    getParams += `&role=${params.role}`;
  }

  if (
    params &&
    params.action !== null &&
    params.action !== "" &&
    params.action !== undefined
  ) {
    getParams += `&action=${params.action}`;
  }
  if (
    params &&
    params.lastkey !== null &&
    params.lastkey !== "" &&
    params.lastkey !== undefined
  ) {
    getParams += `&lastkey=${params.lastkey}`;
  }

  if (
    params &&
    params.orderStatus !== null &&
    params.orderStatus !== "" &&
    params.orderStatus !== undefined
  ) {
    getParams += `&orderStatus=${params.orderStatus}`;
  }
  if (
    params &&
    params.productType !== null &&
    params.productType !== "" &&
    params.productType !== undefined
  ) {
    getParams += `&productType=${params.productType}`;
  }
  if (
    params &&
    params.order_status !== null &&
    params.order_status !== "" &&
    params.order_status !== undefined
  ) {
    getParams += `&order_status=${params.order_status}`;
  }

  if (
    params &&
    params.shipped_status !== null &&
    params.shipped_status !== "" &&
    params.shipped_status !== undefined
  ) {
    getParams += `&shipped_status=${params.shipped_status}`;
  }

  if (
    params &&
    params.outForDelivery_status !== null &&
    params.outForDelivery_status !== "" &&
    params.outForDelivery_status !== undefined
  ) {
    getParams += `&outForDelivery_status=${params.outForDelivery_status}`;
  }

  if (
    params &&
    params.delivered_status !== null &&
    params.delivered_status !== "" &&
    params.delivered_status !== undefined
  ) {
    getParams += `&delivered_status=${params.delivered_status}`;
  }

  if (
    params &&
    params.sort !== null &&
    params.sort !== "" &&
    params.sort !== undefined
  ) {
    getParams += `&sort=${params.sort}`;
  }
  if (
    params &&
    params.min_price !== null &&
    params.min_price !== "" &&
    params.min_price !== undefined
  ) {
    getParams += `&min_price=${params.min_price}`;
  }

  if (
    params &&
    params.max_price !== null &&
    params.max_price !== "" &&
    params.max_price !== undefined
  ) {
    getParams += `&max_price=${params.max_price}`;
  }

  // if (
  //   params &&
  //   params.category_path !== null &&
  //   params.category_path !== "" &&
  //   params.category_path !== undefined
  // ) {
  //   getParams += `&category_path=${params.category_path}`;
  // }
  if (
    params &&
    params.latest !== null &&
    params.latest !== "" &&
    params.latest !== undefined
  ) {
    getParams += `&latest=${params.latest}`;
  }
  if (
    params &&
    params.alphabetsort !== null &&
    params.alphabetsort !== "" &&
    params.alphabetsort !== undefined
  ) {
    getParams += `&alphabetsort=${params.alphabetsort}`;
  }
  if (
    params &&
    params.quantity_sort !== null &&
    params.quantity_sort !== "" &&
    params.quantity_sort !== undefined
  ) {
    getParams += `&quantity_sort=${params.quantity_sort}`;
  }
  if (
    params &&
    params.searchvalue !== null &&
    params.searchvalue !== "" &&
    params.searchvalue !== undefined
  ) {
    getParams += `&searchvalue=${params.searchvalue}`;
  }
  if (
    params &&
    params.search_value !== null &&
    params.search_value !== "" &&
    params.search_value !== undefined
  ) {
    getParams += `&search_value=${params.search_value}`;
  }
  if (
    params &&
    params.year !== null &&
    params.year !== "" &&
    params.year !== undefined
  ) {
    getParams += `&year=${params.year}`;
  }
  if (
    params &&
    params.orderyear !== null &&
    params.orderyear !== "" &&
    params.orderyear !== undefined
  ) {
    getParams += `&orderyear=${params.orderyear}`;
  }

  if (
    params &&
    params.off_percentage !== null &&
    params.off_percentage !== "" &&
    params.off_percentage !== undefined
  ) {
    getParams += `&off_percentage=${params.off_percentage}`;
  }
  if (
    params &&
    params.min_off_percentage !== null &&
    params.min_off_percentage !== "" &&
    params.min_off_percentage !== undefined
  ) {
    getParams += `&min_off_percentage=${params.min_off_percentage}`;
  }
  if (
    params &&
    params.max_off_percentage !== null &&
    params.max_off_percentage !== "" &&
    params.max_off_percentage !== undefined
  ) {
    getParams += `&max_off_percentage=${params.max_off_percentage}`;
  }
  if (
    params &&
    params.rating !== null &&
    params.rating !== "" &&
    params.rating !== undefined
  ) {
    getParams += `&rating=${params.rating}`;
  }
  if (
    params &&
    params.searchuser !== null &&
    params.searchuser !== "" &&
    params.searchuser !== undefined
  ) {
    getParams += `&searchuser=${params.searchuser}`;
  }
  if (
    params &&
    params.sort_key !== null &&
    params.sort_key !== "" &&
    params.sort_key !== undefined
  ) {
    getParams += `&sort_key=${params.sort_key}`;
  }
  if (
    params &&
    params.sort_flag !== null &&
    params.sort_flag !== "" &&
    params.sort_flag !== undefined
  ) {
    getParams += `&sort_flag=${params.sort_flag}`;
  }
  if (
    params &&
    params.region !== null &&
    params.region !== "" &&
    params.region !== undefined
  ) {
    getParams += `&region=${params.region}`;
  }

  return fetch(
    `${service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}${getParams}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => responseStatusHandler(response))
    .then((result) => result)
    .catch((error) => {
      errorHandler(error);
    });
};
/** ****************************** View Api *********************************** */
export const viewDataByApi = (requestUrl, dataId, params) => {
  let getParams = "?";
  if (
    params &&
    params.category_type !== null &&
    params.category_type !== "" &&
    params.category_type !== undefined
  ) {
    getParams += `category_type=${params.category_type}`;
  }
  if (
    params &&
    params.search !== null &&
    params.search !== "" &&
    params.search !== undefined
  ) {
    getParams += `&search=${params.search}`;
  }
  if (
    params &&
    params.is_deal !== null &&
    params.is_deal !== "" &&
    params.is_deal !== undefined
  ) {
    getParams += `&is_deal=${params.is_deal}`;
  }
  if (
    params &&
    params.type !== null &&
    params.type !== "" &&
    params.type !== undefined
  ) {
    getParams += `&type=${params.type}`;
  }

  if (
    params &&
    params.search_size_color !== null &&
    params.search_size_color !== "" &&
    params.search_size_color !== undefined
  ) {
    getParams += `&search_size_color=${params.search_size_color}`;
  }
  if (
    params &&
    params.variant_id !== null &&
    params.variant_id !== "" &&
    params.variant_id !== undefined
  ) {
    getParams += `&variant_id=${params.variant_id}`;
  }
  if (
    params &&
    params.limit !== null &&
    params.limit !== "" &&
    params.limit !== undefined
  ) {
    getParams += `&limit=${params.limit}`;
  }
  if (
    params &&
    params.page !== null &&
    params.page !== "" &&
    params.page !== undefined
  ) {
    getParams += `&page=${params.page}`;
  }

  if (
    params &&
    params.searchvalue !== null &&
    params.searchvalue !== "" &&
    params.searchvalue !== undefined
  ) {
    getParams += `&searchvalue=${params.searchvalue}`;
  }
  return fetch(`${hostConfig.API_URL}${requestUrl}/${dataId}${getParams}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400 || result.status === 409
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};

/** ****************************** Update by Id Api *********************************** */
export const putDataByIdApi = (
  requestUrl,
  params,
  id,
  roleId,
  role,
  service,
  method
) => {
  let getParams = "";
  if (roleId && roleId && roleId !== null && roleId !== undefined) {
    getParams += `/${roleId}`;
  }

  if (role && role !== null && role !== "" && role !== undefined) {
    getParams += `?role=${role}`;
  }

  if (method && method !== null && method !== "" && method !== undefined) {
    getParams += `&method=${method}`;
  }
  return fetch(
    `${service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}/${id}${getParams}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    }
  )
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400 || result.status === 409
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};

/** ****************************** Update Api *********************************** */
export const putDataApi = (requestUrl, params, paramId) => {
  let getParams = "?";
  if (params?.type) {
    getParams += `&type=${params.type}`;
  }
  return fetch(`${hostConfig.API_URL}${requestUrl}/${paramId}${getParams}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400 || result.status === 409
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};
export const putDataApiForm = (requestUrl, params) => {
  let getParams = "?";
  if (params?.type) {
    getParams += `&type=${params.type}`;
  }
  return fetch(`${hostConfig.API_URL}${requestUrl}${getParams}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: params,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // Return response object to chain with subsequent .then()
      return response.json(); // Parse response body as JSON
    })
    .catch((err) => {
      errorHandler(err);
    });
};
/** ****************************** Change password Api *********************************** */
export const changePasswordDataApi = (
  requestUrl,
  params,
  id,
  service,
  changePasswordToken
) => {
  return fetch(
    `${service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}/${id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${changePasswordToken}`,
      },
      body: JSON.stringify(params),
    }
  )
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400 || result.status === 409
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};

/** ****************************** Delete by Id Api *********************************** */
export const deleteDataByIdApi = async (requestUrl, dataId, service) => {
  const response = await fetch(
    `${service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL}${requestUrl}/${dataId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const result = await responseStatusHandler(response);
  if (result.status === 200 || result.status === 201 || result.status === 400 || result.status === 409) {
    return result.json();
  }
  return result;
};


/** ****************************** Delete Api *********************************** */
export const deleteDataApi = (requestUrl, params, service) =>
  fetch(
    `${service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    }
  )
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400 || result.status === 409
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });

/** ****************************** Download Api *********************************** */
export const downloadApi = (requestUrl, params) => {
  let getParams = "?";
  if (
    params &&
    params.candidateId &&
    params.candidateId !== null &&
    params.candidateId !== undefined
  ) {
    getParams += `candidateId=${params.candidateId}`;
  }
  return fetch(
    `${service === "JSONSERVER" ? hostConfig.JSON_SERVER_URL : hostConfig.API_URL
    }${requestUrl}${getParams}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/pdf",
      },
      responseType: "arraybuffer",
    }
  )
    .then((response) => responseStatusHandler(response))
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400 || result.status === 409
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};
