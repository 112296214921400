import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../components/button";
import Input from "../../components/input";
import { postLogIn } from "../../api/create";
import Main from "../../assets/world-archives-white.png";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { toast } from "react-toastify";
import "./sign-in.css";
import cookie from "react-cookies";
import HeaderImg from "../../assets/header.png";
const Index = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  // const [cookie,setCookie] = useCookies(["accessToken"]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()<>?])[A-Za-z\d!@#$%^&*()<>?]{8,}$/,
      //   "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character and be at least 8 characters long"
      // ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setLoader(true);
      const data = {
        email: values?.email,
        password: values?.password,
      };
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      postLogIn(data).then((res) => {
        if (res?.data) {
          localStorage.setItem("loggedUser", JSON.stringify(res?.data));
          localStorage.setItem("accessToken", res?.data?.tokenData?.token);
          cookie.save("accessToken", res?.data?.tokenData?.token, {
          })

          if (res?.data?.findUser?.status === "VERIFIED") {
            toast.success("User Logged in Successfully");
            setTimeout(() => {
              setLoader(false);
              window.location.href = "/home";
            }, 3000);
          } else {
            toast.error("Please Verify Your Account");
            setTimeout(() => {
              setLoader(false);
              sessionStorage.setItem("email", values?.email);
              window.location.href = "/verify-account";
            }, 1000);
          }

          setLoader(false);
          setTimeout(() => {
            setLoader(false);
            // window.location.href = "/home";
          }, 3000);




        } else {
          setLoader(false);
          toast.error(res?.message);
        }
      });
      setSubmitting(false);
    },
  });

  const spaceValidate = (event) => {
    const { name, value } = event.target;
    const newValue = value.replace(/\s/g, '');
    formik.setFieldValue(name, newValue);
  };


  return (
    <div className="banner">
      <div>
        <div className="text-center mb-3 d-flex justify-content-center">
          <img className="w-[100px] h-[50px]" src={HeaderImg} alt="main" />
        </div>
        <div className="row w-100 m-0">
          <div className="col-md-2 col-sm-12 col-lg-2" />
          <div className="col-md-8 col-sm-12 col-lg-8">
            <form onSubmit={formik.handleSubmit} className="join-community">
              <div className="row w-100 m-0">
                <p className="join-community-text">Sign In To Your Account</p>
                <div className="col-12 mt-3 mb-3">
                  <Input
                    style={{
                      border:
                        formik.errors.email && formik.touched.email
                          ? "1px solid red"
                          : "1px solid rgba(200, 199, 199, 1)",
                    }}
                    placeholder={"Email Address"}
                    type="email"
                    onBlur={formik.handleBlur}
                    name="email"
                    onChange={(e) => {
                      spaceValidate(e)
                    }}
                    value={formik.values.email}
                  />
                  {formik.touched.email &&
                    formik.errors.email && (
                      <div className="text-[12px] text-red-600">{formik.errors.email}</div>
                    )}
                </div>
                <div className="col-12 position-relative">
                  <Input
                    style={{
                      border:
                        formik.errors.password && formik.touched.password
                          ? "1px solid red"
                          : "1px solid rgba(200, 199, 199, 1)",
                    }}
                    placeholder={" Password"}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      spaceValidate(e)
                    }}
                    value={formik.values.password}
                  />

                  {showPassword ? (
                    <BsEye
                      className="cursor-pointer eye-icon"
                      size={20}
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <BsEyeSlash
                      className="cursor-pointer eyeSlash-icon"
                      size={20}
                      onClick={() => setShowPassword(true)}
                    />
                  )}
                  {formik.touched.password &&
                    formik.errors.password && (
                      <div className="text-[12px] text-red-600">{formik.errors.password}</div>
                    )}
                  <p className="forgot-psw">
                    <Link to="/forgot-password">Forgot Your Password?</Link>
                  </p>
                </div>

                <div className="mt-3">
                  <Button
                    style={{
                      opacity:
                        loader || formik.isSubmitting || !formik.isValid
                          ? "0.5"
                          : "1",
                      cursor:
                        loader || formik.isSubmitting || !formik.isValid
                          ? "not-allowed"
                          : "pointer",
                    }}
                    type="submit"
                    disabled={loader || formik.isSubmitting || !formik.isValid}
                  >
                    {loader ? (
                      <div
                        className="spinner-border text-light"
                        role="status"
                        style={{ width: "18px", height: "18px", margin: "0 10px 0 10px" }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Login"
                    )}
                  </Button>
                </div>
              </div>
              <div className="d-flex text-center justify-content-center mt-3">
                <p className="already-account">Didn't you have an account?</p>
                &nbsp;
                <Link to="/">
                  <p className="login-text">Sign Up</p>
                </Link>
              </div>
            </form>
          </div>
          <div className="col-md-2 col-sm-12 col-lg-2" />
        </div>
      </div>
    </div>
  );
};

export default Index;
