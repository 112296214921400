import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSetsInfo } from "../../api/list";
import { toast } from "react-toastify";


const initialState = {
    loading: false,
    storeResponseData: [],
    error: null
}

export const fetchSetsData = createAsyncThunk(
    "sets/getsSetsData",
    async (id, { dispatch }) => {
        dispatch(getsSetsRequest());
        const response = await getSetsInfo(id);
     
        if (response?.data && !response?.error) {
            dispatch(getSetsData(response?.data));
        };
        if (response?.error) toast.error(response?.message);
        dispatch(getSetsError(response?.error));
    }
)

const getSetsSlice = createSlice({
    name: "getSets",
    initialState,
    reducers: {
        getsSetsRequest: (state) => {
            state.loading = true;
        },
        getSetsData: (state, action) => {
            state.storeResponseData = action.payload;
            state.loading = false;
        },
        getSetsError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export default getSetsSlice.reducer;
export const {
    getsSetsRequest,
    getSetsData,
    getSetsError
} = getSetsSlice.actions;