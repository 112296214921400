import { putDataApi, putDataApiForm } from "./actions";
import { URL_CONSTANTS } from "./urls";

// export const putProductList = (params) =>
//   putDataApi(URL_CONSTANTS.productData, params, "JSONSERVER");

// export const putProductListById = (params) =>
//   putDataApi(URL_CONSTANTS.productData, params, "JSONSERVER");
// export const putVariantById = (params, id) =>
//   putDataApi(URL_CONSTANTS.createStock, params, id);

export const putAllMedia = (params) =>
  putDataApiForm(URL_CONSTANTS.updateAllMedia, params);

export const putUserInfo = (params, id) =>
  putDataApi(URL_CONSTANTS.getUserInfo, params, id);

export const putSetsById = (params, id) =>
  putDataApi(URL_CONSTANTS.selectedUpdateSets, params, id);




// export const updateOrdersById = (params, id) =>
//   putDataApi(URL_CONSTANTS.updateOrders, params, id);

// export const updateFiltersById = (params, id) =>
//   putDataApi(URL_CONSTANTS.filters, params, id);

// export const updateUserById = (params, id) =>
//   putDataApi(URL_CONSTANTS.users, params, id);

// export const updateCategoryById = (params, id) =>
//   putDataApi(URL_CONSTANTS.categories, params, id);

// export const updateOrderById = (params, id) =>
//   putDataApi(URL_CONSTANTS.updateOrders, params, id);

// export const updateCMSBanner = (params, dataId) =>
//   putDataApi(URL_CONSTANTS.postBanner, params, dataId);
// export const updateCMSProduct = (params, dataId) =>
//   putDataApi(URL_CONSTANTS.postProducts, params, dataId);
// export const updateAdminUser = (params, dataId) =>
//   putDataApi(URL_CONSTANTS.adminUser, params, dataId);

// export const updateAdminPassword = (params, id) =>
//   putDataApi(URL_CONSTANTS.changePassword, params, id);
// export const updateCMSFeatured = (params, dataId) =>
//   putDataApi(URL_CONSTANTS.updateFeaturedCatagory, params, dataId);
// export const updateFilter = (params, id) =>
//   putDataApi(URL_CONSTANTS.updateFilter, params, id);
// export const updateUpdateMainCategory = (params, dataId) =>
//   putDataApi(URL_CONSTANTS.updateMainCategory, params, dataId);
// export const updateNotification = (params, dataId) =>
//   putDataApi(URL_CONSTANTS.updateNotification, params, dataId);
export const updateAdmin = (params, id) =>
  putDataApi(URL_CONSTANTS.admin, params, id);
export const updateTeamMember = (params, id) =>
  putDataApi(URL_CONSTANTS.updateTeam, params, id);
export const updateLead = (params, id) =>
  putDataApi(URL_CONSTANTS.updateLead, params, id);
export const updateMeeting = (params, id) =>
  putDataApi(URL_CONSTANTS.updateMeeting, params, id);
export const updateOrg = (params, id) =>
  putDataApi(URL_CONSTANTS.updateOrg, params, id);
export const updateSummary = (params, id) =>
  putDataApi(URL_CONSTANTS.updateSummary, params, id);
export const updateStatus = (params, id) =>
  putDataApi(URL_CONSTANTS.updateStatus, params, id);
export const updateMail = (params, id) =>
  putDataApi(URL_CONSTANTS.updateMail, params, id);

export const updateDealStatus = (params, id) =>
  putDataApi(URL_CONSTANTS.updateDealStatus, params, id);

export const updateDealById = (params, id) =>
  putDataApi(URL_CONSTANTS.updateDeal, params, id);
export const updateDealClosed = (params, id) =>
  putDataApi(URL_CONSTANTS.updateDealClosed, params, id);

export const updateRoles = (params, id) =>
  putDataApi(URL_CONSTANTS.rolesPut, params, id);
export const createColdReach = (params, id) =>
  putDataApi(URL_CONSTANTS.createColdReach, params, id);
export const updateColdReachActivity = (params, id) =>
  putDataApi(URL_CONSTANTS.putColdLeadActivity, params, id);
export const updateColdStatus = (params, id) =>
  putDataApi(URL_CONSTANTS.updateStatusCold, params, id);
export const updateLeadScore = (params, id) =>
  putDataApi(URL_CONSTANTS.updateLeadScoreById, params, id);
