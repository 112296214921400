import React from 'react'
import noRecordsFound from "../../assets/no-records-found.png";

const Index = () => {
  return (
    <div className='flex flex-col  justify-center items-center'>
      <img src={noRecordsFound} alt="no-records-found"  className='w-[200px] h-[200px] mt-5'/>
      <h6 className='text-center text-[#000] font-bold mt-[25px] text-[20px]'>No Results Found</h6>
      {/* <p className='text-[#26385B] text-[16px] mt-[10px]'>No matches found</p> */}
    </div>
  )
}

export default Index
