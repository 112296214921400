import React from 'react'
import "./support.css"
const Support = () => {
    return (
        <div>
            <div className="support-container">
                <span className="support-heading">
                    Support
                </span>
                <div className="support-content">
                    <span> If you have any queries, suggestion, or operational technical issues,
                        please reach out to us at our email&nbsp; <span className="fw-600 support_link">support@worldarchives.in</span></span>
                </div>
            </div>
        </div>
    )
}

export default Support
