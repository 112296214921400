import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { putUserInfo } from "../../api/update";
import { toast } from "react-toastify";



const initalState = {
    loading: false,
    storeUserData: [],
    error: null
}

export const updateUserInfo = createAsyncThunk(
    "seller/updateUserData",
    async ({ id, params }, { dispatch }) => {
        dispatch(updateUserRequest());
        const response = await putUserInfo(params, id);
        if (response?.data && !response?.error) {
            dispatch(updateUserData(response?.data));
            toast.success(response?.message)
        } else {
            dispatch(updateUserError(response?.error));
            toast.error(response?.message)

        }
        return response

    }
)



const updateUserInfoSlice = createSlice({
    name: "updateUserInfo",
    initialState: initalState,
    reducers: {
        updateUserRequest: (state) => {
            state.loading = true
        },
        updateUserData: (state, action) => {
            state.storeUserData = action.payload;
            state.loading = false;
        },
        updateUserError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default updateUserInfoSlice.reducer;

export const { updateUserRequest, updateUserData, updateUserError } = updateUserInfoSlice.actions;
