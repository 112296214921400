import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {GetAllMedia} from "../../api/create";


const initialState = {
    loading: false,
    storeResponseData: [],
    error: null
}


export const fetchUnderRevision = createAsyncThunk(
    "underRevision/fetchUnderRevision",
    async (params, { dispatch }) => {
        dispatch(addUnderRevisionRequest());
        const response = await GetAllMedia(params);
        if (response?.data && !response?.error) dispatch(addUnderRevisionData(response?.data));
        dispatch(addUnderRevisionError(response?.error));
    }
)



const underRevisionSlice = createSlice({
    name: "underRevision",
    initialState,
    reducers: {
        addUnderRevisionRequest: (state) => {
            state.loading = true;
        },
        addUnderRevisionData: (state, action) => {
            state.loading = false;
            state.storeResponseData = action.payload;
        },
        addUnderRevisionError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});



export default underRevisionSlice.reducer;

export const { addUnderRevisionRequest, addUnderRevisionData, addUnderRevisionError } = underRevisionSlice.actions;