import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserInfoApi } from "../../api/list";



const initalState = {
    loading: false,
    storeUserData: [],
    error: null
}

export const fetchUserInfo = createAsyncThunk(
    "seller/fetchUserInfo",
    async (params, { dispatch }) => {
        dispatch(fetchUserRequest());
        const response = await getUserInfoApi(params);
        if(response?.data && !response?.error) dispatch(fetchUserData(response?.data));
        dispatch(fetchUserError(response?.error));  
        return response.data
    }
)



const userInfoSlice = createSlice({
    name: "userInfo",
    initialState: initalState,
    reducers: {
        fetchUserRequest: (state) => {
            state.loading = true
        },
        fetchUserData: (state, action) => {
            state.storeUserData = action.payload;
            state.loading = false;
        },
        fetchUserError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default userInfoSlice.reducer;

export const { fetchUserRequest, fetchUserData, fetchUserError } = userInfoSlice.actions;
