import React from 'react'
import { Link } from 'react-router-dom'
import { formatAmount, formatDownloads } from '../../utils/functions';
import Skeleton from 'react-loading-skeleton';
const Lifetime = (props) => {

  const { dashboard, loading } = props



  return (
    <div className='flex md:flex-row flex-col  justify-between w-[80%]'>

      <div className='earning-amt'>
        <p className='mb-0 text-[36px] font-[700] text-[#2F1409]'>₹ {loading ? <Skeleton width={50} /> : dashboard?.totalEarnings ? formatAmount(dashboard?.totalEarnings) : 0}</p>


        {loading ? <p className='mb-0 text-[#888888]'> <Skeleton width={100} /> </p> : <p className='mb-0  text-[#888888]'>{dashboard?.totalDownloads ? formatDownloads(dashboard?.totalDownloads) : 0} Downloads</p>}

      </div>

      {dashboard?.publishedFiles?.length < 1 &&
        <div>
          <Link to={'/image-upload'} className='text-decoration-none text-[#888888]'><span className='text-primary'>Upload</span> your best content to get started!</Link>
        </div>
      }

    </div>
  )
}

export default Lifetime
