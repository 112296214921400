// *****************React Icons *************************
import { IoMdArrowBack } from "react-icons/io";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { RiArrowRightDoubleFill } from "react-icons/ri";

// *********************React Library **********************
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Pagination from "../../../components/Pagination/Pagination";
import NoRecordFound from "../../../components/no-records-found";
// **********************ReDux ***************************
import { useDispatch, useSelector } from "react-redux";

//***********************SLices************************ */
import { fetchAllsetsData } from "../../../redux/slices/get-all-sets.slice";
import NoRecordsFound from "../../../components/no-records-found";

const SetsList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const listPerPage = 10;
    const data = useSelector((state) => state.getAllSets?.storeAllSetsData);

    const indexOfLastProduct = currentPage * listPerPage;
    const indexOfFirstProduct = indexOfLastProduct - listPerPage;
    const currentList = data?.slice(indexOfFirstProduct, indexOfLastProduct);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        dispatch(fetchAllsetsData()).then(() => setLoading(false))
    }, [])

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    }




    return (
        <div className="container  ">


            <p className="back-images  my-4">
                <IoMdArrowBack className="cursor-pointer" onClick={() => navigate("/home")} />
                &nbsp; {data?.length ? `Sets files (${data?.length})` : "No Sets Found"}
            </p>


            {/* This is Sets List Page ---------------------------------------------------- */}

            <div className="table-border min-h-[70vh]">
                <table className="w-100 published-file-list">
                    <thead>
                        <tr>
                            <th scope="col">Title</th>
                            <th scope="col">No of Assets</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>



                        {loading ?
                            <tr>
                                <td colSpan="6" className="text-center">
                                    <Skeleton count={4} height={40} />
                                </td>
                            </tr>
                            :
                            data?.length > 0 ? currentList?.map((item, index) => (
                                <tr className="h-[60px]" key={index}>
                                    <td className="d-flex align-items-center md:w-auto w-[350px]">
                                        {item?.medias?.length > 0 ? <img className="h-[35px] rounded-[5px] w-[45px]" src={`https://beyond-dream-development-local.s3.amazonaws.com/${item?.medias?.[0]?.thumbnail}`} alt="table-1" /> : <div className="col-1">
                                        </div>}
                                        &nbsp; {item?.sets_name}
                                    </td>

                                    <td style={{ paddingLeft: "30px" }}>{item?.medias?.length}</td>
                                    <td>{formatDate(item?.createdAt)}</td>
                                    <td className="view-text-published"><Link to={`/update-sets/${item?.id}`} className="text-decoration-none">View</Link>
                                    </td>
                                </tr>

                            ))
                                :
                                <NoRecordFound />
                        }

                    </tbody>
                </table>
            </div>

            <div className="flex items-center justify-between">
                <p className="mb-0">Showing {indexOfFirstProduct + 1} to {indexOfLastProduct} of {data?.length} entries </p>

                <div className='flex justify-center items-center gap-2 my-5'>
                    <MdOutlineKeyboardDoubleArrowLeft className="cursor-pointer" size={20}
                        onClick={
                            () => {
                                if (currentPage > 1) {
                                    paginate(currentPage - 1)
                                }
                            }
                        }
                    />
                    {Array.from({ length: Math.ceil(data?.length / listPerPage) }).map((_, index) => (
                        <button
                            key={index}
                            onClick={() => paginate(index + 1)}
                            className={`px-3 py-1 mx-1 rounded-full ${currentPage === index + 1 ? 'bg-[#ed652b] text-white' : 'text-black bg-transparent'}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <RiArrowRightDoubleFill
                        onClick={
                            () => {
                                if (currentPage < Math.ceil(data?.length / listPerPage)) {
                                    paginate(currentPage + 1)
                                }
                            }
                        }
                        className="cursor-pointer" size={20} />
                </div>
            </div>

        </div>

    )
}

export default SetsList
