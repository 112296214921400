import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllInsights, getAllRecentlyAdded } from "../../api/list";


const initialState = {
    loading: false,
    storeResponseData: [],
    storeRecentlyAddedData: [],
    error: null
}


export const fetchInsightsData = createAsyncThunk(
    "insights/fetchInsightsData",
    async (params, { dispatch }) => {
        dispatch(addInsightsRequest());
        const response = await getAllInsights(params);
        if (response?.data && !response?.error) dispatch(addInsightsData(response?.data));
        dispatch(addInsightsError(response?.error));
    }
)

export const fetchRecentlyData = createAsyncThunk(
    "insights/fetchRecentlyData",
    async (params, { dispatch }) => {
        dispatch(addRecentlyRequest());
        const response = await getAllRecentlyAdded(params);
        if (response?.data && !response?.error) dispatch(addRecentlyData(response?.data));
        dispatch(addRecentlyError(response?.error));
    }
)


const insightsSlice = createSlice({
    name: "insights",
    initialState,
    reducers: {
        addInsightsRequest: (state) => {
            state.loading = true;
        },
        addInsightsData: (state, action) => {
            state.storeResponseData = action.payload;
            state.loading = false;
        },
        addInsightsError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        addRecentlyRequest: (state) => {
            state.loading = true;
        },
        addRecentlyData: (state, action) => {
            state.storeRecentlyAddedData = action.payload;
            state.loading = false;
        },
        addRecentlyError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default insightsSlice.reducer;

export const { addInsightsRequest, addInsightsData, addInsightsError, addRecentlyRequest, addRecentlyData, addRecentlyError } = insightsSlice.actions;