import React from 'react'
import "./mission.css"
const Mission = () => {
  return (
    <div>
      <div className="mission-container">
        <div className="fs-22 fw-700 ft_mg_btm ft_ul_cmn">
          Mission
        </div>
        <div className="mission-content">
          <span className="fw-400">
            Our goal is to capture, create, and preserve all kinds of stock
            content from various cultural, social as well as commercial backgrounds.
            World Archives aims at collecting, owning and selling the assets and
            to open doors for our creative customers.
            We aim to empower the creative economy and fuel up our visual
            storytelling through our content globally.
          </span>
          <span className="fw-400">
            Our mission is to help you create a niche for your brands,
            your customers, readers and viewers and helps you to connect
            with audiences at a larger level.
          </span>
        </div>
      </div>
    </div>
  )
}

export default Mission
