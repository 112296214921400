import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch } from "react-redux";
import { fetchUploadImage } from "../../redux/slices/upload-image.slice";
// import { fetchBulkUploadImage } from "../../redux/slices/upload-bulk-image.slice";
import PopUp from "../../components/pop-up";
// import { MdDelete } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { RiShareBoxFill } from "react-icons/ri";
import { FaImage } from "react-icons/fa6";
import { IoVideocam } from "react-icons/io5";
import { RiErrorWarningFill } from "react-icons/ri";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { IoIosArrowDown } from "react-icons/io";
import Sample from "../../assets/sample.png";
import { IoWarningOutline } from "react-icons/io5";

import UploadImage from "../../assets/upload-arrow.svg";
import "./image-upload.css";





const Index = () => {
  const [open, setOpen] = useState();
  const [images, setImages] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [errorAssets, setErrorAssets] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileTypes = ["JPG", "PNG", "MOV", "MP4", "EPS"];


 



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      file: [], // Initialize files array in formik initialValues
    },
    onSubmit: async (values, { setSubmitting }) => {
      setUploading(true);
      const files = values?.file;
      try {
        const uploadResponses = await Promise.all(
          files.map(async (file) => {
            const formData = new FormData();
            formData.append("file", file);
            // Dispatch the upload action and await the response
            const response = await dispatch(fetchUploadImage(formData));
            return { file, response };
          })
        );

        // Check if all uploads were successful
        const allUploadsSuccessful = uploadResponses.every(
          ({ response }) => response?.payload?.success
        );

        if (allUploadsSuccessful) {
          toast.success("Assets uploaded successfully");
          navigate("/portfolio/submit-content");
        } else {
          toast.error("Some assets failed to upload");

          // Filter out existing files from the array of uploaded files
          const failedUploads = uploadResponses.filter(
            ({ response }) => !response?.payload?.success
          );

          // Extract the failed files and store them in the errorAssets state
          const existingFiles = failedUploads.map(({ file }) => file.name);
          const failedFiles = failedUploads.map(({ file }) => file);
          setErrorAssets(failedFiles);

          // Remove existing files from the form values
          const updatedFiles = files.filter(
            (file) => !existingFiles.includes(file.name)
          );

          // Update form values with the remaining unique files
          formik.setValues({ file: updatedFiles });
          // Reset the form if any upload failed
          formik.resetForm();
        }
      } catch (error) {
        console.error("Error occurred during file upload:", error);
        toast.error("Error occurred during file upload");
      } finally {
        setUploading(false);
      }
    },
  });
  const handleUpload = () => {
    formik.handleSubmit();
  };

  const handleChange = (files) => {
    const fileList = Array.from(files); // Convert FileList to array
    const data = [...formik.values.file, ...fileList];
    formik.setFieldValue("file", data);
  };

  const onDrop = (files) => {
    if (Array.isArray(files)) {
      // Check if files is an array
      // Filter files to only accept jpg, png, mov, and mp4 formats
      const filteredFiles = files.filter((file) =>
        /\.(jpg|png|mov|mp4|eps|jpeg)$/i.test(file.name)
      );

      // Convert files to object URLs
      const fileDataUrls = filteredFiles.map((file) => ({
        name: file.name,
        url: URL.createObjectURL(file),
      }));

      // Update state with file data URLs
      setImages((prevImages) => [...prevImages, ...fileDataUrls]);
    } else {
      return null;
    }
  };

  useEffect(() => {
    const isChecked = localStorage.getItem("checked");
    if (isChecked === "true") {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, []);

  const handlePreviewClicked = (item) => {
    const newWindow = window.open('', '_blank');

    if (newWindow) {
      newWindow.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>
        ${item?.type.startsWith("image/") ? "Image Preview" : "Video Preview"}
        </title>
        <style>
          body {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
            height: 100vh;
          }
          video {
            max-width: 100%;
            max-height: 100%;
          }
          img {
            width: 800px;
          }
        </style>
      </head>
      <body>
      ${item?.type.startsWith("image/") ?
          `<img src=${URL.createObjectURL(item)} alt="Fullscreen Preview">`
          :
          ` <video controls autoplay>
      <source src=${URL.createObjectURL(item)} type="video/mp4">
      Your browser does not support the video tag.
    </video>`}
      </body>
      </html>
    `);
      newWindow.document.close();
    }
  }

  // Validation function to check file sizes
  const isNextButtonDisabled = () => {
    return formik?.values?.file?.some(fileItem => {
      if (fileItem?.type.startsWith("image/") && fileItem.size < 1 * 1024 * 1024) {
        return true;
      }
      if (fileItem?.type.startsWith("video/") && fileItem.size < 5 * 1024 * 1024) {
        return true;
      }
      return false;
    });
  };

  return (
    <div>



      <div className="ps-5 pe-5 mt-4">

        {errorAssets?.length > 0 &&

          <div className=" w-full transition-all duration-150 rounded my-2 border-2 border-red-600 ">

            <div className={`flex items-center bg-red-100 rounded  min-h-[100px] w-full `}>
              <div className="w-[90%] flex items-center ps-5 gap-3">
                <RiErrorWarningFill size={45} className="text-red-700" />
                <span className="text-[20px] font-semibold">
                  There was an error in some Assets
                </span>
              </div>
              <div onClick={() => setOpenError(!openError)}
                aria-controls="example-collapse-text"
                aria-expanded={open} className=" cursor-pointer w-[10%] flex items-center justify-center">

                <IoIosArrowDown className={` ${openError
                  ? "rotate-180"
                  : ""
                  } text-gray-700 transition-all duration-300 `} size={40} />

              </div>
            </div>

            <Collapse in={openError}>
              <div id="example-collapse-text">

                {errorAssets.map((item, index) => {
                  return (
                    <div className=" flex md:w-[90%] py-2  rounded my-2 w-[100%] bg-gray-100 mx-auto items-center" key={index}>
                      <div className="flex w-[70%] items-center gap-3 ps-5">
                        <img className="w-[40px] rounded h-[35px]" src={Sample} alt="" />
                        <span className="text-[16px] font-semibold">{item?.name}</span>
                      </div>
                      <div className="flex items-center text-yellow-700 gap-2">
                        <IoWarningOutline className="" size={20} /> <span className="text-[16px] font-semibold">File Already Uploaded</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Collapse>
          </div>
        }


        <form onSubmit={formik.handleSubmit}>
          <div className="image-upload-main">
            <p className="upload-content-text">Upload Your Content</p>
            <div className="text-center mt-5">
              <p className="mb-4">Drag and drop files here</p>
              <div className="d-flex justify-content-center mb-4">
                <FileUploader
                  multiple
                  title="Drag and drop files here"
                  accept=".jpg,.png,.mov,.mp4,.eps,.jpeg"
                  onDrop={onDrop}
                  handleChange={handleChange} // Pass handleChange function
                  name="file"
                  types={fileTypes}
                >
                  <button
                    type="button"
                    className="image-upload-button "
                  >
                    <img src={UploadImage} alt="upload" />
                    Upload
                  </button>
                </FileUploader>
              </div>
              <p>
                Images must be .eps,png,jpeg or .jpg format. Videos must be
                <br /> .mov or .Mp4 format.
              </p>
              <div className="upload__image-wrapper row">
                <div className="grid grid-cols-2 gap-y-5 md:grid-cols-8">
                  {formik?.values?.file?.map((fileItem, index) => (
                    <div key={index} className="position-relative rounded-[10px] mx-2 my-2 w-[150px] h-[110px]">
                      {fileItem?.type.startsWith("image/") ? (
                        <img
                          className="file-image"
                          src={URL.createObjectURL(fileItem)}
                          alt={fileItem?.name}
                        />
                      ) : fileItem?.type.startsWith("video/") ? (
                        <video className="file-video">
                          <source
                            src={URL.createObjectURL(fileItem)}
                            type={fileItem?.type}
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <p>Selected file: {fileItem?.name}</p>
                      )}

                      {fileItem?.type.startsWith("image/") ?
                        <FaImage className="asset-icon text-[#ed652b]" color="" size={20} /> :
                        <IoVideocam className="asset-icon text-[#ed652b]" color="" size={20} />
                      }

                      {fileItem?.type.startsWith("image/") && fileItem.size < 1 * 1024 * 1024 && (
                        <p className="text-[11px] mb-0 mt-1" style={{ color: "#c0392b" }}>
                          Image must be above 1 MB
                        </p>
                      )}
                      {fileItem?.type.startsWith("video/") && fileItem.size < 5 * 1024 * 1024 && (
                        <p className="text-[11px] mb-0 mt-1" style={{ color: "#c0392b" }}>
                          Video must be above 5 MB
                        </p>
                      )}

                      <button
                        type="button"
                        disabled={uploading}
                        className="remove-button position-absolute"
                        onClick={() => {
                          formik.setFieldValue(
                            "file",
                            formik?.values?.file?.filter(
                              (_, idx) => idx !== index
                            )
                          );
                        }}
                      >
                        <IoClose className="text-[#ed652b]" color="" size={30} />
                      </button>

                      <button
                        type="button"
                        disabled={uploading}
                        className="preview-button position-absolute"
                      >
                        <RiShareBoxFill
                          onClick={() => handlePreviewClicked(fileItem)}
                          onMouseEnter={() => setHoveredIndex(index)}
                          onMouseLeave={() => setHoveredIndex(null)}
                          className="preview-icon relative text-[#ed652b]" color="" size={20} />
                        {hoveredIndex === index &&
                          <div className="bg-black/70 backdrop-blur rounded p-1 absolute bottom-[-30px] right-[-10px] text-white text-[10px]">
                            preview
                          </div>
                        }
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="d-flex justify-content-end mt-4">
          <button
            onClick={handleUpload}
            className="create-set"
            type="submit"
            disabled={!formik?.values?.file?.length || isNextButtonDisabled()}
            style={{ opacity: !formik?.values?.file?.length || isNextButtonDisabled() ? "0.5" : "1" }}
          >
            {uploading ? (
              <div
                className="spinner-border text-light"
                role="status"
                style={{ width: "18px", height: "18px" }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Next"
            )}
          </button>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12 col-lg-4 mt-sm-3">
            <div className="image-upload-card d-grid align-content-center">
              <p className="image-upload-card-text">
                Uploading lots of content?
              </p>
              <div className="d-flex">
                <p className="try-image-upload-text">Try using FTPS.</p>&nbsp;
                <span className="learn-image-upload-text">Learn more.</span>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-lg-4 mt-sm-3">
            <div className="image-upload-card">
              <p className="image-upload-card-text">Uploading vectors?</p>
              <div className="">
                <span className="try-image-upload-text">
                  Upload EPS files compatible with Illustrator
                  <br /> version 8 or 10.
                </span>
                &nbsp;
                <span className="learn-image-upload-text">Learn more.</span>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-lg-4 mt-sm-3">
            <div className="image-upload-card">
              <p className="image-upload-card-text">Need more help?</p>
              <div className="">
                <span className="try-image-upload-text">
                  Visit the&nbsp;
                  <span className="learn-image-upload-text">
                    Support center
                  </span>
                  &nbsp; for detailed guides and
                  <br /> FAQs
                </span>
              </div>
            </div>
          </div>
        </div>

        <PopUp
          headerText={
            <p
              className="mb-0"
              style={{
                fontSize: "24px",
                fontWeight: "600",
                color: "rgba(47, 20, 9, 1)",
              }}
            >
              We can’t wait to see your
              <br /> best work
            </p>
          }
          show={open}
          onHide={() => setOpen(false)}
          backdrop="static"
          keyboard={false}
        >
          <div className="">
            <p className="upload-pop-up-text">
              First, upload images and videos that you have created. Then add
              details and submit your work for review.
            </p>
            <p className="pop-up-heading-text">Photos or illustrations</p>
            <p className="upload-pop-up-text">
              Upload JPEG and PNG files that are at least 4 megabytes.
            </p>
            <p className="pop-up-heading-text">Videos</p>
            <p className="upload-pop-up-text">
              Upload MOV or Mp4 files that are between 5 and 10 seconds long.
            </p>
            <p className="pop-up-heading-text">Vectors</p>
            <p className="upload-pop-up-text">
              Upload EPS files compatible with Illustrator version 8 or 10.
            </p>

            <p className="pop-up-heading-text">
              Content with people or private property
            </p>
          </div>
          <div onClick={() => {
            setOpen(false);
            localStorage.setItem("checked", "true");
          }} className="mt-5 cursor-pointer">
            <div className="upload__image-wrapper-1 w-100">
              <button className="upload-image-button">
                Got it
              </button>
              &nbsp;
            </div>
          </div>
        </PopUp>
      </div>
    </div>
  );
};

export default Index;
