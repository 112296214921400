import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import "./detailed-images.css";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { FaRegEye } from "react-icons/fa";
import Image from "../../assets/images/Illustration-Thumb.png";

import { hostConfig } from "../../config";
import { useNavigate } from "react-router-dom";

import { getById } from "../../redux/slices/published.slice";


const Index = () => {


  const [imgDetails, setImgDetails] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {

    dispatch(getById({ id })).then((res) => {
      setLoading(false);
      if (res?.payload?.data?.media) {
        setImgDetails(res?.payload?.data?.media);
      }
    })


  }, []);



  const handlePreviewClicked = (item) => {
    const newWindow = window.open('', '_blank');

    if (newWindow) {
      newWindow.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>
        ${item?.category === "IMAGE" ? "Image Preview" : "Video Preview"}
        </title>
        <style>
          body {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
            height: 100vh;
          }
          video {
            max-width: 100%;
            max-height: 100%;
          }
          img {
             
          }
        </style>
      </head>
      <body>
     

      ${item?.category === "IMAGE" ?
          `<img src="https://beyond-dream-development-local.s3.amazonaws.com/${item?.thumbnailSrc}" alt="Fullscreen Preview">`
          :
          ` <video controls autoplay>
      <source src="https://beyond-dream-development-local.s3.amazonaws.com/${item?.previewSrc}" type="video/mp4">
      Your browser does not support the video tag.
    </video>`}
    
        
      </body>
      </html>
    `);
      newWindow.document.close();
    }
  }





  return (
    <div className="container-fluid">
      <div className="ps-4 pe-4 pt-3">
        <p className="back-images ">
          <IoMdArrowBack onClick={() => navigate(-1)} className="cursor-pointer
          " />
          &nbsp;{imgDetails?.category === "IMAGE" ? "Image" : imgDetails?.category === "VIDEO" ? "Video" : "Asset"} Details
        </p>
        <div className="image-detail-main p-3">
          <div className="responsive-image-detail-main">
            <div className="responsive-image-detail-sub md:flex md:items-center">
              {loading ? <Skeleton className="mb-2" count={1} height={140} width={210} /> :
                <img onClick={() => handlePreviewClicked(imgDetails)} className="w-[210px] cursor-pointer  h-[140px] mb-2 rounded" src={`${hostConfig?.HOSTING_URL}/${imgDetails?.thumbnailSrc}`} alt="format" />
              }



              <div className="ps-3">
                <div>
                  <p className="image-detail-text">Title</p>
                  <p className="image-detail-text-2 capitalize">
                    {loading ? <Skeleton count={1} /> : imgDetails?.title ? imgDetails?.title : "--"}
                  </p>
                </div>
                <div>
                  <p className="image-detail-text">Description</p>
                  <p className="image-detail-text-2">
                    {loading ? <Skeleton count={1} /> : imgDetails?.description ? imgDetails?.description : "--"}
                  </p>
                </div>
              </div>
              <div>


              </div>
            </div>
            <div className="price-text-div flex items-start ">
              <div
                onClick={() => handlePreviewClicked(imgDetails)} className=" hover:text-[#494666] cursor-pointer me-2 bg-[#F1F1FF] text-[#4946C3] rounded-full px-3 py-[2px]  flex items-center gap-2">
                <FaRegEye size={20} />
                <span className="">Preview</span>
              </div>
              <p className="price-text">Price : ₹ {imgDetails?.price ? imgDetails?.price : 0}</p>
            </div>
          </div>

          <div className="row mt-3 ms-md-2 ms-2">
            <div className="col-md-2">
              <p className="image-detail-text">Format Type</p>
              <p className="image-detail-text-2">
                {loading ? <Skeleton count={1} width={100} /> : imgDetails?.mediainfo?.format ? imgDetails?.mediainfo?.format : "--"}
              </p>
            </div>
            <div className="col-md-2">
              <p className="image-detail-text">Usage</p>
              <p className="image-detail-text-2">
                {loading ? <Skeleton count={1} width={100} /> : imgDetails?.usageFor ? imgDetails?.usageFor : "--"}
              </p>
            </div>
            <div className="col-md-2">
              <p className="image-detail-text">Location</p>
              <p className="image-detail-text-2 capitalize">{
                loading ? <Skeleton count={1} width={100} /> : imgDetails?.shootLocation ? imgDetails?.shootLocation === "undefined" ? "--" : imgDetails?.shootLocation : "--"
              }</p>
            </div>
            <div className="col-md-2">
              <p className="image-detail-text">Asset Type</p>
              <p className="image-detail-text-2 ">
                {
                  loading ? <Skeleton count={1} width={100} /> : imgDetails?.category ?
                    imgDetails?.category?.slice(0, 1).toUpperCase() + imgDetails?.category?.slice(1, imgDetails?.category?.length).toLowerCase() : "--"
                }
              </p>
            </div>
            <div className="col-md-2">
              <p className="image-detail-text">Content Category</p>
              <p className="image-detail-text-2">
                {
                  loading ? <Skeleton count={1} width={100} /> : imgDetails?.contentCategory?.length > 0 ? imgDetails?.contentCategory : "--"
                }
              </p>
            </div>
          </div>

          <div className="row my-3 ms-md-2 ms-2">
            <div className="col-md-2">
              <p className="image-detail-text">Content ID</p>
              <p className="image-detail-text-2">
                {
                  loading ? <Skeleton count={1} width={100} /> : imgDetails?.id ? imgDetails?.id : "--"
                }
              </p>
            </div>
            <div className="col-md-2">
              <p className="image-detail-text">Type of Angle</p>
              <p className="image-detail-text-2 capitalize">{
                loading ? <Skeleton count={1} width={50} /> : imgDetails?.typeOfAngle ? imgDetails?.typeOfAngle : "--"
              }</p>
            </div>
            <div className="col-md-2">
              <p className="image-detail-text">Camera</p>
              <p className="image-detail-text-2 capitalize ">
                {
                  loading ? <Skeleton count={1} width={80} /> : imgDetails?.camera ? imgDetails?.camera : "--"
                }
              </p>
            </div>
            <div className="col-md-2">
              <p className="image-detail-text">Resolution</p>
              <p className="image-detail-text-2  ">
                {
                  loading ? <Skeleton count={1} width={80} /> : imgDetails?.mediainfo?.resolution ?
                    imgDetails?.mediainfo?.resolution?.slice(0, 1).toUpperCase() + imgDetails?.mediainfo?.resolution?.slice(1, imgDetails?.mediainfo?.resolution?.length).toLowerCase() : "--"
                }
              </p>
            </div>
            <div className="col-md-2">
              <p className="image-detail-text">POV</p>
              <p className="image-detail-text-2 capitalize">{
                loading ? <Skeleton count={1} width={50} /> : imgDetails?.pov ? imgDetails?.pov : "--"
              }</p>
            </div>

          </div>
          <div className="ms-sm-2">
            <p className="image-detail-text mt-2">Formats</p>
            <div className="responsive-format-texts">
              <p className="format-texts mb-0">
                {imgDetails?.mediainfo?.width} × {imgDetails?.mediainfo?.height} pixels • {imgDetails?.mediainfo?.imageWidthCm} × {imgDetails?.mediainfo?.imageHeightCm} cm • DPI {imgDetails?.mediainfo?.dpi} • {imgDetails?.mediainfo?.format}
              </p>
              &nbsp;&nbsp;
              {/* <p className="format-texts  mb-0">
                1000 × 590 pixels • 8.5 × 5 cm • DPI 300 • JPG
              </p> */}
            </div>
            <p className="image-detail-text mt-3">Keywords</p>
            <div className="responsive-keyword-image-upload">
              {imgDetails?.keywords?.map((keyword, i) => {
                return <> <span key={i} className="format-texts format-texts-2">{keyword}</span>
                  &nbsp;&nbsp;
                </>

              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
