import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import CreatableSelect from "react-select/creatable";
import Input from "../../../components/input";
import { putAllMedia } from "../../../api/update";
import Skeleton from "react-loading-skeleton";
import { IoWarningOutline } from "react-icons/io5";
import { fetchAllMedia } from "../../../redux/slices/get-all-media.slice";
import RightIcon from "../../../assets/right.svg";
import Content5 from "../../../assets/images/content-5.png";
import DeleteIcon from "../../../assets/delete.svg"
import axios from "axios";
import "react-loading-skeleton/dist/skeleton.css";
import "./submit-content.css";
import { MdRefresh } from "react-icons/md";
import Select from "react-select";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { GoImage } from "react-icons/go";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { fetchUnderRevision } from "../../../redux/slices/under-revision.slice";
import { RiShareBoxFill } from "react-icons/ri";
import { fetchDeleteAsset } from "../../../redux/slices/delete-asset-slice";
import Popup from "../../../components/pop-up";
import { RiErrorWarningLine } from "react-icons/ri";
import { RiVideoLine } from "react-icons/ri";
import Sample from "../../../assets/sample.png";
import { FaImage } from "react-icons/fa";








const Index = () => {
  const dispatch = useDispatch();
  const [media, setMedia] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [usage, setUsage] = useState("Commercial");
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
  const [categories, setCategories] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [createdOptionsCount, setCreatedOptionsCount] = useState(0);
  const [selectedCity, setSelectedCity] = useState(null);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [processingItem, setProcessingItem] = useState([]);
  const [minimize, setMinimize] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const titleRef = useRef(null);

  const handleSearchCity = async (inputValue) => {
    try {
      if (inputValue !== "") {
        const editedvalue = inputValue.charAt(0).toUpperCase() + inputValue.substring(1)
        setIsLoading(true);
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${editedvalue}`);
        const data = await response.json();
        setOptions(data);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }




  const handleChangeLocation = (selectedOption) => {
    setSelectedCity(selectedOption);
  };




  const handleSubmitted = (e) => {
    e.preventDefault();
    formik.validateForm().then((err) => {
      if (Object.keys(err).length > 0) {
        if (err.title) {
          titleRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        formik.handleSubmit();
      }
    });
  };


  useEffect(() => {
    if (media?.media?.length > 0) {
      const filteredItems = media?.media.filter(item => item.mediaStatus === 'PROCESSING');
      setProcessingItem(filteredItems);
      if (filteredItems?.length > 0) {
        setShow(true);
      } else {
        setShow(false);
      }
    } else {
      setProcessingItem([]);
      setSelectedImage(null);
    }
  }, [media?.media]);



  useEffect(() => {
    dispatch(
      fetchAllMedia({
        search: { mediaStatus: "PENDING", visibility: "NOT_PUBLISHED" },
        processing: true,
        sortBy: { createdAt: true }
      })
    ).then((res) => {
      setMedia(res?.payload?.data);
      if (res?.payload?.data?.media?.length > 0) {
        let count = 0;
        const data = res?.payload?.data?.media;
        for (let i = 0; i < data?.length; i++) {
          if (data[i]?.mediaStatus === "PROCESSING") {
            count++;
          }
        }
        setSelectedImage(res?.payload?.data?.media[count]);
      }

      setLoading(false);
      setUsage(res?.payload?.data?.media[0]?.usageFor || "Commercial");
    });
  }, [dispatch]);




  useEffect(() => {
    const fetchCategories = async () => {
      const response = await axios.get("https://api.worldarchives.in/setting");
      const settings = response?.data?.data?.settings;
      let contentCategories = [];
      for (let i = 0; i < settings.length; i++) {
        const setting = settings[i];
        if (setting.settingKey === "CONTENT_CATEGORIES") {
          contentCategories = setting.settingValue;
          break;
        }
      }
      setCategories(contentCategories);
    };
    fetchCategories();
  }, []);


  const spaceValidate = (event) => {
    const { name, value } = event.target;
    // Remove leading spaces and replace multiple spaces between words with a single space
    const newValue = value.replace(/^\s+/g, '').replace(/\s+/g, ' ');
    formik.setFieldValue(name, newValue);
  };



  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string(), //.required("Description is required"),
    keywords: Yup.array()
      .of(Yup.string())
      .min(1, "At least one keyword is required")
      .required("Keywords are required"),
    contentCategory: Yup.string(),
    typeOfAngle: Yup.string(),

    // camera: Yup.string(),
    // pov: Yup.string(),

  });




  const initialValues = {
    mediaId: selectedImage?.id || "",
    title: selectedImage?.title || "",
    description: selectedImage?.description || "",
    usageFor: selectedImage?.usageFor || "Commercial",
    keywords: selectedImage?.keywords || [],

    contentCategory: selectedImage?.contentCategory || "",
    // releaseForm: "",
    typeOfAngle: selectedImage?.typeOfAngle || "",
    shootLocation: selectedImage?.shootLocation || "",
    camera: selectedImage?.camera || "",
    sellerDataUpdates: false,
    pov: selectedImage?.pov || "", // Assuming pov is also a field in your form
  };



  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values) => {

      if (selectedImage === null) {
        toast.error("Please select an asset");
      } else {
        setBtnLoading(true);
        const formData = new FormData();
        // Append each field with its value to the FormData object
        formData.append("usageFor", usage);
        formData.append("sellerDataUpdates", true);
        formData.append("mediaId", selectedImage?.id);
        formData.append("title", formik.values.title.toLowerCase());
        formData.append("description", formik.values.description);
        formik.values.keywords.forEach((keyword, index) => {
          formData.append(`keywords[${index}]`, keyword);
        });
        if (typeof formik.values.contentCategory === "string") {
          // should be Array catgeory
          formData.append("contentCategory[0]", formik.values.contentCategory);

        } else {
          formik.values.category.forEach((category, index) => {
            formData.append(`contentCategory[${index}]`, category);
          });
        }

        // formData.append("releaseForm", formik.values.releaseForm);
        formData.append("typeOfAngle", formik.values.typeOfAngle);
        formData.append("shootLocation", formik.values.shootLocation ? formik.values.shootLocation : selectedCity?.label || "");
        formData.append("camera", formik.values.camera ? formik.values.camera : selectedCity?.camera || "");
        formData.append("pov", formik.values.pov ? formik.values.pov : selectedImage?.pov);
        // Now you can submit the FormData object
        setBtnLoading(true);
        putAllMedia(formData)

          .then((res) => {

            setBtnLoading(true);
            if (res?.success === true) {
              toast.success(selectedImage?.category ? `${selectedImage?.category.toLowerCase()} sent for review` : "Media Meta Data Update");
              handleRefresh();
              setBtnLoading(false);
              setLoading(true);
              dispatch(
                fetchAllMedia({
                  search: { mediaStatus: "PENDING", visibility: "NOT_PUBLISHED" },
                  processing: true,
                  sortBy: { createdAt: true }
                })
              ).then((res) => {
                setMedia(res?.payload?.data);
                if (res?.payload?.data?.media?.length > 0) {
                  setSelectedImage(res?.payload?.data?.media[0]);
                  setLoading(false);
                  dispatch(fetchUnderRevision({
                    search: { mediaStatus: "SUBMIT", visibility: "NOT_PUBLISHED" },
                    sortBy: { createdAt: true }
                  }));
                }
                setSelectedCity(null)
                setOptions([]);
              });
            }
            // Handle success response if needed
          })
          .catch((error) => {
            setBtnLoading(false);
            toast.error(error?.message || "Error");
            // Handle error if needed
            setBtnLoading(false);
          });
      }

    },
  });


  const handleRadioChange = (event) => {
    const value = event.target.value;
    setUsage(value);
    formik.setFieldValue("usage", value);
  };

  const handleRefresh = () => {
    setLoading(true);
    setSpinning(true);
    // setShow(false);
    formik.resetForm();
    dispatch(
      fetchAllMedia({
        search: { mediaStatus: "PENDING", visibility: "NOT_PUBLISHED" },
        processing: true,
        sortBy: { createdAt: true }
      })
    ).then((res) => {
      setMedia(res?.payload?.data);
      if (res?.payload?.data?.media?.length > 0) {
        let count = 0;
        const data = res?.payload?.data?.media;
        for (let i = 0; i < data?.length; i++) {
          if (data[i]?.mediaStatus === "PROCESSING") {
            count++;
          }
        }
        setSelectedImage(res?.payload?.data?.media[count]);
      }

      setLoading(false);
      setSpinning(false);
      setUsage(res?.payload?.data?.media[0]?.usageFor || "Commercial");

    });

  }

  const handlePreviewClicked = (item) => {
    const newWindow = window.open('', '_blank');

    if (newWindow) {
      newWindow.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>
        ${item?.category === "IMAGE" ? "Image Preview" : "Video Preview"}
        </title>
        <style>
          body {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
            height: 100vh;
          }
          video {
            max-width: 100%;
            max-height: 100%;
          }
          img {
             
          }
        </style>
      </head>
      <body>
     

      ${item?.category === "IMAGE" ?
          `<img src="https://beyond-dream-development-local.s3.amazonaws.com/${item?.thumbnailSrc}" alt="Fullscreen Preview">`
          :
          ` <video controls autoplay>
      <source src="https://beyond-dream-development-local.s3.amazonaws.com/${item?.previewSrc}" type="video/mp4">
      Your browser does not support the video tag.
    </video>`}
    
        
      </body>
      </html>
    `);
      newWindow.document.close();
    }
  }



  const handleDelete = () => {
    setDeleteBtnLoader(true);
    dispatch(fetchDeleteAsset(selectedImage?.id)).then((res) => {
      if (res.payload && res.payload.success) {
        handleRefresh();
        toast.success(res.payload.message);
      } else {
        toast.error(res.payload.message || "An error occurred");
      }
      setOpenDelete(false);
      setDeleteBtnLoader(false);
    });
  };





  return (

    <div>

      <Popup
        headerText={
          <p
            className="mb-0"
            style={{
              fontSize: "24px",
              fontWeight: "600",
              color: "rgba(47, 20, 9, 1)",
            }}
          >
            Delete Asset
            <br />
          </p>
        }
        show={openDelete}
        onHide={() => setOpenDelete(false)}
        backdrop="static"
        keyboard={false}
      >
        <div className="flex items-center gap-2 flex-col">
          <RiErrorWarningLine size={100} color="gray" />
          <p className="mb-0 px-10 text-center text-[#444444]">Are you sure you want to delete this <span className="font-bold">{
            selectedImage ? selectedImage?.fileName.length > 40 ? selectedImage?.fileName.slice(0, 40) + "..." : selectedImage?.fileName : "-"
          }</span> asset?</p>

          <div className="flex gap-3 my-3">
            <div onClick={() => setOpenDelete(false)} className="text-[18px] cursor-pointer hover:bg-gray-600 font-semibold py-2 px-3 bg-gray-500 rounded text-white ">Cancel</div>
            <div
              onClick={handleDelete}
              className={`text-[18px] hover:bg-orange-700 cursor-pointer font-semibold py-2 ${!deleteBtnLoader ? "px-3" : "px-4"} bg-[#ed652b] rounded text-white `}>{deleteBtnLoader ? (
                <div
                  className="spinner-border  spinner-border-1"
                  role="status"
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Delete"
              )}</div>
          </div>
        </div>
      </Popup>

      {show ?
        <ToastContainer
          className={`p-3 position-fixed ${minimize === false ? "bottom-0 end-0" : "bottom-[-41px] end-0"} transition-all`}
          // position={"bottom-end"}
          style={{ zIndex: "9999" }}
        >
          <Toast style={{
            background: "", borderColor: "#ed652b", backdropFilter: "blur(100px)",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
          }} show={show}>
            <Toast.Header closeButton={false} onClick={() => setMinimize(!minimize)} style={{ background: "rgba(237, 101, 43, 0.3)", cursor: "pointer" }}>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Uploading {processingItem?.length} {processingItem?.length > 1 ? "Items" : "Item"}</strong>
              <IoIosArrowDown size={20} className={`cursor-pointer transition-all ${minimize ? "rotate-180" : "rotate-0"}`} />
            </Toast.Header>
            <Toast.Body>
              {processingItem?.length > 0 && !minimize && processingItem?.map((item, index) => {
                return (
                  <div key={index} className="d-flex  items-center py-2 justify-between">
                    <div className="d-flex gap-2 items-center">
                      {item?.category === "IMAGE" ? <GoImage color="green" size={20} /> : <RiVideoLine color="blue" size={21} />}
                      <span className="text-center">{item?.fileName?.slice(0, 25)}</span>
                    </div>
                    {item?.mediaStatus === "PROCESSING" ? <div style={{ width: "20px", height: "20px" }} className="spinner-border text-primary opacity-50" role="status">
                      <span className=" visually-hidden">Loading...</span>
                    </div> : <div>
                      <FaCheckCircle size={20} color="green" />
                    </div>}
                  </div>
                )
              })}
            </Toast.Body>
          </Toast>
        </ToastContainer> : null}





      <div className="ps-2 pe-2 pt-3 md:ps-5 md:pe-5">
        <p className="submit-text " >Submit Content</p>
        <div className="d-flex justify-between items-center
        ">
          <div className="d-flex">
            <img src={RightIcon} alt="icon" />
            &nbsp;&nbsp;
            <p className="submit-info">
              Welcome! Let's get your content approved. Select an item to add
              details and submit for review.&nbsp;
              <span className="learn-more">Learn more</span>
            </p>
          </div>

          <div onClick={() => handleRefresh()}
            className="d-flex hover:text-gray-500 user-select-none justify-center  items-center gap-1 cursor-pointer">
            <MdRefresh className={`${spinning ? "animate-spin" : ""}`} size={20} /> <span>Refresh</span>
          </div>
        </div>
        {/* <p className="learn-more-1">Learn more</p> */}



        <div className="row mt-3 submit-content-border">
          <div className="col-md-7 col-sm-12 submit-content-border-1">
            <div className="image-elements  row pt-3 pb-3">
              {loading ? ( // Check if loading, then show skeleton loaders
                Array.from({ length: 8 }).map((_, index) => (
                  <div
                    className="col-md-3 col-sm-12 mb-2 position-relative"
                    key={index}
                  >
                    <Skeleton height={123} />
                  </div>
                ))
              ) : media?.media?.length > 0 ? (
                media.media?.map((item, index) => (
                  <div key={index}
                    className="col-md-3 cursor-pointer h-32 col-sm-12 mb-2 position-relative"
                    // eslint-disable-next-line no-unused-expressions
                    onClick={() => { item?.mediaStatus === "PENDING" ? handleImageClick(item) : null }}>

                    <RiShareBoxFill
                      onClick={() => handlePreviewClicked(item)}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      className="preview-icon absolute bottom-[15px] right-[20px]  text-white" color="" size={20} />



                    <input
                      className="selected-check-box"
                      type="checkbox"
                      checked={selectedImage?.id === item.id}
                      onChange={() => handleImageClick(item)}
                    />
                    {item?.mediaStatus === "PENDING" ?
                      <img
                        src={`${"https://beyond-dream-development-local.s3.amazonaws.com/"}${item?.thumbnailSrc
                          }`}
                        alt="contents"
                        width="100%"
                        className=" image-aspect mx-auto"
                      /> : <Skeleton height={123} />
                    }
                    {/* <span className="extension-type">
                      {item?.mediainfo?.extension}
                    </span> */}
                  </div>
                ))
              ) : (
                <div className="no-records-image">
                  <div>
                    <IoWarningOutline size={100} />
                  </div>
                  <p>No Records found</p>
                </div>
              )}




              {/* <img
                src={Content1}
                alt="contents"
                width="10%"
                className="pe-2 pb-2"
              /> */}
            </div>
          </div>



          {loading ? ( // Check if loading, then show skeleton loaders
            <div className="col-md-5 col-sm-12 pe-0 ps-0">

              <div className="pt-3 ps-3 pe-3 form-scroll">
                <Skeleton height={100} count={10} />
              </div>
              <div className="sticky-bottom">
                <div className="w-50 me-2">
                  <Skeleton height={50} />
                </div>
                <div className="w-50 ms-2">
                  <Skeleton height={50} />
                </div>
              </div>
            </div>

          ) : (
            <form
              className="col-md-5 col-sm-12 pe-0 ps-0"
              onSubmit={formik.handleSubmit}

              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <div className="pt-3 ps-3 pe-3 form-scroll">
                <div className="d-flex justify-content-between ">
                  <div className="d-flex align-items-center justify-between col-lg-11 col-12">
                    <div className="d-flex flex-column flex-md-row  align-items-center col-lg-11 gap-2">


                      {selectedImage ?
                        <img
                          src={
                            `${"https://beyond-dream-development-local.s3.amazonaws.com/"}${selectedImage?.thumbnailSrc
                            }`
                          }
                          alt="."
                          width="20%"
                          className="image-aspect "
                        /> : <FaImage className="w-20  h-20 text-gray-500" />

                      }



                      <span>
                        {selectedImage?.fileName ? selectedImage?.fileName.length < 30 ? selectedImage?.fileName :
                          selectedImage?.fileName.slice(0, 30) + "..." : " - "}
                      </span>
                    </div>
                    {selectedImage &&
                      <img onClick={() => setOpenDelete(true)} src={DeleteIcon} className="  w-[30px] cursor-pointer" alt="delete-icon" />
                    }

                  </div>

                </div>
                <div className="mt-3">
                  <label>Asset Type </label>
                  <p className="mt-2">
                    {selectedImage?.category ? selectedImage?.category : "-"}
                  </p>
                </div>
                <div className="mt-3">
                  <label>Usage</label>
                  <div className="d-flex">
                    <div className="d-flex align-items-center mt-2">
                      <input
                        type="radio"
                        value="Commercial"
                        checked={usage === "Commercial"}
                        onChange={handleRadioChange}
                      />
                      &nbsp;
                      <p className="usage-text">Commercial</p>
                    </div>
                    <div className="d-flex align-items-center mt-2 ms-5">
                      <input
                        type="radio"
                        value="Editorial"
                        checked={usage === "Editorial"}
                        onChange={handleRadioChange}
                      />
                      &nbsp;
                      <p className="usage-text">Editorial</p>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div ref={titleRef}>
                      <Input
                        style={{
                          border:
                            formik.errors.title && formik.touched.title
                              ? "1px solid red"
                              : "1px solid rgba(200, 199, 199, 1)",
                        }}
                        placeholder="Title"
                        type="text"
                        name="title"
                        onBlur={formik.handleBlur}
                        onChange={(e) => spaceValidate(e)}
                        value={formik.values.title}
                      />
                      {formik.touched.title &&
                        formik.errors.title && <p className=" mb-0 ms-2 text-danger text-[12px] error-message">{formik.errors.title}</p>
                      }
                    </div>
                    <textarea
                      style={{
                        border:
                          formik.errors.description &&
                            formik.touched.description
                            ? "1px solid red"
                            : "1px solid rgba(200, 199, 199, 1)",
                      }}
                      placeholder="Description"
                      className="mt-3 content-text-area"
                      name="description"
                      onBlur={formik.handleBlur}
                      onChange={(e) => spaceValidate(e)}
                      value={formik.values.description}
                    />
                    <select

                      style={{
                        border:

                          "1px solid rgba(200, 199, 199, 1)",
                        marginTop: "0.6rem",
                      }}
                      name="contentCategory"
                      onBlur={formik.handleBlur}
                      onChange={(event) => {
                        const selectedOptions = event.target.value;
                        formik.setFieldValue("contentCategory", selectedOptions);
                      }}
                      value={formik.values.contentCategory}
                      className="content-select"
                    >
                      <option value="">Category...</option>
                      {categories.map((category, index) => (
                        <option key={index} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>

                    <div className="create-key" style={{ marginTop: "1rem" }}>

                      <CreatableSelect
                        className="w-100"
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: formik.errors.keywords && formik.touched.keywords
                              ? "1px solid red"
                              : "1px solid rgba(200, 199, 199, 1)",
                          }),
                        }}
                        name="keywords"
                        onBlur={formik.handleBlur}
                        onChange={(selectedOptions) => {
                          const validKeywords = selectedOptions
                            .map((option) => option.value)
                            .filter((keyword) => keyword.trim() !== "");

                          formik.setFieldValue("keywords", validKeywords);
                        }}
                        value={formik.values.keywords.map((keyword) => ({
                          value: keyword,
                          label: keyword,
                        }))}
                        isClearable
                        isMulti
                        placeholder="Keywords"
                        onInputChange={(inputValue, { action }) => {
                          if (action === 'input-change') {
                            // Trim the input value before adding it to the options
                            inputValue = inputValue.trim();
                          }
                        }}
                      />


                      {!formik.values?.keywords.length && selectedImage &&
                        <p className=" mb-0 ms-2 text-danger text-[12px] error-message">At least one key word is required</p>
                      }

                    </div>

                    {/* <Input
                      style={{
                        border:
                          formik.errors.shootLocation &&
                            formik.touched.shootLocation
                            ? "1px solid red"
                            : "1px solid rgba(200, 199, 199, 1)",
                        marginTop: "0.6rem",
                      }}
                      placeholder="Location (Optional)"
                      type="text"
                      name="shootLocation"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.shootLocation}
                    /> */}

                    <div>
                      <Select
                        className="mt-[1rem] text-[14px]"
                        value={selectedCity}
                        onInputChange={(e) => { handleSearchCity(e) }}
                        // onChange={(e) => handleSearchCity(e.target.value)}
                        onChange={handleChangeLocation}
                        onBlur={formik.handleBlur}
                        options=
                        {options?.map((option) => ({ label: option.name, value: option.name }))}
                        name="shootLocation"
                        placeholder="Location (Optional)"
                        isClearable={true}

                      />
                    </div>

                    <Input
                      style={{
                        border:
                          formik.errors.typeOfAngle &&
                            formik.touched.typeOfAngle
                            ? "1px solid red"
                            : "1px solid rgba(200, 199, 199, 1)",
                        marginTop: "1rem",
                      }}
                      placeholder="Types of Angle"
                      type="text"
                      name="typeOfAngle"
                      onBlur={formik.handleBlur}
                      onChange={(e) => spaceValidate(e)}
                      value={formik.values.typeOfAngle}
                    />
                    <Input
                      style={{
                        border:
                          formik.errors.camera &&
                            formik.touched.camera
                            ? "1px solid red"
                            : "1px solid rgba(200, 199, 199, 1)",
                        marginTop: "1rem",
                      }}
                      placeholder="Camera"
                      type="text"
                      name="camera"
                      onBlur={formik.handleBlur}
                      onChange={(e) => spaceValidate(e)}
                      value={formik.values.camera}
                    />

                    <Input
                      style={{
                        border:
                          formik.errors.typeOfAngle &&
                            formik.touched.typeOfAngle
                            ? "1px solid red"
                            : "1px solid rgba(200, 199, 199, 1)",
                        marginTop: "1rem", marginBottom: "1rem",
                      }}
                      placeholder="POV"
                      type="text"
                      name="pov"
                      onBlur={formik.handleBlur}
                      onChange={(e) => spaceValidate(e)}
                      value={formik.values.pov}
                    />
                  </div>
                </div>
              </div>


              <div className="sticky-bottom form-group w-100 " >  {/* sticky-bottom*/}




                <div className="w-50 form-group mx-auto">   {/* w-50 me-2 */}



                  <button
                    className="submit-button " // save-button 
                    type="submit"
                    disabled={btnLoading || !selectedImage}
                  >
                    {btnLoading ? (
                      <div
                        className="spinner-border  spinner-border-1"
                        role="status"
                        style={{
                          width: "18px",
                          height: "18px",
                        }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>


                </div>



                {/* <div className="w-50 ms-2">
                   <button
                    style={{
                      opacity:
                        !selectedImage?.sellerDataUpdates === "true" ||
                          !selectedImage?.sellerDataUpdates
                          ? "0.5"
                          : "1",
                      cursor:
                        !selectedImage?.sellerDataUpdates === "true" ||
                          !selectedImage?.sellerDataUpdates
                          ? "not-allowed"
                          : "pointer",
                    }}
                    type="button"
                    className="submit-button"
                    onClick={handlePostMedia}
                    disabled={btnLoadingSubmit}
                  >
                    {btnLoadingSubmit ? (
                      <div
                        className="spinner-border spinner-border-1"
                        role="status"
                        style={{
                          width: "18px",
                          height: "18px",
                        }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button> 
                </div> */}
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
