import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { postForgotPassword } from "../../api/create";
import { postVerifyAccount } from "../../api/create";
import Main from "../../assets/world-archives-white.png";
import "../verify-account/verify.css";
import HeaderImg from "../../assets/header.png";
import { toast } from "react-toastify";


const Index = () => {
    const [email, setEmail] = useState(sessionStorage.getItem("forgot-email"));
    const [timer, setTimer] = useState(0); // Timer in seconds
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const history = useNavigate();


    const formik = useFormik({
        initialValues: {
            email: email,
        },

        onSubmit: (values, { setSubmitting }) => {
            postForgotPassword({ email: values?.email }).then((res) => {
                if (res?.success === true) {
                    formik.resetForm("");
                    toast.success(
                        res?.message ? res?.message : "Mail has been sent successfully"
                    );
                    startTimer();
                } else {
                    formik.resetForm("");
                    toast.error(res?.message);
                }
            });
        },
    });



    const startTimer = () => {
        setTimer(300);
        setIsTimerRunning(true);
    };

    useEffect(() => {
        let interval;
        if (isTimerRunning) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer === 0) {
                        clearInterval(interval);
                        setIsTimerRunning(false);
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isTimerRunning]);

    const handleResendClick = () => {
        if (!isTimerRunning) {
            formik.handleSubmit();
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    return (
        <div className="banner">
            <div>
                <div className="text-center mb-3 d-flex justify-content-center">
                    <img className="w-[100px] h-[50px]" src={HeaderImg} alt="main" />
                </div>
                <div className="row w-100 m-0">
                    <div className="col-4" />
                    <div className="col-4">
                        <form onSubmit={formik.handleSubmit} className="join-community">
                            <div className="row w-100 m-0">
                                <p className="join-community-text-1 mb-3">
                                    Thanks {formik.values.email ? formik.values.email.split("@")[0] : "mkphotography@gmail.com"} Please check your email to change your password.

                                </p>
                                <p className="mb-2">
                                    The email has been sent to: <b>{formik.values.email ? formik.values.email : "mkphotography@gmail.com"}</b>
                                </p>
                                <p className="mb-0">Didn't get an email?</p>
                                <button type="button" className={`resend-again ${isTimerRunning ? "" : ""}`} onClick={handleResendClick} disabled={isTimerRunning}>
                                    {isTimerRunning ? `Resend in ${formatTime(timer)} ` : "Send again"}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="col-4" />
                </div>
            </div>
        </div>
    );
};

export default Index;
