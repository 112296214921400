// ************** React Lib ************************//
import React, { useEffect, useState } from "react";

// ************** Redux ************************//
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardData } from "../../redux/slices/dashboard.slice";
import { fetchAllEarningsData } from "../../redux/slices/get-all-earnings-slice";
// ************** Components **************************//
import Card from "../../components/card";
import ListView from "../../components/list-view";
import NoRecordsFound from "../../components/no-records-found";
import { formatAmount, formatDownloads } from "../../utils/functions";
// ************** Assets *****************************//
import Save from "../../assets/save.svg";
import Wishlist from "../../assets/wish-list.svg";
import Money from "../../assets/money.svg";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
// ************** Styles *****************************//
import "./earings.css";

const Index = () => {
  const dispatch = useDispatch();
  const allEarnings = useSelector((state) => state.earnings?.storeAllEarningsData);
  const dashboard = useSelector((state) => state.dashboard?.storeResponseData);

  const [loading, setLoading] = useState(true);
  const [years, setYears] = useState([]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-based

  const [params, setParams] = useState({
    month: currentMonth.toString(),
    years: currentYear.toString(),
  });

  useEffect(() => {
    const yearsArray = [];
    for (let year = 2020; year <= currentYear; year++) {
      yearsArray.push(year);
    }
    setYears(yearsArray);
    dispatch(fetchDashboardData()).then(() => setLoading(false));
  }, [dispatch, currentYear]);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAllEarningsData(params)).then(() => setLoading(false));
  }, [dispatch, params]);

  // Logging allEarnings to diagnose the issue


  return (
    <div className="container-fluid mt-5">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 ps-0 ps-lg-5 pe-0 pe-lg-5">
        <div>
          <Card
            img={Money}
            loading={loading}
            rupeeSymbol={"₹"}
            text={"Total Earnings"}
            rate={dashboard?.totalEarnings ? formatAmount(dashboard?.totalEarnings) : 0}
          />
        </div>
        <div>
          <Card
            img={Money}
            loading={loading}
            rupeeSymbol={"₹"}
            text={"Unpaid Earnings"}
            rate={dashboard?.totalUnPaidEarning ? formatAmount(dashboard?.totalUnPaidEarning) : 0}
          />
        </div>
        <div>

          <Card loading={loading} img={Save} text={"Downloads"} rate={dashboard?.totalDownloads ? formatDownloads(dashboard?.totalDownloads) : 0} />
        </div>
        <div>
          <Card loading={loading} img={Wishlist} text={"Wishlists"} rate={dashboard?.totalWishlist ? formatDownloads(dashboard?.totalWishlist) : 0} />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-1 ps-0 ps-lg-5 pe-0 pe-lg-5 pb-3 pt-3 w-100 mt-3">
        <div className="list-design">
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex items-center">
              <p className="mb-0 text-[#444444] font-semibold me-2">Earnings</p>
              {typeof allEarnings?.earnings === 'number' && (
                <div className="mb-0 text-[#444444] font-semibold d-flex align-items-center">
                  (₹ {formatAmount(allEarnings?.earnings)})
                </div>
              )}
            </div>
            <div>
              <select
                value={months[parseInt(params.month) - 1]}
                onChange={(e) => {
                  const monthIndex = months.indexOf(e.target.value) + 1;
                  setParams({ ...params, month: monthIndex.toString() });
                }}
                className="select-year"
              >
                {months.map((month, index) => (
                  <option key={index}>{month}</option>
                ))}
              </select>
              &nbsp;
              <select
                value={params.years}
                onChange={(e) => setParams({ ...params, years: e.target.value })}
                className="select-year"
              >
                {years.map((year) => (
                  <option key={year}>{year}</option>
                ))}
              </select>
            </div>
          </div>

          {loading ? (
            <Skeleton className="mb-4" count={4} height={60} />
          ) : Array.isArray(allEarnings?.mediaData) && allEarnings?.mediaData.length > 0 ? (
            allEarnings.mediaData.map((data, index) => (
              <ListView key={index} path={`/portfolio/published/images/${data?.id}`} ImageElement img={data?.thumbnailSrc} Title={data?.fileName} />
            ))
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
