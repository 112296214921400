import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllSets } from "../../api/list";
import { toast } from "react-toastify";


const initialState = {
    loading: false,
    storeAllSetsData: [],
    error: null
}

export const fetchAllsetsData = createAsyncThunk(
    "sets/getsAllSetsData",
    async (id, { dispatch }) => {
        dispatch(getAllSetsRequest());
        const response = await getAllSets();
     
        if (response?.data && !response?.error) {
            dispatch(getAllSetsData(response?.data));
            return response?.data
        };
        if (response?.error) toast.error(response?.message);
        dispatch(getAllSetsError(response?.error));
    }
)

const getAllSetsSlice = createSlice({
    name: "getAllSets",
    initialState,
    reducers: {
        getAllSetsRequest: (state) => {
            state.loading = true;
        },
        getAllSetsData: (state, action) => {
            state.storeAllSetsData = action.payload;
            state.loading = false;
        },
        getAllSetsError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export default getAllSetsSlice.reducer;
export const {
    getAllSetsRequest,
    getAllSetsData,
    getAllSetsError
} = getAllSetsSlice.actions;