import React from "react";
import { Link } from "react-router-dom";
import { BiMoney } from "react-icons/bi";
import { MdOutlineFileDownload } from "react-icons/md";
import Download from "../../assets/download.png"
import { hostConfig } from "../../config";


import "./list.css";

const Index = (props) => {
  const { img, ImageElement, Title, download, path, price } = props;





  return (

    <div className="px-[12px] py-[16px]">
      <div className="d-flex justify-content-between list-ui px-[12px] py-[16px] ">
        <div className="d-flex">
          {ImageElement ?

            <div>

              {img ? <img className="rounded-[3px]" src={`${hostConfig?.HOSTING_URL}/${img}`} alt="list" style={{ width: "30px", height: "30px" }}

              /> : null}


            </div>

            : null
          }

          <div className="d-flex align-items-center">
            <p className="list-text">{Title}</p>
          </div>
        </div>

        {/* {!price ? <Link to={path} className="list-view px-3 font-sans font-[600] text-decoration-none" >View</Link> : <div className="list-view px-3 font-sans font-[600] text-decoration-none" >
          <BiMoney size={20} className="me-1" /> {`Rs ${price}`}
        </div>} */}

        {price ? <div className="list-view flex items-center "><BiMoney size={20} className="me-1" /> <p className="mb-0">{`Rs ${price}`}</p></div> : download ?
          <div className="list-view "> <img className="w-[16px]" src={Download} alt="" /><p className="mb-0 list-view pt-1 ps-1 pe-3 font-sans font-[600]">{download}</p></div> : <Link to={path} className="list-view px-3 font-sans font-[600] text-decoration-none" >View</Link>
        }
      </div>
    </div>
  );
};

export default Index;
