import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { React } from "react";

const Header = ({ activeKey, debounceSearch, search, setSearch, dataPublished, dataRejected, dataUnderRevision, allMedia }) => {

    let pathname = window.location.pathname

    useEffect(() => {
        if (search) {
            setSearch("")
        }

    }, [pathname, setSearch])




    return (
        <div className="portfolio-tabs position-relative">

            <div className="text-sm  font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">

                <ul className=" grid grid-cols-2 md:grid-cols-8 mb-0 ps-0">
                    <li className="me-2">
                        <Link to="/portfolio/published" className={`inline-block text-[#2F1409] py-4 border-b-2 text-[16px] no-underline rounded-t-lg dark:hover:text-gray-400 ${activeKey.includes("/portfolio/published") ? "border-b-2 border-[#ED652B]" : "border-none"}`}>
                            {dataPublished?.length > 0 ? `Published (${pathname === "/portfolio/published" ? dataPublished?.length : pathname === "/portfolio/published/images" ? dataPublished.filter(item => item.category === "IMAGE").length : pathname === "/portfolio/published/videos" ? dataPublished.filter(item => item.category === "VIDEO").length : dataPublished?.length})` : "Published"}
                        </Link>
                    </li>


                    <li className="me-2">
                        <Link to="/portfolio/under-revision" className={`inline-block text-[#2F1409] py-4 border-b-2   text-[16px]	no-underline rounded-t-lg dark:hover:text-gray-400 ${activeKey === "/portfolio/under-revision" ? "border-b-2 border-[#ED652B] " : "border-none"}`}>{dataUnderRevision?.length > 0 ? `Under Revision (${dataUnderRevision?.length})` : "Under Revision"}</Link>
                    </li>

                    <li className="me-2">
                        <Link to="/portfolio/rejected" className={`inline-block text-[#2F1409] py-4 border-b-2  text-[16px]	no-underline rounded-t-lg  dark:hover:text-gray-400 ${activeKey === "/portfolio/rejected" ? "border-b-2 border-[#ED652B] " : "border-none"}`}>{dataRejected?.length > 0 ? <span>Rejected (<span className="text-danger">{dataRejected?.length}</span>)</span> : "Rejected"}</Link>
                    </li>

                    <li className="me-2">
                        <Link to="/portfolio/submit-content" className={`inline-block text-[#2F1409] py-4 border-b-2  text-[16px]	no-underline rounded-t-lg  dark:hover:text-gray-400 ${activeKey === "/portfolio/submit-content" ? "border-b-2 border-[#ED652B] " : "border-none"}`}>{allMedia?.length > 0 ? `Submit Content (${allMedia?.length})` : "Submit Content"}</Link>
                    </li>

                </ul>
            </div>


            <input
                style={{ height: "40px", width: "300px", }}
                className={`position-absolute search-bar m-0 hidden  px-3 py-2  ${activeKey === "/portfolio/submit-content" || pathname === "/portfolio/published" ? "md:hidden" : "md:block"}`}
                type="search"
                placeholder="Search"
                onChange={(e) => { debounceSearch(e); setSearch(e.target.value); }}
                value={search}
            />

            <input type="search" className={`outline-none block md:hidden mx-auto w-[90%] rounded mt-2 border p-2 border-1  ${activeKey === "/portfolio/submit-content" || pathname === "/portfolio/published" ? "hidden" : "block"}`}
                placeholder="Search"
                onChange={(e) => { debounceSearch(e); setSearch(e.target.value); }}
                value={search}
            />
        </div>

    )
}

export default Header
