import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetAllMedia } from "../../api/create";
import { getMediaById } from "../../api/list";


const initialState = {
    loading: false,
    storePublishedData: [],
    error: null
}


export const fetchPublished = createAsyncThunk(
    "published/fetchpublished",
    async (params, { dispatch }) => {
        dispatch(addPublishedRequest());
        const response = await GetAllMedia(params);
        if (response?.data && !response?.error) dispatch(addPublishedData(response?.data));
        dispatch(addPublishedError(response?.error));
    }
)


export const getById = createAsyncThunk(
    "published/getByid",
    async ({ id, params }) => {
        const response = await getMediaById(id,params);
        return response
    }
)








const publishedSlice = createSlice({
    name: "published",
    initialState,
    reducers: {
        addPublishedRequest: (state) => {
            state.loading = true;
        },
        addPublishedData: (state, action) => {
            state.loading = false;
            state.storePublishedData = action.payload;
        },
        addPublishedError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});




export default publishedSlice.reducer;

export const { addPublishedRequest, addPublishedData, addPublishedError } = publishedSlice.actions;