import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { putSetsById } from "../../api/update";
import { toast } from "react-toastify";


const initalState = {
    loading: false,
    storeUserData: [],
    error: null
}

export const updateSetsById = createAsyncThunk(
    "seller/sets/updateSetsById",
    async ({
        id, params
    }, { dispatch }) => {
        dispatch(updateSetsByIdRequest());
        const response = await putSetsById(params, id);
        if (response?.data && !response?.error) {
            dispatch(updateSetsByIdData(response?.data))
            toast.success("Assets Updated Successfully")

        };

        dispatch(updateSetsByIdError(response?.error));
        toast.error(response?.error)
        return response.data
    }
)



const setsByIdSlice = createSlice({
    name: "updateSetsById",
    initialState: initalState,
    reducers: {
        updateSetsByIdRequest: (state) => {
            state.loading = true
        },
        updateSetsByIdData: (state, action) => {
            state.storeUserData = action.payload;
            state.loading = false;
        },
        updateSetsByIdError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default setsByIdSlice.reducer;

export const { updateSetsByIdRequest, updateSetsByIdData, updateSetsByIdError } = setsByIdSlice.actions;
