import { createSlice } from '@reduxjs/toolkit';

const userImageSlice = createSlice({
    name: 'userImage',
    initialState: {
        imageUrl: '',
    },
    reducers: {
        setImageUrl: (state, action) => {
            state.imageUrl = action.payload;
        },
    },
});

export const { setImageUrl } = userImageSlice.actions;
export default userImageSlice.reducer;
