import React, { useState } from "react";
import Input from "../../components/input";
import Button from "../../components/button";
import "./banner.css";
import { Link } from "react-router-dom";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { hostConfig } from "../../config";

const Company = (props) => {
  const { formikCompany, loader, numberValidate, passwordSpaceValidate, spaceValidate, } = props;

  const [showPassword, setShowPassword] = useState(false);


  return (
    <form onSubmit={formikCompany.handleSubmit} className="  scroll-company">
      <div className="row w-100 m-0">
        <p className="account-text">Account Information</p>
        <div className="col-6">
          <Input
            style={{
              border:
                formikCompany.errors.fullName && formikCompany.touched.fullName
                  ? "1px solid red"
                  : "1px solid rgba(200, 199, 199, 1)",
            }}
            placeholder="First Name"
            type="text"
            name="fullName"
            onBlur={formikCompany.handleBlur}
            onChange={(e) => spaceValidate(e)}
            value={formikCompany.values.fullName}
          />
          {formikCompany.touched.fullName &&
            formikCompany.errors.fullName && (
              <div className="text-[12px] text-red-600">{formikCompany.errors.fullName}</div>
            )}
        </div>
        <div className="col-6">
          <Input
            style={{
              border:
                formikCompany.errors.userName && formikCompany.touched.userName
                  ? "1px solid red"
                  : "1px solid rgba(200, 199, 199, 1)",
            }}
            placeholder="Last Name"
            type="text"
            name="userName"
            onBlur={formikCompany.handleBlur}
            onChange={(e) => spaceValidate(e)}
            value={formikCompany.values.userName}
          />
          {formikCompany.touched.userName &&
            formikCompany.errors.userName && (
              <div className="text-[12px] text-red-600">{formikCompany.errors.userName}</div>
            )}
        </div>
        <div className="col-12 mb-3 mt-3">
          <Input
            style={{
              border:
                formikCompany.errors.phone && formikCompany.touched.phone
                  ? "1px solid red"
                  : "1px solid rgba(200, 199, 199, 1)",
            }}
            placeholder="Phone Number"
            type="phone"
            onBlur={formikCompany.handleBlur}
            name="phone"
            onChange={(e) => numberValidate(e)}
            value={formikCompany.values.phone}
          />
          {formikCompany.touched.phone &&
            formikCompany.errors.phone && (
              <div className="text-[12px] text-red-600">{formikCompany.errors.phone}</div>
            )}
        </div>
        <div className="col-12  mb-3">
          <Input
            style={{
              border:
                formikCompany.errors.email && formikCompany.touched.email
                  ? "1px solid red"
                  : "1px solid rgba(200, 199, 199, 1)",
            }}
            placeholder="Email Address"
            type="email"
            onBlur={formikCompany.handleBlur}
            name="email"
            onChange={formikCompany.handleChange}
            value={formikCompany.values.email}
          />
          {formikCompany.touched.email &&
            formikCompany.errors.email && (
              <div className="text-[12px] text-red-600">{formikCompany.errors.email}</div>
            )}
        </div>

        <div className="col-12 mb-3">
          <Input
            style={{
              border:
                formikCompany.errors.companyName &&
                  formikCompany.touched.companyName
                  ? "1px solid red"
                  : "1px solid rgba(200, 199, 199, 1)",
            }}
            placeholder="Company Name"
            type="text"
            name="companyName"
            onBlur={formikCompany.handleBlur}
            onChange={(e) => spaceValidate(e)}
            value={formikCompany.values.companyName}
          />
          {formikCompany.touched.companyName &&
            formikCompany.errors.companyName && (
              <div className="text-[12px] text-red-600">{formikCompany.errors.companyName}</div>
            )}
        </div>
        <div className="col-12 mb-3">
          <Input

            style={{
              border:
                formikCompany.errors.gstinNumber &&
                  formikCompany.touched.gstinNumber
                  ? "1px solid red"
                  : "1px solid rgba(200, 199, 199, 1)",
            }}
            placeholder="GSTIN Number"
            type="text"
            name="gstinNumber"
            onBlur={formikCompany.handleBlur}
            onChange={(e) => spaceValidate(e)}
            value={formikCompany.values.gstinNumber}
          />
          {formikCompany.touched.gstinNumber &&
            formikCompany.errors.gstinNumber && (
              <div className="text-[12px] text-red-600">{formikCompany.errors.gstinNumber}</div>
            )}
        </div>
        <div className="col-12 mb-3">
          <div className="position-relative">
            <div className="browse-file">
              <span>

                {
                  formikCompany?.values?.gstinFile ? formikCompany?.values?.gstinFile?.name.length < 35 ? formikCompany?.values?.gstinFile?.name : formikCompany?.values?.gstinFile?.name.slice(0, 35) + "..." : "Choose GSTIN File"
                }
              </span>
              <span className="browse-button-text">Browse</span>
            </div>
            <input name="gstinFile" onChange={(e) => formikCompany.setFieldValue("gstinFile", e.target.files[0])} type="file" className="browse-button" />
            {
              formikCompany.errors.gstinFile && (
                <div className="text-[12px] text-red-600">{formikCompany.errors.gstinFile}</div>
              )}
          </div>
        </div>


        <div className="col-12 position-relative">
          <Input
            style={{
              border:
                formikCompany.errors.confirmPassword && formikCompany.touched.confirmPassword
                  ? "1px solid red"
                  : "1px solid rgba(200, 199, 199, 1)",
            }}
            placeholder={" Password"}
            type={showPassword ? "text" : "password"}
            name="confirmPassword"
            onBlur={formikCompany.handleBlur}
            onChange={(e) => {
              passwordSpaceValidate(e)
            }}
            value={formikCompany.values.confirmPassword}
          />
          {formikCompany.touched.confirmPassword &&
            formikCompany.errors.confirmPassword && (
              <div className="text-[12px] text-red-600">{formikCompany.errors.confirmPassword}</div>
            )}
          {showPassword ? (
            <BsEye
              className="cursor-pointer eye-icon"
              size={20}
              onClick={() => setShowPassword(false)}
            />
          ) : (
            <BsEyeSlash
              className="cursor-pointer eyeSlash-icon"
              size={20}
              onClick={() => setShowPassword(true)}
            />
          )}
        </div>
        <div className="d-flex align-items-center mt-2">
          <Input
            type="checkbox"
            name="agreeTerms"
            style={{ width: "13px", marginTop: "6px" }}
            onChange={formikCompany.handleChange}
            value={formikCompany.values.agreeTerms}
          />
          &nbsp;
          <span className="terms-span">
            I agree to the{" "}
            <Link
              className="terms-text"
              to={"/signup/terms-and-conditions"}
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </Link>
            &nbsp;and&nbsp;
            <Link
              className="terms-text"
              to={"/signup/privacy-policy"}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </Link>
          </span>
        </div>
        <div className="mt-3">
          <Button
            style={{
              opacity:
                loader || formikCompany.isSubmitting || !formikCompany.isValid
                  ? "0.5"
                  : "1",
              cursor:
                loader || formikCompany.isSubmitting || !formikCompany.isValid
                  ? "not-allowed"
                  : "pointer",
            }}
            type="submit"
            disabled={
              loader || formikCompany.isSubmitting || !formikCompany.isValid
            }
          >
            {loader ? (
              <div
                className="spinner-border text-light"
                role="status"
                style={{ width: "18px", height: "18px" }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Next"
            )}
          </Button>
        </div>
      </div>
      <div className="d-flex text-center justify-content-center mt-3">
        <p className="already-account">Already Have An Account?</p>&nbsp;
        <Link to="/log-in">
          <p className="login-text">Log In</p>
        </Link>
      </div>
    </form>
  );
};

export default Company;
