import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllDashboard } from "../../api/list";



const initalState = {
    loading: false,
    storeResponseData: [],
    error: null
}

export const fetchDashboardData = createAsyncThunk(
    "dashboard/fetchDashboardData",
    async (params, { dispatch }) => {
        dispatch(addDashBoardRequest());
        const response = await getAllDashboard(params);
        if (response?.data && !response?.error) dispatch(addDashboardData(response?.data));
        dispatch(addDashboardError(response?.error));
    }
)



const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initalState,
    reducers: {
        addDashBoardRequest: (state) => {
            state.loading = true
        },
        addDashboardData: (state, action) => {
            state.storeResponseData = action.payload;
            state.loading = false;
        },
        addDashboardError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default dashboardSlice.reducer;

export const { addDashBoardRequest, addDashboardData, addDashboardError } = dashboardSlice.actions;