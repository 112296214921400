import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";
import Sample from "../../../assets/images/Image-Thumb.png";
import IllustrationImage from "../../../assets/images/Illustration-Thumb.png";
import VideosImage from "../../../assets/images/Video-Thumb.png";
import "./published.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import { fetchAllsetsData } from "../../../redux/slices/get-all-sets.slice";

import Skeleton from "react-loading-skeleton";
// import { fetchDashboardData } from "../../../redux/slices/dashboard.slice";
const Index = () => {

  const dispatch = useDispatch();
  const setsData = useSelector((state) => state.getAllSets?.storeAllSetsData);

  useEffect(() => {
    dispatch(fetchAllsetsData()).then(() => setLoading(false))
  }, [dispatch])


  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  // const dashboard = useSelector((state) => state.dashboard?.storeResponseData);



  return (
    <div>
      <div className="ps-2 pe-2 md:ps-5 md:pe-5 portfolio-tabs pt-4 pb-4 position-relative">

        <div className="flex items-center justify-between mb-3">
          <span className="image-type-text">Asset Types</span>
          <div onClick={() => navigate("/image-upload")} className="text-white me-4 rounded-[8px] cursor-pointer px-3 py-2 bg-[#ed652b]">Upload Media</div>
        </div>

        <div className="responsive-images">
          <Link to="/portfolio/published/images">
            <div className="position-relative me-2">

              {loading ?
                <Skeleton height={260} width={461} />
                :
                <>
                  <img
                    src={Sample}
                    alt="sample"

                    style={{ height: "260px", width: "461px", filter: "brightness(0.5)", cursor: "pointer" }}
                  />
                  <div className="centered">Images</div>
                </>
              }
            </div>
          </Link>


          <Link to="/portfolio/published/illustration">
            <div className="position-relative me-2">

              {loading ?
                <Skeleton height={260} width={461} /> :
                <>
                  <img
                    src={IllustrationImage}
                    alt="IllustrationImage"
                    style={{ height: "260px", width: "461px", filter: "brightness(0.5)", cursor: "pointer" }}
                  />
                  <div className="centered">Illustration</div>
                </>
              }
            </div>
          </Link>

          <Link to="/portfolio/published/videos">
            <div className="position-relative me-2">

              {loading ?
                <Skeleton height={260} width={461} /> :
                <>
                  <img
                    src={VideosImage}
                    alt="VideosImage"

                    style={{ height: "260px", width: "461px", filter: "brightness(0.5)", cursor: "pointer" }}
                  />
                  <div className="centered">Videos</div>
                </>
              }
            </div>
          </Link>

        </div>

        <div className="flex items-center justify-between mb-3 mt-3">
          <span className="image-type-text">Collection Sets</span>
          <div onClick={() => navigate("/sets")} className="text-white me-4 rounded-[8px] cursor-pointer px-4 py-2 bg-[#ed652b]">Create Set</div>
        </div>
        <div className=" grid grid-cols-1 md:grid-cols-3">

          {/* <Link to="/published-images">
            <div className="position-relative me-2">

              {loading ?
                <Skeleton height={198} width={461} /> :
                <>
                  <img
                    src={Sample}
                    alt="sample"
                    width="100%"
                    style={{ filter: "brightness(0.5)", cursor: "pointer" }}
                  />
                  <div className="bottom">Natural</div>
                </>
              }
            </div>
          </Link> */}
          {setsData?.map((item, index) =>
            <Link to={`/update-sets/${item?.id}`} className="pb-2" key={index} >
              <div className="  pr-2">

                {loading ?
                  <Skeleton height={198} width={461} /> :

                  <div className="relative w-full">
                    <img className=" rounded-[3px]   "
                      src={item?.medias?.length > 0 ? `https://beyond-dream-development-local.s3.amazonaws.com/${item?.medias?.[0]?.thumbnail} ` : Sample}
                      alt="sample"
                      width="100%"
                      style={{ filter: "brightness(0.5)", cursor: "pointer", height: "260px", width: "461px", }}
                    />
                    <div className="capitalize absolute bottom-4 text-[20px] font-semibold left-4 text-white">{item?.sets_name}</div>
                  </div>
                }
              </div>
            </Link>
          )}


        </div>


      </div>
    </div>
  );
};

export default Index;
