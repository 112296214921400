/** ****************************** Import libs *********************************** */
import {
  postDataApi,
  postDataApiForm,
  postDataApiFormData,
  // postDataApiByID
} from "./actions";
import { URL_CONSTANTS } from "./urls";

export const postRegister = (params) =>
  postDataApiForm(URL_CONSTANTS.signUp, params);
export const postLogIn = (params) => postDataApi(URL_CONSTANTS.logIn, params);
export const postForgotPassword = (params) =>
  postDataApi(URL_CONSTANTS.forgotPassword, params);
export const postVerifyAccount = (params) =>
  postDataApi(URL_CONSTANTS.verifyAccount, params);
export const postImage = (params) =>
  postDataApiForm(URL_CONSTANTS.uploadImage, params);
export const postBulkImage = (params) =>
  postDataApiForm(URL_CONSTANTS.bulkUploadImage, params);
export const GetAllMedia = (params) =>
  postDataApi(URL_CONSTANTS.getAllMedia, params);
export const postAllMedia = (params) =>
  postDataApi(URL_CONSTANTS.postAllMedia, params);
export const postSetsMedia = (params) =>
  postDataApi(URL_CONSTANTS.sets, params);
export const postResetPassword = (params) =>
  postDataApi(URL_CONSTANTS.resetPassword, params);

export const postProfileImage = (params) =>
  postDataApiFormData(URL_CONSTANTS.profile, params);



export const setsAssetsDeleteBulk = (params) =>
  postDataApi(URL_CONSTANTS.setsAssetsDeleteBulk, params);


// export const postTeam = (params) => postDataApi(URL_CONSTANTS.team, params);
// export const postLead = (params) =>
//   postDataApi(URL_CONSTANTS.createLead, params);
// export const postOrg = (params) => postDataApi(URL_CONSTANTS.createOrg, params);
// export const postMeeting = (params) =>
//   postDataApi(URL_CONSTANTS.createMeeting, params);
// export const postMail = (params) =>
//   postDataApi(URL_CONSTANTS.mailCreate, params);
// export const postDeal = (params) =>
//   postDataApi(URL_CONSTANTS.createDeal, params);
// export const postImportDetail = (params) =>
//   postDataApi(URL_CONSTANTS.postImport, params);
// export const postRole = (params) =>
//   postDataApi(URL_CONSTANTS.rolesPost, params);

// export const postMultiCold = (params) =>
//   postDataApi(URL_CONSTANTS.createMultiPleColdReach, params);
// export const updateClosedReview = (params) =>
//   postDataApi(URL_CONSTANTS.updateReview, params);
