import Button from "../button";
import Modal from "react-bootstrap/Modal";

const PopUp = (props) => {
  const { headerText, children, button } = props;
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: "none", padding: "25px", paddingBottom: "10px" }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          {headerText ? headerText : "heading"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {button ? (
        <div className="p-3 pt-0">
          <Button style={{ width: "100%" }} onClick={props.onHide}>
            Close
          </Button>
        </div>
      ) : null}
    </Modal>
  );
};
export default PopUp;

// function App() {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically centered modal
//       </Button>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }

// render(<App />);
