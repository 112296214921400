import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteAsset } from "../../api/delete";
import { toast } from "react-toastify";


const initialState = {
    loading: false,
    storeAllEarningsData: [],
    error: null
}
export const fetchDeleteAsset = createAsyncThunk(
    "deleteAsset/fetchDeleteAsset",
    async (params, { dispatch }) => {
        dispatch(getDeleteAssetRequest());
        const response = await deleteAsset(params);

        if (response?.success) {
            dispatch(getDeleteAssetData(response));
            return response;
        } else {
            toast.error(response?.message || "An error occurred");
            dispatch(getDeleteAssetError(response?.message || "An error occurred"));
            return response;
        }
    }
);


const DeleteAssetSlice = createSlice({
    name: "deleteAsset",
    initialState,
    reducers: {
        getDeleteAssetRequest: (state) => {
            state.loading = true;
        },
        getDeleteAssetData: (state, action) => {
            state.storeAllEarningsData = action.payload;
            state.loading = false;
        },
        getDeleteAssetError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export default DeleteAssetSlice.reducer;
export const {
    getDeleteAssetRequest,
    getDeleteAssetData,
    getDeleteAssetError
} = DeleteAssetSlice.actions;