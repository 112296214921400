import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../components/button";
import Input from "../../components/input";
import { useNavigate } from "react-router-dom";
import { postForgotPassword } from "../../api/create";
import Main from "../../assets/world-archives-white.png";
import "./forgot.css";
import { toast } from "react-toastify";
const Index = () => {

  const [loader, setLoader] = useState(false);



  const history = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: (values, { setSubmitting }) => {

      setLoader(true);
      const data = {
        email: values?.email,
      };
      postForgotPassword(data).then((res) => {
        if (res?.success === true) {
          formik.resetForm("");

          toast.success(
            res?.message ? res?.message : "Mail has been sent successfully"
          );
          sessionStorage.setItem("forgot-email", values?.email);
          setLoader(false);
          history("/reset-password/verification");
        } else {
          setLoader(false);
          formik.resetForm("");
          toast.error(res?.message);
        }
      });
    },
  });


  return (
    <div className="banner">
      <div>
        <div className="text-center mb-3 d-flex justify-content-center">
          <img src={Main} alt="main" />
        </div>
        <div className="row w-100 m-0">
          <div className="col-md-4 col-sm-12 col-lg-4" />
          <div className="col-md-4 col-sm-12 col-lg-4">
            {" "}
            <form onSubmit={formik.handleSubmit} className="join-community">
              <div className="row w-100 m-0">
                <p className="join-community-text">Forgot Your Password</p>
                <p>
                  Enter your E-mail address below and we’ll send you a link to
                  reset your password.
                </p>
                <div className="col-12 mt-3 mb-3">
                  <Input
                    style={{
                      border:
                        formik.errors.email && formik.touched.email
                          ? "1px solid red"
                          : "1px solid rgba(200, 199, 199, 1)",
                    }}
                    placeholder={"Email Address"}
                    type="email"
                    onBlur={formik.handleBlur}
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </div>

                <div className="mt-3">
                  <Button
                    style={{

                      opacity:
                        loader || formik.isSubmitting || !formik.isValid
                          ? "0.5"
                          : "1",
                      cursor:
                        loader || formik.isSubmitting || !formik.isValid
                          ? "not-allowed"
                          : "pointer",
                    }}
                    type="submit"
                    disabled={loader || formik.isSubmitting || !formik.isValid}
                  >
                    {loader ? (
                      <div
                        className="spinner-border text-light mx-[57px]"
                        role="status"
                        style={{ width: "18px", height: "18px" }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Send Request link"
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-4 col-sm-12 col-lg-4" />
        </div>
      </div>
    </div>
  );
};

export default Index;
