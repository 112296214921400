import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setsAssetsDeleteBulk } from "../../api/create";
import { toast } from "react-toastify";


const initalState = {
    loading: false,
    storeSetsAssetsDeleteData: [],
    error: null
}

export const setsAssetsDelete = createAsyncThunk(

    "seller/sets/assets/delete",
    async (
        params
    , { dispatch }) => {
        dispatch(setsAssetsDeleteRequest());
        const response = await setsAssetsDeleteBulk(params);
        if(response?.data && !response?.error){ dispatch(setsAssetsDeleteData(response?.data))
        toast.success(response?.message)
        
        };

        dispatch(setsAssetsDeleteError(response?.error));  
        toast.error(response?.error)
        return response.data
    }
)



const setsAssetsDeleteBulkSlice = createSlice({
    name: "setsAssetsDelete",
    initialState: initalState,
    reducers: {
        setsAssetsDeleteRequest: (state) => {
            state.loading = true
        },
        setsAssetsDeleteData: (state, action) => {
            state.storeSetsAssetsDeleteData = action.payload;
            state.loading = false;
        },
        setsAssetsDeleteError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default setsAssetsDeleteBulkSlice.reducer;

export const { setsAssetsDeleteRequest, setsAssetsDeleteData, setsAssetsDeleteError } = setsAssetsDeleteBulkSlice.actions;
