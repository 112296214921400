import React from 'react'
import { PiDotOutlineFill } from "react-icons/pi";
import "./terms-and-condition.css";

const TermsAndConditions = () => {
    return (
        <div>
            <div className="tac-container">
                <div className=' fs-22 fw-700 ft_mg_btm ft_ul_cmn'>
                    Website terms and conditions
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        1.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <span className=' ft_ul_cmn fw-600 mg_btm_one'>Attention</span>
                        <span className=' fw-400'>Please read these terms carefully before using this website. Using this
                            website indicates that you accept these terms. If you do not accept these
                            terms and conditions ("terms"), do not use this website. By clicking on the
                            button that stipulates agreement to the following terms and by using this
                            website, you agree to be bound by the terms and conditions of this agreement.
                        </span>
                    </div>
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        2.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <span className=' ft_ul_cmn fw-600 mg_btm_one'>Disclaimer</span>
                        <div className='tac-contentCommonSty  fw-400 gapProvTwo'>
                            <span >
                                Websites and companies listed on the World Archives images website are not controlled by World Archives and we are not responsible for any content contained in any such website or company or any loss suffered by you in relation to your use of such websites and companies. We have listed the sites and contacts due to their relevance and potential help to our contributors, but we cannot monitor performance or content. You waive any and all claims against World Archives regarding the inclusion of links to outside websites or your use of those websites.
                            </span>
                            <span>
                                Welcome to the World Archives website. Below are the terms that govern your use of this site.
                            </span>
                            <span>
                                These terms constitute a legal agreement between you and World Archives. ("World Archives" "we" or "our"). Please read this agreement ("agreement") in its entirety before you continue to use this website or download any image. If you do not agree, discontinue using the site.
                            </span>
                        </div>
                    </div>
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        3.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <span className=' ft_ul_cmn fw-600 mg_btm_one'>Ownership of our website</span>
                        <span className=' fw-400'>
                            This website is owned and operated by World Archives. All of the content featured or displayed on this website, including, but not limited to, text, graphics, photographs, images, moving images, sound, illustrations and software ("content"), is owned by World Archives, its licensors and its content providers.
                        </span>
                    </div>
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        4.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <span className=' ft_ul_cmn fw-600 mg_btm_one'>Protection of intellectual property</span>
                        <div className='tac-contentCommonSty  fw-400 gapProvTwo'>
                            <span >
                                All elements of World Archives’ websites, including, but not limited to, the general design and the content, are protected by trademarking, copyright, moral rights, trademark and other laws relating to intellectual property rights. Except as explicitly permitted under this or another agreement with World Archives or one of its subsidiaries, no portion or element of this website or its Content may be copied or retransmitted via any means and this website, its content and all related rights shall remain the exclusive property of World Archives or its licensors unless otherwise expressly agreed.</span>
                            <span>
                                "World Archives Images", "World Archives", "_____”and the World Archives logo, are trademarks of World Archives or its subsidiaries and may be registered in some countries.</span>
                        </div>
                    </div>
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        5.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <span className=' ft_ul_cmn fw-600 mg_btm_one'>Indemnification for breach</span>
                        <div className='tac-contentCommonSty  fw-400'>
                            <span>
                                You agree to indemnify World Archives, its subsidiaries, its affiliates and licensors against any losses, expenses, costs or damages howsoever incurred by any or all of them as a result of your breach of the terms of this agreement or your unauthorised use of the content and related rights.
                            </span>
                        </div>
                    </div>
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        6.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <span className=' ft_ul_cmn fw-600 mg_btm_one'>How you may use the content on this website</span>
                        <div className='tac-contentCommonSty  fw-400 gapProvTwo'>
                            <span>
                                Images, other than comp images, may only be used commercially once you have entered into a licence agreement and paid the relevant fee. Comp images may only be used for your internal layouts for sample purposes or for proofing presentations to end clients.
                            </span>
                            <span>
                                World Archives's copyright notice and image identification reference which appear in the image file must remain with your digital copy of the image at all times. You will retain the copyright notice, the name of World Archives and the respective artist, the respective RM or RF image ID and any other information or metadata that is embedded in the electronic file that comprises any image which you have downloaded from the website or otherwise received from World Archives. Failure to maintain the integrity of the copyright information will constitute a breach of this agreement.
                            </span>
                            <span>
                                World Archives does not grant you any licence to use these images in any final materials distributed either internally or externally by yourself or your company, including any electronic or online system. Except for this limited, internal testing and sample use, World Archives grants you no other rights, and you may not distribute, sublicense or make available for use or distribution any comp images.
                            </span>
                            <span>
                                World Archives will only issue high resolution images for "comps" or layout purposes only after a separate agreement is entered into.
                            </span>
                            <div className='tac-contentCommonSty gapProvTwo mrg-tp mg_btm_one'>
                                <span>You may use our site only for lawful purposes. You may not use this website:</span>
                                <div className='tac-contentCommonSty mg-lft gapProvOne'>
                                    <div className='tac-contentContainer-CommonSty'>
                                        <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                        <span>In any way that breaches any applicable local, national or international law or regulation;</span>
                                    </div>
                                    <div className='tac-contentContainer-CommonSty'>
                                        <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                        <span>
                                            In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;
                                        </span>
                                    </div>
                                    <div className='tac-contentContainer-CommonSty'>
                                        <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                        <span>
                                            To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam);
                                        </span>
                                    </div>
                                    <div className='tac-contentContainer-CommonSty'>
                                        <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                        <span>
                                            To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='tac-contentCommonSty gapProvTwo mrg-tp'>
                                <span>You also agree:</span>
                                <div className='tac-contentCommonSty mg-lft gapProvOne'>
                                    <div className='tac-contentContainer-CommonSty'>
                                        <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                        <span>Not to reproduce, duplicate, copy or re-sell any part of our site in contravention of this agreement; and</span>
                                    </div>
                                    <div className='tac-contentContainer-CommonSty'>
                                        <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                        <span>
                                            Not to access without authority, interfere with, damage or disrupt any part of our website.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        7.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <span className=' ft_ul_cmn fw-600 mg_btm_one'>Copyright infringement policy</span>
                        <span className=' fw-400'>
                            In accordance with the Digital Millennium Copyright Act ("DMCA") and other applicable laws, World Archives has adopted a policy of terminating, in appropriate circumstances and at World Archives's sole discretion, account holders who infringe the intellectual property rights of World Archives or any third party.
                        </span>
                    </div>
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        8.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <span className=' ft_ul_cmn fw-600 mg_btm_one'>Copyright complaints</span>
                        <span className=' fw-400'>
                            If you believe that any material on the World Archives website infringes upon any copyright that you own or control, you may file a notification of such infringement with our designated agent as set forth below:
                        </span>
                        <div className='tac-content-copyRight mg_btm_one mg_btm_two fw-600'>
                            © Copyright World Archives – Inspire Films Pvt. Ltd
                        </div>
                        <div className='tac-contentCommonSty gapProvTwo mrg-tp mg_btm_one'>
                            <span>Notification must include the following information:</span>
                            <div className='tac-contentCommonSty mg-lft gapProvOne'>
                                <div className='tac-contentContainer-CommonSty'>
                                    <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                    <span>Your name, address, telephone number, and email address;</span>
                                </div>
                                <div className='tac-contentContainer-CommonSty'>
                                    <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                    <span>
                                        A description of the copyrighted work that you claim has been infringed;
                                    </span>
                                </div>
                                <div className='tac-contentContainer-CommonSty'>
                                    <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                    <span>
                                        The exact URL, World Archives Image ID or a description of where the material is located;
                                    </span>
                                </div>
                                <div className='tac-contentContainer-CommonSty'>
                                    <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                    <span>
                                        A statement by you that you have a good faith belief that the content you claim infringes your copyright interest was copied without your authorization or that of the copyright owner or his/her/its agent; and
                                    </span>
                                </div>
                                <div className='tac-contentContainer-CommonSty'>
                                    <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                    <span>
                                        A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are either the copyright owner or authorized to act on the copyright owner's behalf and that you do not wish the content to be displayed in the future on the Site. If you are authorized to act on behalf of the copyright owner, you must provide an electronic or physical signature.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        9.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <div className=' ft_ul_cmn fw-600 mg_btm_one'>Model and property releases</div>
                        <div className='tac-contentCommonSty mg-lft gapProvOne'>
                            <div className='tac-contentContainer-CommonSty'>
                                <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                <span>World Archives gives no representations or warranties whatsoever as to the existence of any Release associated with the image.</span>
                            </div>
                            <div className='tac-contentContainer-CommonSty'>
                                <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                <span>
                                    World Archives gives no representations or warranties whatsoever with respect to the use of names, trademarks, logos, uniforms, registered or copyrighted designs or works of art depicted in the image.
                                </span>
                            </div>
                            <div className='tac-contentContainer-CommonSty'>
                                <span className='mg-rgt'><PiDotOutlineFill fontSize='0.8rem' /></span>
                                <span>
                                    You must satisfy yourself that all necessary rights, consents or permissions as may be required for Reproduction of the image have been secured.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        10.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <span className=' ft_ul_cmn fw-600 mg_btm_one'>Email</span>
                        <div className='tac-contentCommonSty  fw-400 gapProvTwo'>
                            <span >
                                If you authorise us to send you promotional materials, we may do so using either the email or the mailing addresses you provided on the registration or billing information screens. From time to time where we are entitled to do so we may notify you of special promotions, new products or services, or other information that may interest you.
                            </span>
                            <span>
                                Should you not wish to receive this type of information, you will be able to express this desire by clicking on the appropriate 'unsubscribe' or 'change your preferences' button or checkbox available on our communications to you, or by modifying your profile at any time. We will not send you information if you expressly inform us you do not wish to receive such materials.
                            </span>
                            <span>
                                If you would like to revise the information provided to us or feel that what we currently have on record is incorrect, please contact us at _____ or you may update the information in the "My World Archives" section of this website.
                            </span>
                        </div>
                    </div>
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        11.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <span className=' ft_ul_cmn fw-600 mg_btm_one'>Data protection disclaimer</span>
                        <div className='tac-contentCommonSty  fw-400 gapProvTwo'>
                            <span >
                                Please note we may collect personal information about you. Our Privacy Policy sets out the basis on which we collect your personal information, what information we collect and how, and how we process your data.
                            </span>
                        </div>
                    </div>
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        12.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <span className=' ft_ul_cmn fw-600 mg_btm_one'>About cookies</span>
                        <div className='tac-contentCommonSty  fw-400 gapProvTwo'>
                            <span >
                                When you visit the World Archives website, we place a text file called a "cookie" in the browser directory of your computer's hard drive. A cookie is a small piece of information that a website can store on your web browser and later retrieve. The cookie cannot be read by any website other than the one that set up the cookie. Please see our Cookie Policy.
                            </span>
                            <span>
                                Cookies enable this website to recognise the information you have consented to give to this website, such as the lightboxes you have created, and help us determine what portions of this website are most appropriate for your professional needs. As a result, cookies will allow you to retrieve previous image search results, access lightboxes with ease, and view your previous invoices.
                            </span>
                            <span>
                                We do not use cookies to examine your surfing behaviour before or after leaving the World Archives website.
                            </span>
                        </div>
                    </div>
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        13.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <span className=' ft_ul_cmn fw-600 mg_btm_one'>Disclaimers</span>
                        <div className='tac-contentCommonSty  fw-400 gapProvTwo'>
                            <span >
                                This website and its content are provided "as is" and World Archives excludes to the fullest extent permitted by applicable law any warranty, express or implied, including, without limitation, any implied warranties of merchantability, satisfactory quality or fitness for a particular purpose. the functions embodied on, or in the materials of, this website are not warranted to be uninterrupted or without error. You, not World Archives, assume the entire cost of all necessary servicing, repair or correction due to your use of this website.
                            </span>
                            <span>
                                Except as specifically stated in these terms, the Security & Privacy Policy, or elsewhere on this website, or as otherwise required by applicable law, neither World Archives nor its directors, employees, licensors, content providers, affiliates or other representatives will be liable for damages of any kind (including, without limitation, lost profits, direct, indirect, compensatory, consequential, exemplary, special, incidental, or punitive damages) arising out of your use of, your inability to use, or the performance of this website or the content whether or not we have been advised of the possibility of such damages.
                            </span>
                            <span>
                                There may be links to other websites from the World Archives website; however, these other websites are not controlled by World Archives and we are not responsible for any content contained on any such website or any loss suffered by you in relation to your use of such websites. You waive any and all claims against World Archives regarding the inclusion of links to outside websites or your use of those websites.
                            </span>
                            <span>
                                World Archives uses reasonable efforts to ensure the accuracy, correctness and reliability of the content, but we make no representations or warranties as to the content's accuracy, correctness or reliability. You should note that in certain countries captions, keywording and other information associated with an Image(s)/Video(s) may have been translated from the original language into another language using an automated machine translation process that World Archives has had no input into or control over. Accordingly, World Archives disclaims any liability for inaccurate, misleading, defamatory, insulting, offensive, infringing or unlawful content created as a result of or arising out of such translation process. If you have a complaint about inaccurate, misleading, defamatory, insulting, offensive, infringing or unlawful content please send a specific and detailed notice of infringement _____________. We will respond within a reasonable time taking into account the circumstances of the complaint."
                            </span>
                            <span>
                                Some US states and foreign countries do not permit the exclusion or limitation of implied warranties or liability for certain categories of damages. Therefore, some or all of the limitations above may not apply to you to the extent they are prohibited or superseded by state or national provisions.
                            </span>
                        </div>
                    </div>
                </div>
                <div className='tac-contentContainer-CommonSty ft_mg_btm'>
                    <div className='mg-rgt  fw-600'>
                        14.
                    </div>
                    <div className='tac-contentCommonSty'>
                        <span className=' ft_ul_cmn fw-600 mg_btm_one'>Governing law and jurisdiction</span>
                        <div className='tac-contentCommonSty  fw-400 gapProvTwo'>
                            <span >
                                This agreement is governed by the laws of India and Courts at Mumbai whose courts are the courts of exclusive jurisdiction.
                            </span>
                            <span>
                                This agreement will not be governed by the United Nations convention on contracts for the international sale of goods or any other similar convention or laws, the application of which are expressly excluded.
                            </span>
                            <span>
                                We reserve the right to change these terms, the Security & Privacy Policy, Cookie Policy, prices, information and available contractual license terms featured on this website without notice. These conditions set out the entire agreement between World Archives and you relating to your use of this website.
                            </span>
                            <span>
                                No action of World Archives, other than an express written waiver or amendment, may be construed as a waiver or amendment of any of these terms, the Cookie Policy or Security & Privacy Policy. Should any clause of these terms or Security & Privacy Policy be found unenforceable, wherever possible this will not affect any other clause, and each will remain in full force and effect.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions
