import React, { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import "./portfolio.css";
import { useLocation } from "react-router-dom";
import Header from "./layout/header";
import { useDispatch, useSelector } from "react-redux";
import { fetchUnderRevision } from "../../redux/slices/under-revision.slice";
import { fetchPublished } from "../../redux/slices/published.slice";
import { fetchRejected } from "../../redux/slices/rejected.slice";
import { debounce } from "../../utils/functions";
import { fetchAllMedia } from "../../redux/slices/get-all-media.slice";
const Index = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const dataPublished = useSelector((state) => state.published?.storePublishedData);
  const dataRejected = useSelector((state) => state.rejected?.storeResponseData);
  const dataUnderRevision = useSelector((state) => state.underRevision?.storeResponseData);
  const allMedia = useSelector((state) => state.getAllMedia?.storeResponseData?.media);


  useEffect(() => {
    dispatch(fetchUnderRevision({
      search: { mediaStatus: "SUBMIT", visibility: "NOT_PUBLISHED" },
      sortBy: { createdAt: true }
    }))
    dispatch(fetchPublished({
      search: { visibility: "PUBLISHED", },
      sortBy: { createdAt: true }
    }))
    dispatch(fetchRejected({
      search: { mediaStatus: "REJECTED", visibility: "NOT_PUBLISHED" },
      sortBy: { createdAt: true }
    }))
    dispatch(fetchAllMedia({
      search: { mediaStatus: "PENDING", visibility: "NOT_PUBLISHED" },
      sortBy: { createdAt: true }
    }))

  }, [location])





  const handleSearch = (e) => {
    if (window.location.pathname?.includes("/portfolio/published")) {
      dispatch(fetchPublished({
        search: { visibility: "PUBLISHED", title: e.target.value },
      }))
    } else if (window.location.pathname === "/portfolio/under-revision") {
      dispatch(fetchUnderRevision({
        search: { mediaStatus: "SUBMIT", visibility: "NOT_PUBLISHED", title: e.target.value },
      }))
    } else if (window.location.pathname === "/portfolio/rejected") {
      dispatch(fetchRejected({
        search: { mediaStatus: "REJECTED", visibility: "NOT_PUBLISHED", title: e.target.value },
      }))
    }
  }





  const debounceSearch = useCallback(
    debounce(handleSearch, 500),
    []
  );


  return (
    <div>
      <div className="px-1 md:px-5 md:mx-[50px]   portfolio-tabs pt-4 pb-4 position-relative">
        <p className="portfolio-text mb-0">Portfolio</p>

        <Header activeKey={location.pathname} search={search} setSearch={setSearch} debounceSearch={debounceSearch} dataRejected={dataRejected?.media} dataUnderRevision={dataUnderRevision?.media} dataPublished={dataPublished?.media} allMedia={allMedia} />

        <Outlet />






      </div>
    </div>
  );
};

export default Index;
