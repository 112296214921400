/** ****************************** Import libs *********************************** */


const URL_CONSTANTS = {
  signUp: "seller/register",
  logIn: "seller/login",
  forgotPassword: "seller/forgot-password",
  verifyAccount: "seller/resend-email",
  dashboard: "seller/dashboard",
  insights: "seller/insights",
  recentlyAdded: "seller/recent-added",
  uploadImage: "seller/media/add-media-content",
  bulkUploadImage: "seller/media/add-media-content/bulk",
  getAllMedia: "seller/media/search",
  updateAllMedia: "seller/media/meta-data",
  postAllMedia: "seller/media/submit",
  getMediaById: "seller/media",
  getUserInfo: "seller",
  sets: "seller/sets",
  updateSets: "seller/non-sets",
  selectedUpdateSets: "seller/sets/assets",
  setsAssetsDeleteBulk: "seller/sets/assets/items",
  dashboardEarnings: "seller/earnings",
  earnings: "seller/earnings/month",
  delete: "seller/media",
  topSales: "seller/top-sales",
  resetPassword: "seller/reset-password",
  profile: "seller/profile-image"

};
export { URL_CONSTANTS };
