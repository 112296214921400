import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import List from "./list";
import "./published-images.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchPublished } from "../../../redux/slices/published.slice";
import { useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";


import NoRecordsFound from "../../../components/no-records-found";
// import Loader from "../../../components/loader/loader";

const Index = () => {


  const [loading, setLoading] = useState(true);


  const dispatch = useDispatch();
  const dataPublished = useSelector((state) => state.published?.storePublishedData);

  useEffect(() => {
    dispatch(fetchPublished({
      search: { visibility: "PUBLISHED", category: "IMAGE" },
      sortBy: { createdAt: true }
    })).then(() => setLoading(false))
  }, [])


  const navigate = useNavigate();





  return (
    <div>



      <div className="ps-5 pe-5 portfolio-tabs-1 pt-4 pb-4 position-relative">
        <div>
          <p className="back-images ">
            <IoMdArrowBack className="cursor-pointer" onClick={() => navigate("/portfolio/published")} />
            &nbsp;Images
          </p>
          {/* <List details={dataPublished} /> */}

          {loading ?
            // <Loader />

            <Skeleton className=" rounded mb-4" count={4} height={150} width={"80%"} />

            :

            dataPublished?.media?.length ?
              <List details={dataPublished} /> :
              <NoRecordsFound />
            // <Skeleton  className=" rounded mb-4" count={2} height={250} width={"80%"}/>

          }
        </div>
      </div>
    </div>
  );
};

export default Index;
