import React, { useEffect, useState } from "react";
// import Pagination from "../../../components/Pagination/Pagination";
// import Pagination from 'react-bootstrap/Pagination';
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { formatAmount, formatDownloads } from "../../../utils/functions";

import { Link, useNavigate } from "react-router-dom";
import "./published-files.css";
//icons--------------------------------
import { FaArrowLeft } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../../redux/slices/dashboard.slice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import NoRecordFound from "../../../components/no-records-found";
const Index = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = 10

    ;

  useEffect(() => {
    dispatch(fetchDashboardData()).then(() => setLoading(false));
  }, [dispatch])

  const dashboard = useSelector((state) => state.dashboard?.storeResponseData);
  const navigate = useNavigate();

  const indexOfLastProduct = currentPage * listPerPage;
  const indexOfFirstProduct = indexOfLastProduct - listPerPage;
  const currentList = dashboard?.publishedFiles?.slice(indexOfFirstProduct, indexOfLastProduct);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  return (
    <div className="container-fluid mt-5">
      <div className="  px-[0px]  md:px-[50px]">
        <div className="flex gap-2 text-[20px] font-medium mb-3 items-center"><FaArrowLeft
          className="cursor-pointer" onClick={() => navigate("/home")}
        /> Published Files ({dashboard?.publishedFiles?.length ? dashboard?.publishedFiles?.length : 0})</div>

        <div className="table-border min-h-[65vh]">
          <table className="w-100  published-file-list">
            <thead>
              <tr>

                <th scope="col">Title</th>
                <th scope="col">Content Id</th>
                <th scope="col">Format</th>
                <th scope="col">Usage</th>
                <th scope="col">Price</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>



              {loading ?
                <tr>
                  <td colSpan="6" className="text-center">
                    <Skeleton count={10} height={40} />
                  </td>
                </tr>
                :
                dashboard?.publishedFiles?.length > 0 ? currentList.map((item, index) => (
                  <tr key={index}>
                    <td className="d-flex align-items-center md:w-auto w-[350px]">
                      <img className="rounded-[3px] h-[30px]" src={`https://beyond-dream-development-local.s3.amazonaws.com/${item?.thumbnailSrc}`} alt="table-1" width="45px" />
                      &nbsp; {item.title}
                    </td>
                    <td>{item?.id}</td>
                    <td>{item?.mediainfo?.format}</td>
                    <td>{item?.usageFor}</td>
                    <td>₹  {formatAmount(item?.price)}</td>
                    <td className="view-text-published"><Link to={`/portfolio/published/images/${item?.id}`} className="text-decoration-none">View</Link>
                    </td>
                  </tr>

                ))
                  :
                  <tr>
                    <td colSpan="6" className="text-center">
                      <NoRecordFound />
                    </td>
                  </tr>
              }



            </tbody>
          </table>
        </div>

        <div className="flex items-center justify-between">
          <p className="mb-0">Showing {indexOfFirstProduct + 1} to {indexOfLastProduct} of {dashboard?.publishedFiles?.length} entries </p>

          <div className='flex justify-center items-center gap-2 my-5'>
            <MdOutlineKeyboardDoubleArrowLeft className="cursor-pointer" size={20}
              onClick={
                () => {
                  if (currentPage > 1) {
                    paginate(currentPage - 1)
                  }
                }
              }
            />
            {Array.from({ length: Math.ceil(dashboard?.publishedFiles?.length / listPerPage) }).map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`px-3 py-1 mx-1 rounded-full ${currentPage === index + 1 ? 'bg-[#ed652b] text-white' : 'text-black bg-transparent'}`}
              >
                {index + 1}
              </button>
            ))}
            <RiArrowRightDoubleFill
              onClick={
                () => {
                  if (currentPage < Math.ceil(dashboard?.publishedFiles?.length / listPerPage)) {
                    paginate(currentPage + 1)
                  }
                }
              }
              className="cursor-pointer" size={20} />
          </div>
        </div>


      </div>
    </div>
  );
};

export default Index;
