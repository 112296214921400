// ****************** React Lib *************************//
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
// ****************** Formik ***************************//
import { useFormik } from "formik";
import * as Yup from "yup";

// ****************** Assets *****************************//
import BlueTick from "../../assets/blue-tick.svg";

// ****************** Redux ***************************//
import { postMediaData } from "../../redux/slices/sets.slice";
import { fetchAllMedia } from "../../redux/slices/get-all-media.slice";
import { useSelector, useDispatch } from "react-redux";

// ****************** Components ***************************//
import NoRecordsFound from "../../components/no-records-found";

// ****************** Styles *****************************//
import "./sets.css";
import { toast } from "react-toastify";

const Index = () => {

  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedData, setSelectedData] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const paramId =  useParams()
  const datas = useSelector((state) => state.getAllMedia?.storeResponseData?.media)


  useEffect(() => {
    dispatch(fetchAllMedia({ search: { visibility: "PUBLISHED" } }));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      name: "",
      selectedCount: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const payload = {
        name: values?.name,
        medias: selectedData?.map((item) => {
          return {
            id: item?.id,
            category: item?.category,
            media_info: item?.mediainfo,
            thumbnail: item?.thumbnailSrc
          }
        }),
      }
      dispatch(postMediaData(payload)).then((res) => {
        if (res) {

          // navigate("/home")
          if (res?.payload?.data) {
            toast.success("Sets Created Successfully");
            navigate("/home")
          } else {
            toast.error(res?.payload?.messages || "An error occurred");
          }
        }
      })


    },
  })




  const handleCheckboxChange = (e, item) => {

    const selecteddata = [...selectedData];
    if (e.target.checked && selecteddata.indexOf(item) === -1) {
      selecteddata.push(item);
    } else {
      selecteddata.splice(selecteddata.indexOf(item), 1);
    }
    setSelectedData(selecteddata);
    setSelectedCount(selecteddata.length);
  };



  const handleClearSelection = () => {
    const checkboxes = document.querySelectorAll(".checkbox-style");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    setSelectedData([]);
    setSelectedCount(0);
  };

  const spaceValidate = (event) => {
    const { name, value } = event.target;
    const newValue = value.replace(/^\s+/g, '').replace(/\s+/g, ' ');
    formik.setFieldValue(name, newValue)
  };


  return (
    <div className="container-fluid">
      <div className="mt-4 responsive-sets">
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex justify-content-between ">
            <p className="back-images">
              <Link to="/home" style={{ color: "black" }}>
                <IoMdArrowBack />
              </Link>
              &nbsp;Create a Set
            </p>
            <button className={`create-set ${selectedCount === 0 ? "bg-[#D9D9D9]" : ""}`} type="submit" >
              Create
            </button>
          </div>
          <div>
            <input
              className="add-title-create border-b-2  text-[#2F1409]"
              type="text"
              placeholder="Add a Title"
              name="name"
              value={formik.values.name}
              onChange={(e) => spaceValidate(e)}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-danger text-[14px]">{formik.errors.name}</div>
            )}
          </div>
        </form>

        {selectedCount > 0 ? (
          <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
            <p className="picture-text"> Pictures</p>
            <div className="d-flex align-items-center">
              <p className="selected-text d-flex">
                <img src={BlueTick} alt="tick" width="18px" />
                &nbsp;{selectedCount}&nbsp; {selectedCount > 1 ? "Pictures are Selected" : "Picture is Selected"}
              </p>
              &nbsp;&nbsp;
              <button onClick={handleClearSelection} className="clear-btn">
                Clear all
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
            <p className="picture-text">Pictures</p>
            <div
              style={{
                visibility: "hidden",
                display: "flex",
                alignItems: "center",
              }}
            >
              <p className="selected-text d-flex">
                <img src={BlueTick} alt="tick" width="18px" />
                &nbsp;{selectedCount}&nbsp;Pictures is Selected
              </p>
              &nbsp;&nbsp;
              <button onClick={handleClearSelection} className="clear-btn">
                Clear all
              </button>
            </div>
          </div>
        )}
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 5 }}>
          <Masonry gutter="10px">
            {datas?.length > 0 ? (
              datas
                .filter(data => data.category === "IMAGE") //filtered only images from published files
                .map(data => (
                  <div key={data.id} className="relative cursor-pointer">
                    <input
                      id={`checkbox-${data.id}`}
                      type="checkbox"
                      className="position-absolute checkbox-style"
                      onChange={e => handleCheckboxChange(e, data)}
                    />
                    <label htmlFor={`checkbox-${data.id}`}>
                      <img
                        className="max-w-full rounded-lg"
                        src={`https://beyond-dream-development-local.s3.amazonaws.com/${data.thumbnailSrc}`}
                        alt=""
                      />
                    </label>
                  </div>
                ))
            ) : (
              <div>No images available</div>
            )}
          </Masonry>
        </ResponsiveMasonry>


        {!datas?.length &&
          <div>
            <NoRecordsFound />
          </div>
        }

      </div>
    </div>
  );
};

export default Index;
