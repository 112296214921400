// ********************** React Lib *************************//
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// ******************** Assets *****************************//
import Save from "../../assets/save.svg";
import Wishlist from "../../assets/wish-list.svg";
import Money from "../../assets/money.svg";


// ******************** Redux ***************************//
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardData } from "../../redux/slices/dashboard.slice";


// ******************** Components ***************************//
import Button from "../../components/button";
import Card from "../../components/card";
import Upload from "../../components/drag-and-drop";
import ListView from "../../components/list-view";
import Monthly from "../../components/monthly/monthly";
import Lifetime from "../../components/lifetime/lifetime";
import { formatAmount, formatDownloads } from "../../utils/functions";
// ******************** Styles *****************************//
import "./home.css";
import Loader from "../../components/loader/loader";

const Home = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const dashboard = useSelector((state) => state.dashboard?.storeResponseData);
  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.removeItem("email");
    dispatch(fetchDashboardData()).then((res) => {
      setLoading(false);
    });



  }, [dispatch]);




  return (
    <div className="container-fluid">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 ps-0 ps-lg-5 pe-0 pe-lg-5 pb-3 pt-3 w-100 m-0">
        <div className="mt-3">
          <Upload />
          {/* <Card img={Money} text={"Unpaid Earnings"} rate={"₹0.00"} /> */}
        </div>
        <div className="mt-3">
          <Card
            loading={loading}
            img={Money}
            text={"Unpaid Earnings"}
            rupeeSymbol={"₹"}
            rate={dashboard?.totalUnPaidEarning ? formatAmount(dashboard.totalUnPaidEarning) : 0}
          />
        </div>
        <div className="mt-3">
          <Card loading={loading} img={Save} text={"Downloads"} rate={dashboard?.totalDownloads ? formatDownloads(dashboard?.totalDownloads) : 0} />
        </div>
        <div className="mt-3">
          <Card loading={loading} img={Wishlist} text={"Wishlists"} rate={dashboard?.totalWishlist ? formatDownloads(dashboard?.totalWishlist) : 0} />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2  gap-4 ps-0 ps-lg-5 pe-0 pe-lg-5">
        <div className="upload-box mt-2">
          <div className="flex justify-between items-center">
            <Link className="text-decoration-none" to="/published-files">
              <p className="published-text">Published Files</p>
            </Link>
            {dashboard?.publishedFiles?.length > 0 ? (
              <Link to={"/published-files"} className=" text-decoration-none cursor-pointer text-[14px] text-[#4946C3] font-sans m-0 px-4">View All</Link>
            ) : null}
          </div>


          {loading ?

            <div role="status" class=" animate-pulse">
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
            </div>
            :
            dashboard?.publishedFiles?.length > 0 ? (
              dashboard?.publishedFiles?.map((item, index) => (
                index <= 4 ? <ListView path={`/portfolio/published/images/${item?.id}`} img={item?.thumbnailSrc} category={item?.category} ImageElement Title={item?.title} />
                  : null
              ))
            ) : (
              <div className="upload-div">
                <p className="text-justify text-[#888888] text-[14px]">
                  When you upload your images we will show you here the
                  <p className="text-center text-[#888888 text-[14px]">
                    ones which have better performance.
                  </p>
                  <Link to="/image-upload">
                    <Button buttonStyles="mt-3">Upload</Button>
                  </Link>
                </p>
              </div>
            )}





        </div>
        <div className="upload-box mt-2">
          <p className="published-text">Latest Downloads</p>
          {loading ?
            <div role="status" className=" animate-pulse">
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
            </div>
            :
            dashboard?.lastestDownloads?.length > 0 ? (
              dashboard?.lastestDownloads?.map((item, index) => (
                index <= 4 ? <ListView img={item?.name?.thumbnailSrc} download={formatDownloads(item?.value)} category={item?.name?.category} ImageElement Title={item?.name?.title} />
                  : null
              ))
            ) : (
              <div className="upload-div">
                <p className="text-justify latest-section text-[14px] text-[#888888]">
                  See your latest downloads from around the world.
                  <br />
                  <a
                    className="learn-more-text text-[14px] text-[#4787E6] font-sans"
                    href="/"
                    target="_blank"
                  >
                    Learn More
                  </a>
                </p>
              </div>
            )}
          {/* <div className="upload-div">
            <p className="text-justify latest-section text-[14px] text-[#888888]">
              See your latest downloads from around the world.
              <br />
              <a
                className="learn-more-text text-[14px] text-[#4787E6] font-sans"
                href="/"
                target="_blank"
              >
                Learn More
              </a>
            </p>
          </div> */}
        </div>
      </div>
      <div className="d-flex justify-content-between ps-0 ps-lg-5 pe-0 pe-lg-5 mt-3">
        <div className="upload-box">
          <div className="container-fluid">
            <div className="flex justify-content-between items-center">
              <div>
                <p className="published-text text-[#2F1409] font-sans mt-2">
                  Earnings
                </p>
              </div>
              <div className="bg-[#F5F5F5] rounded-full flex items-center p-1 mt-2">
                <label className=" relative inline-flex  cursor-pointer select-none items-center justify-center rounded-md ">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span
                    className={`flex items-center space-x-[6px]  py-2 px-[18px] text-sm font-sans 
                  ${!isChecked
                        ? "rounded-full text-white bg-[#ED652B]"
                        : "text-black"
                      }`}
                  >
                    Lifetime
                  </span>
                  <span
                    className={`flex items-center space-x-[6px]  py-2 px-[18px] text-sm font-sans ${isChecked
                      ? "rounded-full text-white bg-[#ED652B]"
                      : "text-black"
                      }`}
                  >
                    Monthly
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="upload-div">
            {/* <p className="text-justify latest-section text-[14px] text-[#888888]">
              Looks like you don't have any sales yet.
            </p> 
            <Monthly /> */}
            {isChecked ? <Monthly loading={loading} dashboard={dashboard} /> : <Lifetime loading={loading} dashboard={dashboard} />}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2  gap-4 ps-0 ps-lg-5 pe-0 pe-lg-5 mt-3">
        <div className="col-12 col-lg upload-box mt-2">
          <div className="d-flex justify-content-between items-center">
            <p className="published-text">Sets</p>

            <div>
              {dashboard?.sets?.length > 0 ? (
                <Link to={"/sets-list"} className="text-decoration-none text-[#4946C3] ">View All</Link>) : null
              }
              {dashboard?.sets?.length > 0 ? (
                <Link className="ms-2" to={"/sets"}>
                  <button className="bg-[#ED652B]  rounded-full text-[#FFFFFF] w-[100px] mx-3 px-[20px] py-[5px]">Create</button>
                </Link>
              ) : null}
            </div>
          </div>

          {loading ?
            <div role="status" className=" animate-pulse">

              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
            </div>
            :
            <div className="text-justify">

              {dashboard?.sets?.length > 0 ? (
                dashboard?.sets?.map((item, index) => (
                  index <= 4 ? <ListView ImageElement={true}
                    img={item?.medias?.[0]?.thumbnail}
                    Title={item?.sets_name} item={item} path={`/update-sets/${item?.id}`} /> : null
                ))

              ) : (
                <div className="upload-div">
                  <p className="text-center text-[#888888] text-[14px] font-sans">
                    Create image & video sets to track your earnings.
                  </p>
                  <Link to="/sets">
                    <Button buttonStyles="mt-3">Create a Set</Button>
                  </Link>
                </div>

              )}
            </div>
          }



        </div>
        <div className="col-12 col-lg upload-box mt-2">
          <p className="published-text text-[#2F1409] font-sans">
            Top 5 Performers
          </p>
          {loading ?
            <div role="status" className=" animate-pulse">
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
              <div className="h-12 bg-gray-200 rounded mx-auto dark:bg-[#ebebeb] w-[96%] mb-4"></div>
            </div>
            :
            <div className="text-justify">

              {dashboard?.top5Performers?.length > 0 ? (
                dashboard?.top5Performers?.map((item, index) => (
                  index <= 4 ? <ListView ImageElement={true}
                    img={item?.media?.thumbnailSrc}
                    Title={item?.media?.title} price={formatAmount(item?.value)} item={item} /> : null
                ))

              ) : (
                <div className="upload-div">
                  <p className="text-justify latest-section text-[14px] text-[#888888]">
                    Looks like you don't have any sales yet.
                  </p>
                </div>

              )}
            </div>
          }

          {/* <div className="upload-div">
            <p className="text-justify latest-section text-[14px] text-[#888888]">
              Looks like you don't have any sales yet.
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
