import React from "react";
import Input from "../../components/input";
import Button from "../../components/button";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import "./banner.css";
import { Link } from "react-router-dom";
import { hostConfig } from "../../config";

const Personal = (props) => {


  const { showPassword, setShowPassword, formik, numberValidate, passwordSpaceValidate, spaceValidate, loader } = props;



  return (
    <form onSubmit={formik.handleSubmit} className="scroll-company">
      {" "}
      <div className="row w-100 m-0">
        <p className="account-text">Account Information</p>
        <div className="col-6">
          <Input
            style={{
              border:
                formik.errors.fullName && formik.touched.fullName
                  ? "1px solid red"
                  : "1px solid rgba(200, 199, 199, 1)",
            }}
            placeholder={"First Name"}
            type="text"
            name="fullName"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              spaceValidate(e)
            }}
            value={formik.values.fullName}
          />
          {formik.touched.fullName &&
            formik.errors.fullName && (
              <div className="text-[12px] text-red-600">{formik.errors.fullName}</div>
            )}
        </div>
        <div className="col-6">
          <Input
            style={{
              border:
                formik.errors.userName && formik.touched.userName
                  ? "1px solid red"
                  : "1px solid rgba(200, 199, 199, 1)",
            }}
            placeholder={"Last Name"}
            onBlur={formik.handleBlur}
            type="text"
            name="userName"
            onChange={(e) => {
              spaceValidate(e)
            }}
            value={formik.values.userName}
          />
          {formik.touched.userName &&
            formik.errors.userName && (
              <div className="text-[12px] text-red-600">{formik.errors.userName}</div>
            )}
        </div>
        <div className="col-12 mt-3">
          <Input
            style={{
              border:
                formik.errors.phone && formik.touched.phone
                  ? "1px solid red"
                  : "1px solid rgba(200, 199, 199, 1)",
            }}
            placeholder={"Phone Number"}
            type="phone"
            onBlur={formik.handleBlur}
            name="phone"
            onChange={(e) => numberValidate(e)}
            value={formik.values.phone}
          />
          {formik.touched.phone &&
            formik.errors.phone && (
              <div className="text-[12px] text-red-600">{formik.errors.phone}</div>
            )}
        </div>
        <div className="col-12 mt-3 mb-3">
          <Input
            style={{
              border:
                formik.errors.email && formik.touched.email
                  ? "1px solid red"
                  : "1px solid rgba(200, 199, 199, 1)",
            }}
            placeholder={"Email Address"}
            type="email"
            onBlur={formik.handleBlur}
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.touched.email &&
            formik.errors.email && (
              <div className="text-[12px] text-red-600">{formik.errors.email}</div>
            )}
        </div>
        <div className="col-12 position-relative">
          <Input
            style={{
              border:
                formik.errors.password && formik.touched.password
                  ? "1px solid red"
                  : "1px solid rgba(200, 199, 199, 1)",
            }}
            placeholder={" Password"}
            type={showPassword ? "text" : "password"}
            name="password"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              passwordSpaceValidate(e)
            }}
            value={formik.values.password}
          />
          {formik.touched.password &&
            formik.errors.password && (
              <div className="text-[12px] text-red-600">{formik.errors.password}</div>
            )}
          {showPassword ? (
            <BsEye
              className="cursor-pointer eye-icon"
              size={20}
              onClick={() => setShowPassword(false)}
            />
          ) : (
            <BsEyeSlash
              className="cursor-pointer eyeSlash-icon"
              size={20}
              onClick={() => setShowPassword(true)}
            />
          )}
        </div>
        <div className="d-flex align-items-center mt-2">
          <Input
            type="checkbox"
            name="agreeTerms"
            style={{ width: "13px", marginTop: "6px" }}
            onChange={formik.handleChange}
            value={formik.values.agreeTerms}
            onBlur={formik.handleBlur}
          />
          &nbsp;
          <span className="terms-span">
            I agree to the
            <Link
              className="terms-text"
              to={"/signup/terms-and-conditions"}
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </Link>
            &nbsp;and&nbsp;
            <Link
              className="terms-text"
              to={"/signup/privacy-policy"}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </Link>
          </span>
        </div>
        <div className="mt-3">
          <Button
            style={{
              opacity:
                loader || formik.isSubmitting || !formik.isValid ? "0.5" : "1",
              cursor:
                loader || formik.isSubmitting || !formik.isValid
                  ? "not-allowed"
                  : "pointer",
            }}
            type="submit"
            disabled={loader || formik.isSubmitting || !formik.isValid}
          >
            {loader ? (
              <div
                className="spinner-border text-light"
                role="status"
                style={{ width: "18px", height: "18px" }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Next"
            )}
          </Button>
        </div>
      </div>
      <div className="d-flex text-center justify-content-center mt-3">
        <p className="already-account">Already Have An Account?</p>&nbsp;
        <Link to="/log-in">
          <p className="login-text">Log In</p>
        </Link>
      </div>
    </form>
  );
};

export default Personal;
