import React from 'react'
import "./about-us.css"
const AboutUs = () => {
    return (
        <div>
            <div className="about-container">
                <div className="fs-22 fw-700 ft_mg_btm ft_ul_cmn">
                    About Us
                </div>
                <div className='about-contentContainer-CommonSty'>
                    <span className="fw-400">
                        World Archives is a platform which provides digital
                        media assets as well as high quality premium stock
                        content including images, videos, music, graphics, illustrations
                        and many more licensed under the World Archives license.
                        All content is perfectly tagged, searchable, and easily discoverable.
                    </span>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
