import React from "react";
import "./card.css";
import Skeleton from "react-loading-skeleton";

const Card = (props) => {
  const { img, text, rate, loading, rupeeSymbol } = props;
  return (
    <div>
      <div className="card-ui">
        <img src={img} alt="card" />
        <p className="card-para mt-3">{
          text
        }</p>
        <p className="card-rate">{rupeeSymbol ? <span className="me-2">{rupeeSymbol}</span> : ""}{
          loading ? <Skeleton width={50} /> : rate
        }</p>
      </div>
    </div>
  );
};

export default Card;
