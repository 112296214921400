// ************** React Lib *************************//
import React, { useEffect, useState } from "react";

// ************** Redux ***************************//
import { useSelector, useDispatch } from "react-redux";
import { fetchInsightsData, fetchRecentlyData } from "../../redux/slices/insights.slice";
import { fetchTopSales } from "../../redux/slices/get-top-sales-slice";

// ************** Assets *****************************//
import Image from "../../assets/image.svg";
import Sample from "../../assets/sample.png";
import Draw from "../../assets/draw.svg";
import Video from "../../assets/video.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// ************** Components ***************************//
import ListView from "../../components/list-view";
import Card from "../../components/card";
import { formatAmount, formatDownloads } from "../../utils/functions";

// ************** Styles *****************************//
import "./insight.css";

const Index = () => {

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const insights = useSelector((state) => state.insights?.storeResponseData);
  const recentlyAdded = useSelector((state) => state.insights?.storeRecentlyAddedData);
  const topSales = useSelector((state) => state.topSales?.storeResponseData);

  useEffect(() => {
    dispatch(fetchInsightsData()).then(() => setLoading(false));
    dispatch(fetchRecentlyData());
    dispatch(fetchTopSales());
  }, [dispatch]);




  return (
    <div className="container-fluid">
      {" "}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 ps-0 ps-lg-5 pe-0 pe-lg-5 pb-3 pt-3 w-100 m-0">
        <div className="mt-3">
          <Card loading={loading} img={Image} text={"Total Images"} rate={insights?.image ? formatAmount(insights?.image) : 0} />
        </div>
        <div className="mt-3">
          <Card loading={loading} img={Draw} text={"Total Illustration"} rate={insights?.illustration ? formatAmount(insights?.illustration) : 0} />
        </div>
        <div className="mt-3">
          <Card loading={loading} img={Video} text={"Total Videos"} rate={insights?.video ? formatAmount(insights?.video) : 0} />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 ps-0 ps-lg-5 pe-0 pe-lg-5 pb-3 pt-3 w-100 mt-3 responsive-insight">
        <div className="list-design me-2 mt-2">
          <div className="d-flex justify-content-between mb-3">
            <p className="mb-0">Top Sales</p>
          </div>
          {loading ?
            <Skeleton className="mb-3" height={50} width={"100%"} count={2} /> :
            topSales && topSales?.length > 0 ? topSales?.map((item) => (
              <ListView price={item?.value} img={item?.media?.thumbnailSrc} ImageElement Title={item?.media?.title} />
            )) : null
          }


        </div>
        <div className="list-design me-2 mt-2">
          <div className="d-flex justify-content-between mb-3">
            <p className="mb-0">Recently Added</p>
          </div>
          {loading ?
            <Skeleton className="mb-3" height={50} width={"100%"} count={2} /> :
            recentlyAdded?.length > 0 ? recentlyAdded?.map((item) => {
              return <ListView path={`/portfolio/published/images/${item?.id}`} img={item?.thumbnailSrc} ImageElement Title={item?.title} />;
            }) : (
              <p className="mb-0  text-center flex justify-center items-center">Look like there is no assets added yet</p>
            )

          }

        </div>
      </div>
    </div>
  );
};

export default Index;
