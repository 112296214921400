import React from 'react'
import { PiDotOutlineFill } from "react-icons/pi";
import "./privacy-policy.css"
const PrivacyPolicy = () => {
    return (
        <div>
            <div className="priPol-container">
                <div className='fs-22 fw-700 ft_mg_btm ft_ul_cmn'>
                    Privacy Policy
                </div>
                <div className='priPol-contentCommonSty  ft_mg_btm'>
                    <span>Version_____</span>
                    <span className='mg-lft-two'>We take your security and privacy very seriously and we aim to make your experience with us entirely safe and problem free. Please see our security guarantee and privacy policy below.</span>
                    <span className='mg-lft-two'>This site is owned by Inspire Films ("World Archives", "we" or "us") whose registered office is at ______and, and we are the 'data controller' of the personal information you provide to us. World Archives acts on behalf of all World Archives group companies, i.e. all parent and subsidiary undertakings.</span>
                    <span className='mg-lft-two'>We are committed to protecting and respecting your privacy when visiting our website and providing us with your personal information and this privacy policy statement summarises what personal details we may collect from you before, during or after you use our site and what we will do with them. You can contact us via the postal address above or via email ________ if you have any queries regarding this policy.</span>
                    <span className='mg-lft-two'>We will review and update this Privacy Policy from time to time and all changes will be posted to this page.</span>
                </div>
                <div className='priPol_content_infoCmnSty gapProvTwo'>
                    <div className='priPol_index_info'>
                        <span className='mg-rgt fw-600'>1.</span>
                        <div className='priPol_infoContainer'>
                            <div className=' fw-600 ft_ul_cmn'>Our security guarantee</div>
                            <div className='priPol-contentCommonSty '>
                                <span>Every time you buy from our site or register using your credit card number we ensure that your on-line credit and debit card transactions are secure by using industry-standard SSL (secure socket layer) encryption technology. In addition, to ensure there is absolutely minimal risk to you, we offer a guarantee for every purchase you make using a credit or debit card on our secure server.</span>
                                <span>Further information may be requested from you by payment service providers in accordance with applicable law and regulations. We will not record any of the information so provided.</span>
                                <span>The guarantee we make to you is as follows. In the event of unauthorised use of your credit or debit card, most banks and card issuers either cover all of your potential charges or in some cases may limit your liability to just $50.00. At World Archives, if your bank or card issuer does hold you liable we will cover this liability up to $50.00, provided that the unauthorised use of your credit or debit card resulted through no fault of your own and was for World Archives purchases using our secure server. Please bear in mind that in the event of unauthorised use of your credit or debit card, you must still notify your card provider in accordance with its reporting rules and procedures.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='priPol_content_infoCmnSty gapProvTwo mg_btm_two'>
                    <div className='priPol_index_info'>
                        <span className='mg-rgt fw-600'>2.</span>
                        <div className='priPol_infoContainer'>
                            <div className=' fw-600 ft_ul_cmn'>Our privacy policy</div>
                            <div className='priPol-contentCommonSty '>
                                <span>We are committed to protecting your personal privacy and, in accordance with relevant data protection laws, we uphold strict security procedures for the collection, storage, use and disclosure of your personal information. We have described below the personal information we may gather about you, the purposes we will hold it for and the limited categories of people to whom we may disclose it.</span>
                                <span>What information do we collect and how may we use it?</span>
                                <div className='priPol_index_info'>
                                    <div className='mg-rgt dotCmn-mrg mg-lft'>
                                        <PiDotOutlineFill fontSize="0.7 rem" />
                                    </div>
                                    <div className='priPol-contentCommonSty'>
                                        <span className='fw-600'>All visitors to the site:</span>
                                        <span>During your visit to our site, we will only collect personal information that you choose to provide, if for example you: contact us with an enquiry; register on the site; enter a competition or prize draw; request us to provide you with further information; post information to a public area of the site or take advantage of a promotion.</span>
                                        <span>If you share other people's data with us, for example if you buy one of our products for another family member, you will need to check that person consents to you providing that information to us.</span>
                                        <span>We may use the information held about you in the following ways:</span>
                                        <div className='priPol_content_infoCmnSty gapProvTwo'>
                                            <div className='priPol_index_info'>
                                                <span className='mg-rgt dotCmn-mrg mg-lft'>
                                                    <PiDotOutlineFill fontSize="0.7 rem" />
                                                </span>
                                                <span className='priPol-contentCommonSty'>
                                                    To administer any account you have with us, including any terminated account to which post-termination provisions apply
                                                </span>
                                            </div>
                                            <div className='priPol_index_info'>
                                                <span className='mg-rgt dotCmn-mrg mg-lft'>
                                                    <PiDotOutlineFill fontSize="0.7 rem" />
                                                </span>
                                                <span className='priPol-contentCommonSty'>
                                                    To perform our contractual obligations to you
                                                </span>
                                            </div>
                                            <div className='priPol_index_info'>
                                                <span className='mg-rgt dotCmn-mrg mg-lft'>
                                                    <PiDotOutlineFill fontSize="0.7 rem" />
                                                </span>
                                                <span className='priPol-contentCommonSty'>
                                                    To respond to your queries and requests
                                                </span>
                                            </div>
                                            <div className='priPol_index_info'>
                                                <span className='mg-rgt dotCmn-mrg mg-lft'>
                                                    <PiDotOutlineFill fontSize="0.7 rem" />
                                                </span>
                                                <span className='priPol-contentCommonSty'>
                                                    To communicate with you
                                                </span>
                                            </div>
                                            <div className='priPol_index_info'>
                                                <span className='mg-rgt dotCmn-mrg mg-lft'>
                                                    <PiDotOutlineFill fontSize="0.7 rem" />
                                                </span>
                                                <span className='priPol-contentCommonSty'>
                                                    To notify you of any changes to our services
                                                </span>
                                            </div>
                                            <div className='priPol_index_info'>
                                                <span className='mg-rgt dotCmn-mrg mg-lft'>
                                                    <PiDotOutlineFill fontSize="0.7 rem" />
                                                </span>
                                                <span className='priPol-contentCommonSty'>
                                                    To ensure that the content of our site is presented in the most effective manner
                                                </span>
                                            </div>
                                            <div className='priPol_index_info'>
                                                <span className='mg-rgt dotCmn-mrg mg-lft'>
                                                    <PiDotOutlineFill fontSize="0.7 rem" />
                                                </span>
                                                <span className='priPol-contentCommonSty'>
                                                    To provide you with the information, products and services requested from us
                                                </span>
                                            </div>
                                            <div className='priPol_index_info'>
                                                <span className='mg-rgt dotCmn-mrg mg-lft'>
                                                    <PiDotOutlineFill fontSize="0.7 rem" />
                                                </span>
                                                <span className='priPol-contentCommonSty'>
                                                    To provide you with helpful information about our products or services
                                                </span>
                                            </div>
                                            <div className='priPol_index_info'>
                                                <span className='mg-rgt dotCmn-mrg mg-lft'>
                                                    <PiDotOutlineFill fontSize="0.7 rem" />
                                                </span>
                                                <span className='priPol-contentCommonSty'>
                                                    To allow you to participate in interactive features of our service, should you choose to do so
                                                </span>
                                            </div>
                                            <div className='priPol_index_info'>
                                                <span className='mg-rgt dotCmn-mrg mg-lft'>
                                                    <PiDotOutlineFill fontSize="0.7 rem" />
                                                </span>
                                                <span className='priPol-contentCommonSty'>
                                                    To administer competition entries and to notify winners
                                                </span>
                                            </div>
                                            <div className='priPol_index_info'>
                                                <span className='mg-rgt dotCmn-mrg mg-lft'>
                                                    <PiDotOutlineFill fontSize="0.7 rem" />
                                                </span>
                                                <span className='priPol-contentCommonSty'>
                                                    World Archives market research
                                                </span>
                                            </div>
                                            <div className='priPol_index_info'>
                                                <span className='mg-rgt dotCmn-mrg mg-lft'>
                                                    <PiDotOutlineFill fontSize="0.7 rem" />
                                                </span>
                                                <span className='priPol-contentCommonSty'>
                                                    To make improvements to the service we provide you
                                                </span>
                                            </div>
                                        </div>
                                        <span>
                                            We also reserve the right to disclose your personal information where we are required to do so by law, and to assist in any disputes, claims or investigations relating to your account or contract with us, and to detect and prevent fraudulent transactions.
                                        </span>
                                        <span>
                                            We may also use information about your visits to our site to enhance your visit and to create a general demographic profile of visitors.
                                        </span>
                                        <span>
                                            E-mail and Live Chat correspondence with World Archives via our website and email addresses accessible through, or obtained from, this site may be recorded and/or monitored. By using these communication methods, you are consenting to the recording and/or monitoring of your emails and chats.
                                        </span>
                                        <span>
                                            Telephone calls with World Archives at the numbers accessible through, or obtained from, this site may be monitored for training purposes. By using this communication method, you are consenting to the monitoring of your calls.
                                        </span>
                                    </div>
                                </div>
                                <div className='priPol_index_info'>
                                    <div className='mg-rgt dotCmn-mrg mg-lft'>
                                        <PiDotOutlineFill fontSize="0.7 rem" />
                                    </div>
                                    <div className='priPol-contentCommonSty'>
                                        <span className='fw-600'>
                                            Customers and registered users:
                                        </span>
                                        <span>
                                            When you register on the site we will ask for your name and address, your company name and address, your email address and a password. This enables us to send you information which you have indicated you wish to receive from us, as well as related communications which we think you will find of interest, including our regular email newsletters and updates on any special offers or events coming up on the site.
                                        </span>
                                        <span>
                                            When you place orders with us we need to know your name, email address, invoice address, delivery address and telephone number. If the order is placed using a credit or debit card we also need the number and expiry date of the card, if paying on account we also need the email and contact name of the person responsible for paying invoices in your accounts payable department. This allows us to process and fulfil your orders and to notify you of your order status. We may provide some or all of this information to our partners or advisors for the purposes of completing or investigating a transaction with you or answering a question you raise with us. We sometimes provide pseudonymised data to 3rd parties to fulfil contractual obligations.
                                        </span>
                                        <span>
                                            We use information on your previous habits and purchases to personalise your shopping experience, and to show you products and promotions that we think will appeal to you. This information is collected by a 'cookie' which is stored on your computer's hard drive and enables us to automatically link our customers to their personalised accounts. If you want to, most web-browsers allow you to turn off the automatic acceptance of cookies on your PC. We also monitor the usage of our site and customer traffic patterns to help us improve the layout and functionality of the site.
                                        </span>
                                    </div>
                                </div>
                                <div className='priPol_index_info'>
                                    <div className='mg-rgt dotCmn-mrg mg-lft'>
                                        <PiDotOutlineFill fontSize="0.7 rem" />
                                    </div>
                                    <div className='priPol-contentCommonSty'>
                                        <span className='fw-600'>
                                            Contributors:
                                        </span>
                                        <span>
                                            If you register with us as a contributor, we need you to provide your name and address, (your company name if you register as an agency) your telephone number, your email address and a password. This enables us to administer your account and send you information which you have indicated you wish to receive from us, as well as important account specific information such as contractual notifications, operational updates and service emails.
                                        </span>
                                        <span>
                                            Additionally, to get paid for any sales made through World Archives we need to know your bank details; name of your bank, country, account holder's name and account number with sort code.
                                        </span>
                                    </div>
                                </div>
                                <div className='priPol_index_info'>
                                    <div className='mg-rgt dotCmn-mrg mg-lft'>
                                        <PiDotOutlineFill fontSize="0.7 rem" />
                                    </div>
                                    <div className='priPol-contentCommonSty'>
                                        <span className='fw-600'>
                                            Contributors covering News events:
                                        </span>
                                        <span>
                                            If you agree to cover a News event World Archives will share your required personal details with the event organizer so that you can gain access to the event. If the event organizer requires more details than what we currently hold we will request these details from you. We will delete any extra details we acquire in regards to your access to a News event within 2 years.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className=''>In all cases cited above if you do not provide the data required we will not be able to provide you with our services, or the administration of those services may be hampered.</div>
                            <div className='priPol-contentCommonSty  mg_btm_two'>
                                <div className='priPol_content_infoCmnSty gapProvOne'>
                                    <span className='fw-600 mrg-tp'>What if I don't want to receive any correspondence from you?</span>
                                    <span>
                                        Where you have given your permission for us to use your data for particular purposes, or where we send you communications relating to World Archives products and services, you can at any time change your preferences as to how your data is used by us. Simply sign in to ________  and change your email preferences.
                                    </span>
                                </div>
                                <div className='priPol_content_infoCmnSty gapProvOne'>
                                    <span className='fw-600 mrg-tp'>
                                        Lightboxes
                                    </span>
                                    <span>
                                        As lightboxes can be emailed out and viewed by clicking on the URL link, the information contained in them could be viewed, but not altered by someone other than yourself or an intended recipient.
                                    </span>
                                </div>
                                <div className='priPol_content_infoCmnSty gapProvOne'>
                                    <span className='fw-600 mrg-tp'>
                                        Social Media
                                    </span>
                                    <span>
                                        You may contact us or comment on our products and services through social media. The information we collect from social media and online sites sometimes includes personal information that has been put online and is publicly available. Please refer to the relevant social media sites for details of their own privacy policies on how they collect and share your personal data.
                                    </span>
                                </div>
                                <div className='priPol_content_infoCmnSty gapProvOne'>
                                    <span className='fw-600 mrg-tp'>
                                        How do we store and protect your data?
                                    </span>
                                    <span>
                                        We take the security of your information seriously and we will endeavour to ensure that the personal information you submit to us is kept private and secure, and held in accordance with relevant laws. We use appropriate technical and organisational measures to protect your personal information. We protect your personal information using security measures such as encryption, access controls and physical security measures.
                                    </span>
                                    <span>
                                        We will not sell, rent or otherwise disclose the personal information you provide to us through the site to third parties (other than as listed below) unless we are required to do so by law.
                                    </span>
                                </div>
                                <div className='priPol_content_infoCmnSty gapProvOne mrg-tp'>
                                    <span className='fw-600 mrg-tp'>
                                        Third parties who provide services on our behalf
                                    </span>
                                    <span>
                                        We share your personal information with certain third parties who provide services on our behalf, or, in limited cases, who receive services from us. They only have access to the personal information they need to perform those services. They are required to keep your personal information secure and confidential and may only use it as permitted by us in accordance with this Privacy Policy.
                                    </span>
                                    <span>
                                        The third parties who provide services on our behalf fall into the following categories:
                                    </span>
                                    <div className='priPol_index_info'>
                                        <span className='mg-rgt dotCmn-mrg mg-lft'>
                                            <PiDotOutlineFill fontSize="0.7 rem" />
                                        </span>
                                        <span className='priPol-contentCommonSty'>
                                            Other World Archives/ IFPL  group companies
                                        </span>
                                    </div>
                                    <div className='priPol_index_info'>
                                        <span className='mg-rgt dotCmn-mrg mg-lft'>
                                            <PiDotOutlineFill fontSize="0.7 rem" />
                                        </span>
                                        <span className='priPol-contentCommonSty'>
                                            Payment service providers including for the purposes of transaction administration, identification and authentication, fraud detection and prevention services and anti fraud screening services
                                        </span>
                                    </div>
                                    <div className='priPol_index_info'>
                                        <span className='mg-rgt dotCmn-mrg mg-lft'>
                                            <PiDotOutlineFill fontSize="0.7 rem" />
                                        </span>
                                        <span className='priPol-contentCommonSty'>
                                            Our partners or advisors for the purposes of completing or investigating a transaction with you or answering a question you raise with us
                                        </span>
                                    </div>
                                    <div className='priPol_index_info'>
                                        <span className='mg-rgt dotCmn-mrg mg-lft'>
                                            <PiDotOutlineFill fontSize="0.7 rem" />
                                        </span>
                                        <span className='priPol-contentCommonSty'>
                                            Translators we engage to translate into different languages image captions and tags (keywords) and other information associated with an image
                                        </span>
                                    </div>
                                    <div className='priPol_index_info'>
                                        <span className='mg-rgt dotCmn-mrg mg-lft'>
                                            <PiDotOutlineFill fontSize="0.7 rem" />
                                        </span>
                                        <span className='priPol-contentCommonSty'>
                                            Marketing agencies appointed to provide services to us
                                        </span>
                                    </div>
                                    <div className='priPol_index_info'>
                                        <span className='mg-rgt dotCmn-mrg mg-lft'>
                                            <PiDotOutlineFill fontSize="0.7 rem" />
                                        </span>
                                        <span className='priPol-contentCommonSty'>
                                            The service providers operating this site on our behalf
                                        </span>
                                    </div>
                                    <div className='priPol_index_info'>
                                        <span className='mg-rgt dotCmn-mrg mg-lft'>
                                            <PiDotOutlineFill fontSize="0.7 rem" />
                                        </span>
                                        <span className='priPol-contentCommonSty'>
                                            Social media platforms
                                        </span>
                                    </div>
                                    <div className='priPol_index_info'>
                                        <span className='mg-rgt dotCmn-mrg mg-lft'>
                                            <PiDotOutlineFill fontSize="0.7 rem" />
                                        </span>
                                        <span className='priPol-contentCommonSty'>
                                            Accountants, auditors, law firms, information technology support providers
                                        </span>
                                    </div>
                                    <div className='priPol_index_info'>
                                        <span className='mg-rgt dotCmn-mrg mg-lft'>
                                            <PiDotOutlineFill fontSize="0.7 rem" />
                                        </span>
                                        <span className='priPol-contentCommonSty'>
                                            Advertising services, analytics services, content providers
                                        </span>
                                    </div>
                                    <span>
                                        The information we collect about you, is hosted and stored in the ___ and also outside of the ___. We ensure our staff based in India are bound by the same conditions and standards and operate within the same levels of security. Those third parties with which we share your personal information may handle or transfer your personal information outside of the ____. We make sure that any transfer of your personal information by us to any third party complies with all applicable data protection laws.
                                    </span>
                                    <span>
                                        By submitting your personal information to us, and by using our site, you agree to us transferring, storing and handling your personal information in this way.
                                    </span>
                                    <div className='priPol_content_infoCmnSty mg_btm_one gapProvOne'>
                                        <span className='fw-600 mrg-tp'>
                                            How long do we store your data?
                                        </span>
                                        <span className='mg_btm_one'>
                                            We will not keep your data for longer than is necessary for the relevant purposes set out in this Privacy Policy. Where you have purchased a product or service from us, we will hold your relevant personal details to enable us to provide after-sale services and to administer the contract, including any relevant warranty. Where you are a Contributor (or an ex-contributor in respect of certain post-termination contractual obligations) as this is a contractual arrangement, we need to keep this data to administer the contract and correspond with you on any contractual matters arising.
                                        </span>
                                    </div>
                                    <div className='priPol_content_infoCmnSty gapProvOne mg_btm_two'>
                                        <span className='fw-600 mrg-tp'>
                                            Your Rights
                                        </span>
                                        <span>
                                            Under the General Data Protection Regulation (GDPR) and The Data Protection Act 2018 (DPA) you have a number of rights with regard to your personal data.
                                        </span>
                                        <span>
                                            You have the right to request from us access to and rectification or erasure of your personal data, the right to restrict processing, object to processing as well as in certain circumstances the right to data portability. Please note we will retain and use your personal information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
                                        </span>
                                    </div>
                                    <div className='priPol_content_infoCmnSty gapProvOne'>
                                        <span className='fw-600 mrg-tp'>
                                            Public areas
                                        </span>
                                        <span>
                                            Any information you post on the public areas of the site (e.g. our forum) is accessible to and used by others and may result in unsolicited messages or other contact from others. You are advised to use caution when posting personal information about yourself in public areas of this site.
                                        </span>
                                    </div>
                                    <div className='priPol_content_infoCmnSty gapProvOne'>
                                        <span className='fw-600 mrg-tp'>
                                            No data collected from children
                                        </span>
                                        <span>
                                            Our site is not directed at children and should not be accessed by them. We will not knowingly collect information from minors without their parent's or guardian's consent.
                                        </span>
                                    </div>
                                    <div className='priPol_content_infoCmnSty gapProvOne'>
                                        <span className='fw-600 mrg-tp'>
                                            Third Party Websites
                                        </span>
                                        <span>
                                            From time to time our site may contain links to and from the websites of our partner networks, affiliates and other third party sites. If you visit any of these sites please note that they have their own privacy policies and you should check these before submitting any personal data. We cannot accept any responsibility or liability for these policies.
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='priPol_content_infoCmnSty gapProvTwo mg_btm_two'>
                    <div className=' fw-600'>Cookie Policy</div>
                    <span>We explain what cookies are, how World Archives use them and how you can manage or remove them.</span>
                    <div className='priPol_content_infoCmnSty gapProvTwo '>
                        <div className='priPol_index_info'>
                            <span className='mg-rgt fw-600'>1.</span>
                            <div className='priPol_infoContainer'>
                                <div className='fw-600'>What are cookies?</div>
                                <div className='priPol-contentCommonSty '>
                                    <span>
                                        A cookie is a small file stored on your device that's created each time you visit a website. Multiple cookies can be stored from one website and are used to make your online experience easier.
                                    </span>
                                    <span>
                                        A cookie file is generated by the site you're browsing and is accepted and processed by your computer's browser software. The cookie file is stored in your browser's local folder or subfolder.
                                    </span>
                                    <span>
                                        Cookies save your browsing information which means sites can keep you signed in, remember your site preferences, and give you locally relevant content.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='priPol_index_info'>
                            <span className='mg-rgt fw-600'>2.</span>
                            <div className='priPol_infoContainer'>
                                <div className='fw-600'>What sort of cookies does the World Archives website use?</div>
                                <div className='priPol-contentCommonSty '>
                                    <span>
                                        Cookies help us improve your experience by providing us with information about how the website is being used. Our cookies do not store financial information or other personal information such as your name or email address.
                                    </span>
                                    <span>
                                        In addition to our own cookies, we use third party tools which also use cookies.
                                    </span>
                                    <span>
                                        There is a notice on our website which provides a link to our cookie policy. If you continue to use the World Archives website, we will assume your consent to the use of cookies for this purpose.
                                    </span>
                                    <span>
                                        If, after reading this information you have any questions or concerns about our cookie policy please contact us at ______ or read our privacy policy
                                    </span>
                                    <span>
                                        We are using the different types of cookies listed below:
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='priPol_content_table_container mg_btm_two'>
                    <div className='priPol_table_row'>
                        <div className='priPol_table_columnOne  '>
                            <span className='mg-tpBtm'>Cookie Type</span>
                        </div>
                        <div className='priPol_table_columnTwo'>
                            <span className='mg-tpBtm'>How we use them</span>
                        </div>
                    </div>
                    <div className='priPol_table_row borderProv'>
                        <div className='priPol_table_columnOne '>
                            <span className='mg-tpBtm'>Strictly Necessary</span>
                        </div>
                        <div className='priPol_table_columnTwo'>
                            <span className='mg-tpBtm'>These cookies are essential for the operation of our websites.</span>
                            <span className='mg-tpBtm'>For example, they keep track of what you have in your basket and identify you as being signed in to the website and allows us to recognise you as a customer or contributor.</span>
                            <span className='mg-tpBtm'>If you block these cookies we cannot guarantee the website will perform correctly.</span>
                        </div>
                    </div>
                    <div className='priPol_table_row borderProv'>
                        <div className='priPol_table_columnOne '>
                            <span className='mg-tpBtm'>Performance</span>
                        </div>
                        <div className='priPol_table_columnTwo'>
                            <span className='mg-tpBtm'>We use these cookies to analyse how our visitors use our websites and to monitor website performance.</span>
                            <span className='mg-tpBtm'>For example, we might use them to keep track of which pages are most popular, test new website's design and operability and to see why some pages are receiving error messages.</span>
                        </div>
                    </div>
                    <div className='priPol_table_row borderProv'>
                        <div className='priPol_table_columnOne '>
                            <span className='mg-tpBtm'>Functionality</span>
                        </div>
                        <div className='priPol_table_columnTwo'>
                            <span className='mg-tpBtm'>We use functionality cookies to remember choices you make such as which regional edition of the website you want to see when you sign in.</span>
                            <span className='mg-tpBtm'>These cookies recall any customisation you make to pages on the website during your visit.</span>
                            <span className='mg-tpBtm'>If you block these cookies certain features and services may be affected and we cannot be sure how the Website will perform during your visit.</span>
                        </div>
                    </div>
                    <div className='priPol_table_row borderProv'>
                        <div className='priPol_table_columnOne '>
                            <span className='mg-tpBtm'>Marketing</span>
                        </div>
                        <div className='priPol_table_columnTwo'>
                            <span className='mg-tpBtm'>These cookies are used to track our visitors browsing habits and activity across our websites. They can be used to build up a profile of search and/or browsing history for every visitor. Identifiable or unique data may be collected. Anonymized data may be shared with third parties.</span>
                            <span className='mg-tpBtm'>This information is linked to site functionality provided by other websites, and may be shared with other organisations (such as advertising networks).</span>
                        </div>
                    </div>
                    <div className='priPol_table_row borderProv'>
                        <div className='priPol_table_columnOne '>
                            <span className='mg-tpBtm'>Third Party</span>
                        </div>
                        <div className='priPol_table_columnTwo'>
                            <span className='mg-tpBtm'>We also use or allow third parties to serve cookies that fall into the four categories above. For example, like many companies, we use Google Analytics to help us monitor our website traffic. We may also use third party cookies to help us with market research, revenue tracking, improving site functionality and monitoring compliance with our terms and conditions.</span>
                        </div>
                    </div>
                </div>
                <div className='priPol_index_info  mg_btm_two'>
                    <span className='mg-rgt fw-600'>13.</span>
                    <div className='priPol_infoContainer'>
                        <div className='fw-600'>How do I control my cookies ?</div>
                        <div className='priPol-contentCommonSty '>
                            <span>
                                You can manage the cookies we store from you on our website through your internet browser settings at any time. For more information about how to do this, and about cookies in general, you can visit________
                            </span>
                            <span>
                                Please be aware that if you turn off all cookies, your experience on our website will be impacted, some of the functionality will be reduced and your overall experience on World Archives will be poorer.
                            </span>
                            <span>
                                In this policy you will find all the information you need about our cookies usage. If you feel uncomfortable with these cookies, please ask us for more info or use the above guidelines to turn them off.
                            </span>
                        </div>
                    </div>
                </div>
                <div className='priPol_content_commonSty  gapProvThree'>
                    <span className=''>
                        Cancellation policy - 14 day money back guarantee
                    </span>
                    <span>
                        If you have not downloaded an item of Content, you may cancel the Licence and get a full refund within fourteen (14) days of the Invoice date. You will need to send an email to _________ with the Invoice number (e.g. IY11110000) and the Content file number (e.g.AT4WHG). If you cancel, you will not be able to use the Content.
                    </span>
                    <span>
                        Once you download a purchased item of Content, you agree that you have no right to a refund, unless expressly stated otherwise in the Agreement
                    </span>
                    <span className='mg_btm_two'>
                        Email disclaimers
                    </span>
                    <span>
                        Email disclaimer for ________
                    </span>
                    <span>
                        The information contained in or attached to emails is intended only for the use of the individual or entity to which it is addressed. If you are not the intended recipient, you are not authorised to and must not disclose, copy, distribute, or retain any message or any part of it. If you have received an email in error, please contact the sender and delete the material from any computer.
                    </span>
                    <span>
                        We accept no liability for any loss or damage which may be caused by software viruses or interception/interruption of emails. The information contained or attached to an email may contain information which is confidential and/or covered by legal, professional or other privilege or other rules or laws with similar effect in other jurisdictions.
                    </span>
                    <span>
                        Inspire Films
                    </span>
                    <span>
                        Registered office:_________.
                    </span>
                    <span className='mg_btm_two'>
                        Public domain content
                    </span>
                    <span>
                        Our website might have some collections that include images that are in the public domain, not protected by copyright or where the copyright ownership is unknown. Content can enter the public domain when copyright has expired, has been forfeited or is not applicable. For these images we don't claim any copyright or other intellectual property rights and neither do our contributors, we just provide you with access to a copy to use in line with the terms of the license you buy. Other copies of these images might be available elsewhere for free.
                    </span>
                    <span>
                        What is Copyright? Copyright is a law that gives you ownership over the things you create. As copyright owner you have the exclusive rights to sell, display and distribute the work however you want.
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
