import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postBulkImage } from "../../api/create";


const initialState = {
    loading: false,
    storeResponseData: [],
    error: null
}

export const fetchBulkUploadImage = createAsyncThunk(
    "bulkImageUpload/fetchBulkUploadImage",
    async (params, { dispatch }) => {
        dispatch(addBulkUploadImageRequest());
        const response = await postBulkImage(params);
        if (response?.success === true) {
            dispatch(addBulkUploadImageData(response?.data));
            return response;
        } else {
            dispatch(addBulkImageUploadError(response?.error));
            return response;
        }
    }
)

const uploadImageSlice = createSlice({
    name: "imageUpload",
    initialState: initialState,
    reducers: {
        addBulkUploadImageRequest: (state) => {
            state.loading = true
        },
        addBulkUploadImageData: (state, action) => {
            state.storeResponseData = action.payload;
            state.loading = false;
        },
        addBulkImageUploadError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default uploadImageSlice.reducer;

const { addBulkUploadImageRequest, addBulkUploadImageData, addBulkImageUploadError } = uploadImageSlice.actions;
