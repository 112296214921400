import React from "react";
import MainLogo from "../../assets/main-logo.svg";
import { FaInstagram, FaTwitter, FaTumblrSquare } from "react-icons/fa";
import { BsPinterest } from "react-icons/bs";
import { FiFacebook } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import "./footer.css";
import footerLogo from "../../assets/footer.png";


const Footer = () => {
  const navigate = useNavigate();

  //   useEffect(() => {
  //     const base_url = process.env.REACT_APP_BASE_URL;
  //     const setting_URL = `${base_url}/setting`;
  //     const getSettings = async () => {
  //       const { data } = await axios.get(setting_URL, {});
  //       const settings = data?.data?.settings;
  //       const contentValue = settings.find(
  //         (setting) => setting.settingKey === "TOTAL_NO_OF_CONTENT"
  //       ).settingValue;
  //       setContentCount(contentValue);
  //     };
  //     getSettings();
  //   }, [contentCount]);

  const onClickAboutUs = () => {
    navigate("/about");
  };

  const onClickMission = () => {
    navigate("/mission");
  };

  const onClickSupport = () => {
    navigate("/support");
  };

  const onClickTermsAndConditions = () => {
    navigate("/terms-conditions");
  };

  const onClickLicenceAgreement = () => {
    navigate("/license-agreement");
  };

  const onClickPrivacyPolicy = () => {
    navigate("/privacy-policy");
  };

  const onClickDataDeletionPolicy = () => {
    navigate("/data-deletion-policy");
  };

  let currentYear = new Date().getFullYear();


  return (

    <footer >

      <div className="ftr ">
        <div className="ftr-logo ">
          <img src={footerLogo} alt="logo" />
        </div>
        <div className="ftr-information  ">
          <div className="ourCompany ">
            <span className="ftr-heading">Contributor</span>
            <span onClick={onClickAboutUs} className="ftr-subHeading">
              About us
            </span>
            <span onClick={onClickMission} className="ftr-subHeading">
              Mission
            </span>
            <span className="ftr-subHeading">Press/Media</span>
            <span className="ftr-subHeading">Coupons</span>
          </div>
          <div className="partner">
            <span className="ftr-heading">Help</span>
            <span className="ftr-subHeading">Developers</span>
            <span className="ftr-subHeading">Affiliate/Reseller</span>
            <span className="ftr-subHeading">International reseller</span>
          </div>
          <div className="legal">
            <span className="ftr-heading">Legal</span>
            <span
              onClick={onClickDataDeletionPolicy}
              className="ftr-subHeading"
            >
              Data Deletion Policy
            </span>
            <span
              onClick={onClickTermsAndConditions}
              className="ftr-subHeading"
            >
              Terms And Conditions
            </span>
            <span onClick={onClickLicenceAgreement} className="ftr-subHeading">
              License Agreement
            </span>
            <span onClick={onClickPrivacyPolicy} className="ftr-subHeading">
              Privacy Policy
            </span>

          </div>
          <div className="services">
            <span className="ftr-heading">Services</span>
            <span className="ftr-subHeading">Live assignments</span>
            <span className="ftr-subHeading">Rights and clearance</span>
          </div>
          <div className="contactUs">
            <span className="ftr-heading">Help</span>
            <span className="ftr-subHeading" onClick={onClickSupport}>
              Support
            </span>
            <a
              href="https://worldarchives.freshdesk.com/support/home"
              className="ftr-subHeading"
            >
              FAQ
            </a>
          </div>
        </div>
      </div>
      <div className="ftr-copyright flex  ">
        <div className="ftr-copyright-logoAndDetail">&copy; Copyright {currentYear ? currentYear : "2024"}</div>
        <div className="ftr-icon flex justify-between gap-3">
          <a
            href="https://www.instagram.com/world_archives_support/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <FaInstagram className="cursor-pointer icon" />
            </span>
          </a>
          <a
            href="https://twitter.com/World__Archives"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <FaTwitter className="cursor-pointer icon" />
            </span>
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100089366404607"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <FiFacebook className="cursor-pointer icon" />
            </span>
          </a>
          <a
            href="https://www.tumblr.com/login?redirect_to=%2Fsettings%2Faccount"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <FaTumblrSquare className="cursor-pointer icon" />
            </span>
          </a>
          <a
            href="https://www.pinterest.com/108rgcgvz74bf0sthu5tce6k040hw1/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span >
              <BsPinterest className=" cursor-pointer icon  " />
            </span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
