import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postResetPassword } from "../../api/create";


const initialState = {
    loading: false,
    storeResponseData: [],
    error: null
}

export const fetchResetPassword = createAsyncThunk(
    "reset/fetchResetPassword",
    async (params, { dispatch }) => {
        dispatch(addResetPasswordRequest());
        const response = await postResetPassword(params);
        if (response?.success === true) {
            dispatch(addResetPasswordData(response?.data));
            return response;
        } else {
            dispatch(addResetPasswordError(response?.error));
            return response;
        }
    }
)

const resetPasswordSlice = createSlice({
    name: "resetpassword",
    initialState: initialState,
    reducers: {
        addResetPasswordRequest: (state) => {
            state.loading = true
        },
        addResetPasswordData: (state, action) => {
            state.storeResponseData = action.payload;
            state.loading = false;
        },
        addResetPasswordError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default resetPasswordSlice.reducer;

const { addResetPasswordRequest, addResetPasswordData, addResetPasswordError } = resetPasswordSlice.actions;
