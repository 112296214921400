import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { fetchPublished } from "../../../redux/slices/published.slice";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/loader";


import NoRecordsFound from "../../../components/no-records-found";


const Index = () => {



    const dispatch = useDispatch();

    const dataPublished = useSelector((state) => state.published?.storePublishedData);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchPublished({
            search: { visibility: "PUBLISHED", category: "VIDEO" },
        })).then(() => setLoading(false))
    }, [dispatch])



    const navigate = useNavigate();

    return (
        <div className="ps-5 pe-5 pt-4 pb-4">
            <div>
                <p className="back-images ">
                    <IoMdArrowBack className="cursor-pointer" onClick={() => navigate("/portfolio/published")} />
                    &nbsp;Illustration
                </p>
            </div>

            <NoRecordsFound />


        </div>
    );
};

export default Index;

