import React, { useEffect, useState } from 'react';
import "./monthly.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchEarningsData } from '../../redux/slices/get-dashboard-earnings.slice';
import { BiMoneyWithdraw } from 'react-icons/bi';
import { formatAmount, formatDownloads } from '../../utils/functions';
import Skeleton from 'react-loading-skeleton';

const Monthly = (props) => {
    const dispatch = useDispatch();
    const earnings = useSelector((state) => state.dashboardEarnings?.storeAllEarningsData);
    const { loading } = props
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    const [years, setYears] = useState([]);
    const [active, setActive] = useState(months[currentMonth - 1]);
    const [params, setParams] = useState({
        month: currentMonth.toString(),
        years: currentYear.toString(),
        lifeTime: "false"
    });

    useEffect(() => {
        const yearsArray = [];
        for (let year = 2020; year <= currentYear; year++) {
            yearsArray.push(year);
        }
        setYears(yearsArray);
    }, [currentYear]);

    useEffect(() => {
        dispatch(fetchEarningsData(params));
    }, [dispatch, params]);

    const handleClick = (item, index) => {
        setActive(item);
        setParams({ ...params, month: (index + 1).toString() });
    };

    const earningsAmount = typeof earnings?.earnings === 'number' ? earnings : "0";

    return (
        <div className='monthly-container w-full'>
            <div className='month-line user-select-none md:flex hidden justify-between mx-auto col-11 items-center'>
                {months.map((item, index) => (
                    <p
                        onClick={() => handleClick(item, index)}
                        className={`month ${active === item ? "bg-[#ED652B] text-white" : ""} m-0 text-black py-[6px] time cursor-pointer rounded-[8px] px-[16px]`}
                        key={index}
                    >
                        {item}
                    </p>
                ))}
                <select
                    value={params.years}
                    onChange={(e) => setParams({ ...params, years: e.target.value })}
                    className="select-year"
                >
                    {years.map((year) => (
                        <option value={year} key={year}>{year}</option>
                    ))}
                </select>
            </div>

            <div className='flex justify-center gap-4'>
                <select
                    value={active}
                    onChange={(e) => {
                        const monthIndex = months.indexOf(e.target.value) + 1;
                        setParams({ ...params, month: monthIndex.toString() });
                        setActive(e.target.value);
                    }}
                    className='select-year md:hidden block'
                >
                    {months.map((month, index) => (
                        <option key={index}>{month}</option>
                    ))}
                </select>

                <select
                    value={params.years}
                    onChange={(e) => setParams({ ...params, years: e.target.value })}
                    className='select-year md:hidden block'
                >
                    {years.map((year) => (
                        <option key={year}>{year}</option>
                    ))}
                </select>
            </div>

            <div className='earning-amt col-12 w-full px-[80px] my-[40px]'>
                <p className='text-[36px] text-[#2F1409] font-bold'>
                    ₹ {loading ? <Skeleton width={30} /> : earningsAmount?.earnings ? formatAmount(earningsAmount?.earnings) : 0}
                </p>
                <p className='font-medium text-[#888888] text-[16px]'>
                    {formatDownloads(earnings?.downloads)} Downloads
                </p>
            </div>
        </div>
    );
};

export default Monthly;
