import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUnderRevision } from "../../../redux/slices/under-revision.slice";
import NoRecordsFound from "../../../components/no-records-found";
import "./under-revision.css";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";

const Index = () => {

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const Datas = useSelector((state) => state.underRevision?.storeResponseData);

  useEffect(() => {
    dispatch(fetchUnderRevision({
      search: { mediaStatus: "SUBMIT", visibility: "NOT_PUBLISHED" },
      sortBy: { createdAt: true }
    })).then(() => setLoading(false))
  }, [])


  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }

  const handlePreviewClicked = (item) => {
    const newWindow = window.open('', '_blank');

    if (newWindow) {
      newWindow.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>
        ${item?.category === "IMAGE" ? "Image Preview" : "Video Preview"}
        </title>
        <style>
          body {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
            height: 100vh;
          }
          video {
            max-width: 100%;
            max-height: 100%;
          }
          img {
       
          }
        </style>
      </head>
      <body>
     

      ${item?.category === "IMAGE" ?
          `<img src="https://beyond-dream-development-local.s3.amazonaws.com/${item?.thumbnailSrc}" alt="Fullscreen Preview">`
          :
          ` <video controls autoplay>
      <source src="https://beyond-dream-development-local.s3.amazonaws.com/${item?.previewSrc}" type="video/mp4">
      Your browser does not support the video tag.
    </video>`}
    
        
      </body>
      </html>
    `);
      newWindow.document.close();
    }
  }





  return (


    <div>
      <div className="ps-1 pe-1 md:ps-5 md:pe-5 portfolio-tabs pt-4 pb-4 position-relative">

        {loading ?
          // <div role="status" className=" animate-pulse">
          //   <div className="h-[400px] bg-gray-200 rounded mx-auto dark:bg-gray-700 w-[96%] mb-4"></div>
          // </div>
          <>
            <Skeleton count={2} height={271} className="mb-4" />

          </>

          : Datas?.media && Datas?.media.length > 0 ?
            Datas?.media?.map((item) => (

              <div className="image-border-revision mt-3">
                <div className="under-revision-responsive">
                  <div className="under-revision-responsive-1">

                    <div>
                      <img onClick={() => handlePreviewClicked(item)} src={`${"https://beyond-dream-development-local.s3.amazonaws.com/"}${item?.thumbnailSrc}`} alt="data"
                        className="w-[150px] cursor-pointer h-[150px] md:w-[120px] md:h-[80px]  rounded"
                      />
                    </div>
                    <div className="ms-3">
                      <p className="revision-text capitalize">{item?.title}</p>
                      <p className="revision-text-1">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="submitted-text">Submitted on {formatDate(item?.updatedAt)}</p>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-md-2">
                    <div>
                      <p className="revision-type-text">Format Type</p>
                      <p className="revision-jpg">{item?.mediainfo?.format ? item?.mediainfo?.format : "--"}</p>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <p className="revision-type-text">Usage</p>
                      <p className="revision-jpg">{item?.usageFor ? item?.usageFor : "--"}</p>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <p className="revision-type-text">Location</p>
                      <p className="revision-jpg">{item?.shootLocation
                        ? item?.shootLocation === "undefined" ? "--" : item?.shootLocation : "--"
                      }</p>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <p className="revision-type-text">Asset Type</p>
                      <p className="revision-jpg">{item?.category ? item?.category.slice(0, 1).toUpperCase() + item?.category.slice(1).toLowerCase() : "--"}</p>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <p className="revision-type-text">Content Category</p>
                      <p className="revision-jpg">{item?.contentCategory?.length ? item?.contentCategory : "--"}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="revision-type-text">Keywords</p>
                  {item?.keywords?.length > 0 ? (
                    <div className="responsive-keyword flex d-flex flex-wrap ">
                      {item?.keywords?.map((item) => (
                        <span className="key-word-texts mx-[5px] my-[5px] lg:mx-[5px] text-center">{item}&nbsp;&nbsp;</span>
                      ))}
                    </div>
                  ) : null}


                </div>
              </div>
            )) : (
              <div>
                <NoRecordsFound />
              </div>
            )
        }

      </div>
    </div>
  );
};

export default Index;
