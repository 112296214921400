/******************************** Import libs *********************************** */

import Monthly from "../components/monthly/monthly";
import {
  getListByApi,
  //  getListByApiFile,
  viewDataByApi
} from "./actions";
import { URL_CONSTANTS } from "./urls";

const getAllDashboard = (params) =>
  getListByApi(URL_CONSTANTS.dashboard, params);

const getMediaById = (id, params) =>
  viewDataByApi(URL_CONSTANTS.getMediaById, id, params);

const getAllInsights = (params) => getListByApi(URL_CONSTANTS.insights, params);
const getAllRecentlyAdded = (params) =>
  getListByApi(URL_CONSTANTS.recentlyAdded, params);

const getUserInfoApi = (params) =>
  getListByApi(URL_CONSTANTS.getUserInfo, params);



const getSetsInfo = (id, params) =>
  viewDataByApi(URL_CONSTANTS.sets, id, params);


const getNonSets = (params) =>
  viewDataByApi(URL_CONSTANTS.updateSets, params);

const getSetsAssets = (params) =>
  viewDataByApi(URL_CONSTANTS.selectedUpdateSets, params);

const getAllSets = (params) =>
  getListByApi(URL_CONSTANTS.sets, params);
const getEarningsApi = (params) =>
  getListByApi(URL_CONSTANTS.dashboardEarnings, params);



const getAllEarningsApi = (params) =>
  getListByApi(URL_CONSTANTS.earnings, params);

const getTopSalesApi = (params) =>
  getListByApi(URL_CONSTANTS.topSales, params);





export { getAllDashboard, getAllInsights, getEarningsApi, getTopSalesApi, getAllRecentlyAdded, getMediaById, getAllEarningsApi, getUserInfoApi, getNonSets, getSetsAssets, getSetsInfo, getAllSets };
