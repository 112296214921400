import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getNonSets } from "../../api/list";



const initalState = {
    loading: false,
    storeSetsData: [],
    error: null
}

export const fetchNonSets = createAsyncThunk(
    "seller/fetchNonSetsData",
    async (params, { dispatch }) => {
        dispatch(fetchNonSetsRequest());
        const response = await getNonSets(params);
        if (response?.data && !response?.error) dispatch(fetchNonSetsData(response?.data));
        dispatch(fetchNonSetsError(response?.error));
        return response.data
    }
)



const getsNonSetsSlice = createSlice({
    name: "getsNonSets",
    initialState: initalState,
    reducers: {
        fetchNonSetsRequest: (state) => {
            state.loading = true
        },
        fetchNonSetsData: (state, action) => {
            state.storeSetsData = action.payload;
            state.loading = false;
        },
        fetchNonSetsError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default getsNonSetsSlice.reducer;

export const { fetchNonSetsRequest, fetchNonSetsData, fetchNonSetsError } = getsNonSetsSlice.actions;
