import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {GetAllMedia} from "../../api/create";


const initialState = {
    loading: false,
    storeRejectedData: [],
    error: null
}


export const fetchRejected = createAsyncThunk(
    "underRevision/fetchUnderRevision",
    async (params, { dispatch }) => {
        dispatch(addUnderRejectedRequest());
        const response = await GetAllMedia(params);
        if (response?.data && !response?.error) dispatch(addRejectedData(response?.data));
        dispatch(addRejectedError(response?.error));
    }
)



const underRejectedSlice = createSlice({
    name: "underRevision",
    initialState,
    reducers: {
        addUnderRejectedRequest: (state) => {
            state.loading = true;
        },
        addRejectedData: (state, action) => {
            state.loading = false;
            state.storeResponseData = action.payload;
        },
        addRejectedError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});



export default underRejectedSlice.reducer;

export const { addUnderRejectedRequest, addRejectedData, addRejectedError } = underRejectedSlice.actions;