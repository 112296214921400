import React from "react";
import "./input.css";

const Index = (props) => {
  const { placeholder, type, style, name, value, onChange, onBlur } = props;
  return (
    <div>
      <input
        name={name}
        value={value}
        className="custom-input"
        placeholder={placeholder}
        type={type}
        style={style}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default Index;
