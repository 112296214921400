import React, { useState } from "react";
import Format from "../../../assets/format.svg";
import { FaCheckCircle } from "react-icons/fa";
import Copy from "../../../assets/copy.svg";
import { Link } from "react-router-dom";
import { formatAmount, formatDownloads } from "../../../utils/functions";
const List = ({ details }) => {
  const [copied, setCopied] = useState(null);

  const imageDetails = details?.media;

  const handleCopy = (content) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        setCopied(content);
        setTimeout(() => {
          setCopied(false);
        }, 3000); // Hide tick after 3 seconds
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  return (
    <div className="container-fluid">
      {imageDetails
        ?.filter((image) => image?.category === "IMAGE")
        .map((image, index) => {
          return (
            <div key={index} className="image-list-main mt-[20px] col-lg-9 col-12">
              <div className="image-lists">
                <img
                  className="w-[200px] h-[120px] rounded"
                  src={`https://beyond-dream-development-local.s3.amazonaws.com/${image?.thumbnailSrc}`}
                  alt="sample"
                />
              </div>
              <div className="w-100 d-grid ps-3 pe-3">
                <div className="image-grid-section">
                  <div>
                    <div className="d-flex">
                      <img src={Format} alt="format" />
                      <span className="farm-text text-[32px] ps-2 capitalize">{image?.title}</span>
                    </div>
                    <p className="format-text text-[12px] mt-2">{image?.mediainfo?.format}</p>
                  </div>
                  <div className="responsive-contact-details">
                    <div className="responsive-contact-details-1">
                      <p className="image-list-content">Content ID</p>
                      <div className="d-flex align-items-center">
                        <p className="image-list-content-1">{image?.id}</p>&nbsp;
                        {copied == image?.id ? (
                          <FaCheckCircle color="green" />
                        ) : (
                          <img
                            src={Copy}
                            alt="copy"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleCopy(image?.id)}
                          />
                        )}
                      </div>
                    </div>
                    <div className="responsive-contact-details-1">
                      <p className="image-list-content">Content Category</p>
                      <p className="image-list-content-1">
                        {image?.contentCategory.length ? image?.contentCategory : "--"}
                      </p>
                    </div>
                    <div className="responsive-contact-details-1">
                      <p className="image-list-content">Price</p>
                      <p className="image-list-content-1 ">₹ {formatAmount(image?.price)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="responsive-view">
                <Link
                  to={`/portfolio/published/images/${image?.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <p className="view-text">View</p>
                </Link>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default List;
