import React from 'react'
import { useNavigate } from 'react-router-dom';
import "./license-aggreement.css"
const LicenseAgreement = () => {
  const navigate = useNavigate();
  const onClickWebsiteLink = () => {
    navigate('/')
  }
  return (
    <div>
      <div className="licAg-container">
        <div className='fs-22 fw-700 ft_mg_btm ft_ul_cmn'>
          Licence Agreement
        </div>
        <div className='licAg-contentContainer-CommonSty'>
          <span className=' cmn-Mrg fw-400'>
            This agreement in digital form constitutes a legal agreement (the “Agreement”)
          </span>
        </div>
        <div className='licAg-contentContainer-CommonSty '>
          <span className='cmn-Mrg fw-400'>Between</span>
        </div>
        <div className='licAg-contentContainer-CommonSty '>
          <span className='cmn-Mrg fw-600'>User / You or your company, firm or other organisation/ subscriber</span>
          <span className='cmn-Mrg fw-400'>&nbsp;to this website, as applicable (“you”)</span>
        </div>
        <div className='licAg-contentContainer-CommonSty '>
          <span className='cmn-Mrg fw-400'>And</span>
        </div>
        <div className='mrg-tp  licAg-inspireContainer'>
          <div className='licAg-contentContainer-CommonSty '>
            <span className='cmn-Mrg fw-600'>Inspire Films Private Limited</span>
            <span className='cmn-Mrg fw-400'>&nbsp;(“World Archives,") having address at [•]</span>
          </div>
          <span className='cmn-Mrg fw-400'>The user is connoted as the ‘user’ / ‘subscriber’ to this website.
            The parties shall deem to mean the parties to this agreement.</span>
          <span className='cmn-Mrg fw-400'>World Archives is a brand solely owned by Inspire Films Private Limited
            <span className='fw-600'>&nbsp;(“IFPL”)&nbsp;</span>and concept and idea by “Yash Patnaik”. The reference to the brand
            World Archives shall deem to be mean the exclusive copyright of IFPL and a
            limited license to use the images/ videos/ sound tracks and other contents
            available on&nbsp;<span onClick={onClickWebsiteLink} className='licAg-hyperlink fw-500'>www.worldarchives.in</span>
          </span>
          <span className='cmn-Mrg fw-400'>
            All Trademarks, logos and designs of World Archives belong to IFPL. All rights reserved.
          </span>
        </div>
        <div className='licAg_content_commonSty'>
          <div className='mg_btm_one'>
            <span className='fw-600 ft_ul_cmn'>WHEREAS</span>
          </div>
          <div className='licAg_content_infoCmnSty  '>
            <div className='licAg_index_info'>
              <div className='mg-rgt'>1.</div>
              <span>World Archives is a brand and concept by Yash Patnaik and exclusively owned by IFPL. IFPL is the owners of the website www.worldarchives.com and being an owner and operator of the World Archives.com website (and related domains) and system, has entered into agreements with Contributors to host and offer their Content for licensing as well as provide platform to third party contributors for the purposes of uploading their stock photos/ images/ videos sound tracks etc. IFPL is authorised to carry out the selling activities for licensing of digital images hosted by World Archives on its server.</span>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt'>2.</span>
              <span>Please read this Agreement carefully in its entirety before you download or use any Content. By confirming to the terms and conditions and the purchase of the Content, downloading the Content from the Website, or by otherwise obtaining or using the Content, you agree to be bound by the terms of this Agreement and the Content usage restrictions contained herein, in an Invoice and/or notified on the Website. If you do not wish to accept the terms of this Agreement, do not download the Content, and notify World Archives by email to the following address: [•] within fourteen (14) days from the Invoice date for a full refund.</span>
            </div>
          </div>
        </div>
        <div className='licAg_content_commonSty'>
          <div className='mg_btm_one'>
            <span className='fw-600 ft_ul_cmn'>
              NOW THIS AGREEMENT WITNESSTH AND IT IS AGREED BY AND BETWEEN THE PARTIES THAT
            </span>
          </div>
          <div className='licAg_content_infoCmnSty'>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>1.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Definitions &#38; Interpretations</div>
                <div>
                  <span className='fw-400 '>In this agreement, the following words and expressions (inclusing in the recitals hereof) or schedules hereto shall, unless the context requires otherwise, have the following meanings ascribed to them:</span>
                </div>
                <div className='licAg-info-points '>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.1</span>
                    <span>“World Archives” Means the copyright and ownership of IFPL and the word word archive shall deem to mean the website
                      &nbsp;<span onClick={onClickWebsiteLink} className='licAg-hyperlink'>www.worldarchives.in</span>
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.2</span>
                    <span>
                      “Content” Means a specific copy/copies of the image/s, Video/s, 360 image/s, vector/s, illustration/s, sound recordings, background music/score or graphic/s available on the Website that you have selected, and which is identified on the Invoice as well as by way of your selection;
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.3</span>
                    <span>
                      “Contributor” Means the parties who have submitted Content to World Archives or have allowed World Archives to access and obtain the Content by way of license and/or acquisition of content and/or shall also deemed to mean the content/ copyrightable works belonging to IFPL and their associates / subsidiaries;
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.4</span>
                    <span>
                      “Intellectual Property” Means all patents, rights to inventions, copyright and related rights, moral rights, trademarks, service marks, trade names, trade dress, symbols, logos and designs, business names and domain names, rights in get-up, goodwill and the right to sue for passing off, rights in designs, rights in computer software, database rights, rights to use, and protect the confidentiality of, confidential information (including know-how) and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world.
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.5</span>
                    <span>
                      “Invoice” Means the invoice provided by World Archives that may include, without limitation, details of the Content selected, any limitations on the Licence in addition to those specified herein, the key terms of the Licence and the Licence Fee. The terms contained in the invoice will be incorporated into this Agreement and all references to the Agreement will include those terms.
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.6</span>
                    <span>
                      “Licence” Means the non-exclusive (unless otherwise stated in the Invoice) right which the World Archives grants to you to Reproduce the Content which may include Rights Managed and/or Royalty Free licences and/or any other rights to use the image/ videos/ illustrations/ vectors etc by paying a one-time subscription / monthly / yearly subscription and/or one time usage of downloadable content by making payment of the one-time fee for the content.
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.7</span>
                    <span>
                      “Licence Fee” Means any sums payable to World Archives by you in respect of the Licence either in form of one time subscription / monthly/ yearly subscription as per the subscription plans on the website.
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.8</span>
                    <span>
                      “Release” Means a model or property release or any other release or clearance of a third-party right or other permission which it is necessary or desirable to obtain in respect of your use of the Content.
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.9</span>
                    <span>
                      “Reproduction” Means all or any form of copying, publication, reproduction, display, distribution, broadcast, streaming, printing or other exploitation of the Content (in each case whether or not to an audience) in the manner as decided by the subscriber but shall deem to mean the limits and regulations as imposed by the Ministry of Information and Broadcasting and the Information Technology Act, 2000.
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.10</span>
                    <span>
                      “Rights Managed” Means Content licensed for specific rights and for a specific purpose notified to you in the Invoice and expressly designated as “Rights Managed” or “RM” on the Website or Invoice.
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.11</span>
                    <span>
                      “Royalty Free” Means Content licensed for an unlimited number of permitted uses for a one-time Licence Fee and expressly designated as “Royalty-Free” or “RF” on the Website or Invoice.
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.12</span>
                    <span>
                      “Terms” Means the terms and conditions set out in this Agreement and includes the terms contained in the Invoice. In the event of any conflict between the terms contained in the Invoice and those in this Agreement, the terms in the Invoice will prevail.
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.13</span>
                    <span>
                      “Video” Means the specific copy of the film, video footage or any other audio visual work available on the Website that you have selected, and which is identified on the Invoice.
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
                    <span className='mg-rgt'>1.14</span>
                    <span>
                      “Website” Means the website owned and operated by World Archives at URL [•] (as amended by World Archives from time to time).
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>2.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Contracting parties</div>
                <div className='licAg-info-points '>
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0rem' }}>
                    <span className='mg-rgt'>2.1</span>
                    <span>  The parties to this Agreement are World Archives and you. World Archives is the property, owned and operated by IFPL and the owner and operator of the Website (and related domains) and system, has entered into agreements with Contributors to host and offer their Content for licensing. World Archives has an exclusive authority to carry out the selling activities for licensing of digital images hosted by World Archives on its server. The Licence granted herein is conditional on compliance by you and your personnel and contractors with this Agreement and on World Archives receipt of the Licence Fee in full.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>3.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Grant of rights and restriction</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mg_btm_two'>
                    <div className='mg-rgt'>3.1</div>
                    <div>
                      <div className='mg_btm_one'>Subject to the restrictions set out in these Terms, World Archives grants to you the non-exclusive (unless otherwise stated in the Invoice) right to Reproduce the Content in accordance with this Agreement.</div>
                      <div className='flexrowCmnSty'>
                        <div className='mg-rgt'>3.1.1</div>
                        <div>
                          <div className='mg_btm_one'>In relation to all Licences:</div>
                          <div className='flexrowCmnSty'>
                            <div className='mg-rgt'>(i)</div>
                            <div className='flex-columnCmnSty'>
                              <div>subject to Clause ___, you may alter, crop or re-modify the Content as follows:</div>
                              <div className='flexrowCmnSty'>
                                <span className='mg-rgt'>(a)</span>
                                <span>
                                  when used in a news or editorial context, you may only crop or otherwise edit the Content for technical quality purposes, provided that the editorial integrity of the Content is not compromised and the truth of the Content is maintained. The Content when used in a news or editorial context may not, under any circumstances, be otherwise altered;
                                </span>
                              </div>
                              <div className='flexrowCmnSty'>
                                <span className='mg-rgt'>(b)</span>
                                <span>
                                  Content depicting in copyright artwork may be cropped or otherwise edited for technical quality purposes only, provided that the original context and setting of the Content is not altered; and
                                </span>
                              </div>
                              <div className='flexrowCmnSty'>
                                <span className='mg-rgt'>(c)</span>
                                <span>
                                  subject to Clauses ___ and ____ you may alter, crop and create derivative works from the Content;
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(ii)</span>
                            <span>
                              you must not incorporate any Content (or any part of it) into a logo, trademark or service mark;
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(iii)</span>
                            <span>
                              Content must not be used as references for creating drawings or other visual works unless specifically authorised;
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(iv)</span>
                            <span>
                              the Content information (as defined in Clause [•]) and any caption or, keyword associated with the Content is provided “as is” and World Archives does not warrant the accuracy of such information. In particular you acknowledge that the Content Information may have been translated from its original language using an automated machine translation process that World Archives has had no input into or control over and that accordingly World Archives disclaims any liability for inaccurate, misleading, defamatory, insulting, offensive, infringing or unlawful Content created as a result of or arising out of such translation process;
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(v)</span>
                            <span>
                              you acknowledge that the Content licensed to you may be original Content that has had certain pre-formatting changes carried out by World Archives, as may be specified on the applicable Invoice. You are solely responsible for ensuring that the Content so altered is suitable and appropriate for your intended use. You agree that any altered Content that gives an untrue representation of reality should not be used for news or current events reporting. You accept that pre- formatting changes carried out to original Content by the Contributor may not be specified on the Invoice.
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(vi)</span>
                            <span>
                              you may not use the Content in a pornographic, defamatory, fraudulent, lewd, obscene or otherwise illegal manner, including, but not limited to use: (i) in connection with pornography, adult videos, adult entertainment venues, escort services, or the like; or (ii) which may or may be deemed to infringe any third-party Intellectual Property or privacy rights, whether directly or in context or by juxtaposition with other materials;
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(vii)</span>
                            <span>
                              if any Content featuring a model is used in a manner that would lead a reasonable person to believe that the model personally uses or endorses a product or service or if the depiction of the model in the Content would be unflattering or unduly controversial to a reasonable person, you must accompany each such use with a statement indicating that the person is a model and the Content is being used for illustrative purposes only;
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(viii)</span>
                            <span>
                              subject to the credit obligations in Clause [•], you may store the Content in a digital library, network configuration or other electronic storage system to allow it to be viewed within your organisation and by your clients. When your Licence period (as set out in the Invoice) ends or if your Licence is otherwise terminated, you must promptly delete the Content from your computer, digital library, network configuration or other electronic storage system. Any subsequent reuses of the Content must be agreed with World Archives in advance of such use to ensure the Content is available to use under the rights you require.
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(ix)</span>
                            <span>
                              not all the Content is Released. It is your responsibility to check that all necessary Releases have been secured (see Clause 8 below);
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(x)</span>
                            <span>
                              the Licence granted to you pursuant to this Agreement is personal to you and the Content may not: (i) subject to Clause [•], be sublicensed, resold, assigned, transferred or otherwise made available for use to any third party; or (ii) be distributed separately or detached from a product or web page. For example, the Content may be used as an integral part of a web page design, but may not be made available for downloading separately or in a format designed or intended for permanent storage or reuse by website users. Similarly, your end-users may be provided with copies of the Content as an integral part of work product but may not be provided with the Content or permitted to use the Content separately;
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(xi)</span>
                            <span>
                              you may only sublicense or assign the Content as incorporated into a work, project, product or production (each a “Product” in accordance with the Terms and only to the extent strictly necessary for the Content to appear in such Product. If you sublicense or assign the Content to any third party pursuant to this Clause[•]: (i) you will rescind your rights to use the Content in any other third party’s work; (ii) liability for the performance of your obligations under this Agreement will not be affected; and (iii) you will at all times remain responsible for the acts and omissions of such third party under or in connection with this Agreement as though such actions or omissions were performed by you and it is your responsibility to ensure that that third party understands and abides by all the Terms;
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(xii)</span>
                            <span>
                              the Content may not be distributed in a way that would allow any third party to download, extract or access the Content as a standalone file;
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(xiii)</span>
                            <span>
                              you may not use the Content in templates (i.e. in pre-formatted designs available to end-users to add their own content) without World Archives’s prior written consent;
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(xiv)</span>
                            <span>
                              the Content may be shared by creating an image library, network configuration or other similar arrangement provided that only individuals employed or contracted by the entity which is a party to this Agreement have access to the Content through such sharing process;
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(xv)</span>
                            <span>
                              you acknowledge that the Content may be provided to World Archives subject to arrangements (involving intellectual property and/or any other rights or otherwise), restrictions, prohibitions, directions or instructions imposed by Contributors on the use of such Content. You must: (i) comply with any and all such arrangements, restrictions, prohibitions, direction or instructions imposed by World Archives and/or its Contributors notified to you by World Archives before or at the time of delivery of the Content, either in the information accompanying the Content, on the Invoice or otherwise; and (ii) where applicable ,ensure that certain arrangements (including payment of any applicable third party licence or other fee) are made directly with any such Contributor; and
                            </span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>3.2</span>
                    <span>
                      In relation to all Licences, except Royalty Free licences, your Reproduction of the Content is strictly limited to the use,medium, period of time, territory and any other restrictions specified in the Terms. You may utilise the Content in any production
                      process that may be necessary for the intended use specified in the Invoice.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mg_btm_two mrg-tp'>
                    <div className='mg-rgt'>3.3</div>
                    <div>
                      <div className='mg_btm_two'>In relation to Video Content:</div>
                      <div className='flexrowCmnSty mrg-tp'>
                        <span className='mg-rgt'>(i)</span>
                        <span>you may not use stills derived from the Video without World Archives’s prior consent, except in in-context marketing, promotion, and advertising of a Product that incorporates the Video and only to the extent and in the context that such stills appear in that Product; and</span>
                      </div>
                      <div className='flexrowCmnSty mrg-tp'>
                        <span className='mg-rgt'>(ii)</span>
                        <span>(ii)	you may not use any Video in a pornographic, unlawful or defamatory context or manner, including use depicting a person in the Content as engaging in acts of moral turpitude or criminal activity, except where used in a news or editorial context.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>4.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Previews</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mg_btm_two'>
                    <div className='mg-rgt'>4.1</div>
                    <div>
                      <div className='mg_btm_one'>Notwithstanding any other term of this Agreement:</div>
                      <div className='flexrowCmnSty'>
                        <div>
                          <div className='flexrowCmnSty'>
                            <span className='mg-rgt'>(i)</span>
                            <span>
                              no warranty or right or licence granted to you (other than in this Clause 4) will apply to any preview of any item of Content displayed in or downloaded from the Website or otherwise provided to you (a “Preview”);
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(ii)</span>
                            <span>
                              any Preview provided to you may only be used for your internal review and evaluation (and in the case of Video Content, for period not exceeding 30 days from the date such Preview is downloaded or made available to you);
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(iii)</span>
                            <span>
                              you will limit access to Previews to your representatives who are informed of the terms of this Clause 4;
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(iv)</span>
                            <span>
                              you will not make available any Product containing the modified or unmodified Preview or a derivative work thereof to any third party, other than to a client which agrees to be bound by this Clause 4;
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(v)</span>
                            <span>
                              unless and until you or your client purchases a Licence relating to the Preview, neither you nor your client will exploit, print, publish, display, distribute or publicly stream, broadcast, display or perform any Previews or Product containing the modified or unmodified Preview or a derivative work thereof; and
                            </span>
                          </div>
                          <div className='flexrowCmnSty mrg-tp'>
                            <span className='mg-rgt'>(vi)</span>
                            <span>
                              if you or your client do not purchase a Licence from World Archives relating to the Preview (and in the case of Video Content, within 30 days from the date such Preview is downloaded or made available to you), you will permanently delete all copies of the Preview from any and all media.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>5.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Credit and Intellectual Property issues</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mg_btm_two'>
                    <div className='mg-rgt'>5.1</div>
                    <div>
                      <div className='mg_btm_one'>Nothing herein is intended or will be construed to transfer or assign any Intellectual Property rights, if applicable, of the Contributors or World Archives to you. You acknowledge that, with the exception of certain Content that may be in the public domain (for which you are obtaining access rights), all right, title and interest in and to the Content and in any Content Information, including, without limitation, any applicable Intellectual Property rights therein remain with the Contributors, and other than the specific rights granted in Clause 3, nothing contained herein will be construed to convey any rights or proprietary interest in the Content to you.</div>
                      <div className='flexrowCmnSty'>
                        <span className='mg-rgt'>5.1.1</span>
                        <span>The Content as stored, shared or otherwise made available by you must at all times retain any World Archives source credit, the name of any artist (if applicable), the Content identification reference number, any third party credit or notice, and any other information or metadata associated with the Content (“Content Information”) that is embedded in or provided with the electronic file that comprises any Content. Failure to maintain the integrity of the Content Information referred to in this Clause 5.2 will constitute a material breach of this Agreement.</span>
                      </div>
                      <div className='flexrowCmnSty'>
                        <span className='mg-rgt'>5.1.2</span>
                        <span>5.1.2	Unless otherwise agreed in writing, if any Content is reproduced by you for editorial or news purposes, you must include the credit line “(Photographer’s or Agency’s name)/World Archives", or any other credit line specified by World Archives.</span>
                      </div>
                    </div>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>5.2</span>
                    <span>
                      In connection with the use of "World Archives" or its partners' or Contributors’ trade names, trademarks, logos or service marks, including the names of all Content collections (“Marks”), you acknowledge and agree that: (i) such Marks are and will remain the sole property of World Archives Limited or its partners or Contributors (as applicable); (ii) nothing will confer upon you any right of use in or to the Marks; and (iii) you will not now or in the future contest the validity of the Marks.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>5.3</span>
                    <span>
                      You will immediately notify World Archives if you become aware or suspect that any third party: (i) has gained access to the Content through you; (ii) is wrongfully using the Content, in whole or in part; or (iii), is violating any of World Archives or any third party’s Intellectual Property rights.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>6.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Warranty and limitation of liability</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>6.1</span>
                    <span>
                      World Archives guarantees that should any Content contain defects in material or workmanship which are notified in writing to World Archives within fourteen (14) days of the date of delivery of the Content then World Archives will either replace that Content with another digital copy of the Content free from defect or refund the Licence Fee paid by you to the extent attributable to the defective Content, at World Archives’s option.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>6.2</span>
                    <span>
                      Subject to Clause 8.3 where copyright subsists in the Content, World Archives warrants that it is authorised by the copyright holder to offer a Licence on the terms set out in this Agreement.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>6.3</span>
                    <span>
                      World Archives makes no other warranty, express or implied, including, without limitation, any implied warranties of merchantability or fitness for a particular purpose. Neither World Archives nor its Contributors will be liable for any loss of profit or loss of revenue, loss of or damage to goodwill, loss of contracts, loss of customers or any indirect, special or consequential loss, damage, costs, expenses or other claims (whether caused by the negligence of World Archives, its servants or agents or otherwise) which arises out of or in connection with this Agreement, even if World Archives has been advised of the possibility of such loss. Each provision of this Clause 6.3 is to be construed as a separate limitation (applying and surviving even if for any reason one or other of such provisions is held to be inapplicable, unreasonable or unenforceable in any circumstances) and will remain in force notwithstanding the termination or expiry of this Agreement or any Licence hereunder.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>6.4</span>
                    <span>
                      World Archives maximum liability arising out of or in connection with your use of or inability to use the Content (whether such liability arises due to breach of contract, negligence or for any other reason) will be limited to three (3) times the total Licence Fees paid by you during the twelve (12) month period immediately prior to the notification to World Archives by you of an intention to make a claim under this Agreement. Neither World Archives nor its Contributors will have any liability for any claims, damages, losses, expenses or costs arising as a result of or in connection with any alteration, cropping, manipulation, editing or any other modifications made to the Content by you or on your behalf or as a result of the context in which the Content is used.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>6.5</span>
                    <span>
                      No term of this Agreement will preclude any liability or claim: (i) for death or bodily injury; (ii) for fraud; (iii) arising from wilful default or gross negligence on the part of World Archives or any of its employees, agents or otherwise; or (iv) which cannot be excluded or limited by law.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mg_btm_two'>
                    <div className='mg-rgt'>6.6</div>
                    <div>
                      <div className='mg_btm_one'>Notwithstanding any other provisions of this Agreement each party acknowledges that: (i) the Website may contain archival or other collections that include Content that are not protected by copyright, are in the public domain or for which copyright ownership is unknown; and (ii) in respect of such Content:</div>
                      <div className='flexrowCmnSty'>
                        <span className='mg-rgt'>(i)</span>
                        <span>neither World Archives nor its Contributors purport to have any Intellectual Property rights in such Content and are solely providing you with access to their copy based on your compliance with this Agreement; and</span>
                      </div>
                      <div className='flexrowCmnSty'>
                        <span className='mg-rgt'>(ii)</span>
                        <span>other copies of such Content may be available elsewhere for free.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>7.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Payment</div>
                <div className='licAg-info-points '>
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0rem' }}>
                    <span className='mg-rgt'>7.1</span>
                    <span>
                      Any Reproduction of the Content by you or on your behalf must be reported to World Archives as soon as practicable, and in any event within four (4) months of your download or receipt of the Content (whichever is earlier). Any failure in such reporting and/or delay in receipt of the Licence Fee by World Archives constitutes a material breach of this Agreement. Such material breach entitles World Archives to immediately terminate this Agreement and any Licence hereunder and may, in some instances constitute an infringement of copyright and/or other Intellectual Property rights.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>8.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Release information</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>8.1</span>
                    <span>
                      Unless Content is marked as having a Release available on the Website at the time you download or order it, World Archives gives no representations or warranties whatsoever: (i) as to the existence of any Releases associated with the Content; (ii) with respect to any names, trademarks, logos, trade dress, uniforms, registered or copyrighted designs, artistic works, architecture or other works depicted in any Content; ; (iii) with respect to any right of privacy or publicity of any person depicted in the Content; and (iv) in relation to the subject matter depicted or included in the Content and grants no rights in relation to the same. World Archives will not be liable for any third-party claims relating to the subject matter depicted therein.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>8.2</span>
                    <span>
                      You must satisfy yourself that all Releases as may be required for the Reproduction of the Content have been secured and that they are appropriate for your intended use. You are solely responsible for obtaining all such Releases and will be solely liable in the event that a suitable Release is not obtained. If you are unsure as to whether any Releases are needed for your Content usage, then it is your responsibility to consult with relevant parties. You will not rely upon any representation or warranty given by World Archives employees or representatives save as set out in this Agreement.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>8.3</span>
                    <span>
                      Any music, dialogue or other ambient audio contained in any Video is incidental only. World Archives gives no representations or warranties whatsoever as to the existence of any clearances or permissions relating to any such music, dialogue or audio that may be required and you are solely responsible for obtaining all such clearances or permissions.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>8.4</span>
                    <span>
                      Some items of Content may contain placeholder music, dialogue, other audio, text, video and/or images (“Placeholders”). Such Placeholders are intended only for demonstrations purposes and World Archives makes no warranties or representations as to such Placeholders and you are solely responsible for obtaining your own cleared versions of the same.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>8.5</span>
                    <span>
                      Failure or refusal by you to secure the relevant Releases for Reproduction of the Content is considered a material breach of this Agreement and a breach of Intellectual Property rights, for which you will be solely liable and for which you will indemnify and hold harmless World Archives, the Contributors, and their respective parents, subsidiaries, successors, assigns, and all employees and agents. This indemnification is in addition to, not in lieu of, the indemnification set forth in Section 8 herein and will survive the expiration or earlier termination of this Agreement.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>9.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Indemnity</div>
                <div className='licAg-info-points '>
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0rem' }}>
                    <span className='mg-rgt'>9.1</span>
                    <span>
                      You will indemnify, keep indemnified and hold harmless World Archives, its Contributors and their respective parents, subsidiaries, successors, assigns, and all employees and agents thereof against any and all claims, damages, losses, expenses or costs, including but not limited to any reasonable legal costs, arising in any manner whatsoever from: (i) your unauthorized use or allegedly unauthorized use of any Content; (ii) any third party claim in relation to your failure to secure any necessary Releases; or (iii) any other breach by you of any of your obligations under this Agreement. The terms of this Clause 9 will survive the expiration or earlier termination of this Agreement.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>10.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Licence fee</div>
                <div className='licAg-info-points '>
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0rem' }}>
                    <span className='mg-rgt'>10.1</span>
                    <span>
                      You will pay the Licence Fee to World Archives in accordance with Clause 14.1, the Licence Fee depends on the nature of the rights granted. You agree to notify World Archives in the event that you wish to expand the usage for the Content and pay any additional Licence Fee. Use of any Content in a manner not specifically authorised under the Terms constitutes a material breach of the Agreement and may in some instances constitute an infringement of copyright and/or other Intellectual Property rights.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>11.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Licence cancellation and termination</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>11.1</span>
                    <span>
                      If you have not downloaded an item of Content you may cancel the Licence and get a full refund within fourteen (14) days of the Invoice date. You will need to send an email to [•] with the Invoice number (e.g. [•]) and the Content file number (e.g. [•]). If you cancel, you will not be able to use the Content.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>11.2</span>
                    <span>
                      Once you download a purchased item of Content, you agree that you have no right to a refund, unless expressly stated otherwise in the Agreement.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>11.3</span>
                    <span>
                      World Archives may terminate or withdraw your Licence in relation to the Content based on a potential or actual legal claim. Upon such termination or withdrawal, you and your client (if applicable) must immediately discontinue all future use of the Content, delete the Content and all copies from all magnetic/electronic media and destroy all other copies in its or your possession or control. World Archives may replace the Content with alternate Content upon its discretion.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>11.4</span>
                    <span>
                      This Agreement and any Licence hereunder will terminate immediately if you: (i) enter into voluntary or compulsory liquidation, have a receiver appointed, or suffer any other insolvency or bankruptcy event, (ii) cease or threaten to cease to carry on trading (if applicable); or (iii) commit a material breach of this Agreement and, if such breach is remediable, it is not remedied within fourteen (14) days of receipt of notice requiring remedy. In the event of termination, all rights granted will immediately revert to us and any further exploitation of any Content may in some instances constitute an infringement of copyright and/or other Intellectual Property rights.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>11.5</span>
                    <span>
                      Any provision of this Agreement that expressly or by implication is intended to come into or continue in force on or after termination or expiry of this Agreement will remain in full force and effect.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>12.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Confidentiality</div>
                <div className='licAg-info-points '>
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0rem' }}>
                    <span className='mg-rgt'>12.1</span>
                    <span>
                      Each party agrees and undertakes that, both during and after the term of this Agreement, it will keep confidential, will not use for its own purposes and will not without the prior written consent of the other party disclose to any third party (other than to any company in either party’s group of companies) any information concerning the business and affairs of the other (including the terms (but not the fact) of this Agreement) which may become known to such party in connection with this Agreement unless such information is public knowledge, other than as a result of a breach of this paragraph, has been independently acquired from a third party without restriction on disclosure, or is required by law or any regulatory body or for the purposes of litigation by or against either party to be disclosed.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>13.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Data Privacy</div>
                <div className='licAg-info-points '>
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0rem' }}>
                    <span className='mg-rgt'>13.1</span>
                    <span>
                      We will collect and process personal information either submitted by you or collected by us to enable us to perform our contractual obligations to you and to provide services related to this Agreement. We will at all times adhere to applicable data protection laws and will process your personal information in accordance with our Privacy Policy.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>14.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Overdue Invoices</div>
                <div className='licAg-info-points '>
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0rem' }}>
                    <span className='mg-rgt'>14.1</span>
                    <span>
                      Unless otherwise agreed by us in writing, all Invoices are payable by you within thirty (30) days.
                    </span>
                  </div>
                </div>
                <div className='licAg-info-points '>
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0rem' }}>
                    <span className='mg-rgt'>14.2</span>
                    <span>
                      If you do not make full payment of an Invoice on time, we reserve the right to: (i) charge interest on the outstanding amount at the rate of 1.5 times the BPLR of State Bank of India or any other scheduled banks in India, prevailing at the time of default from the date payment was due until payment is received by World Archives; and/or (ii) terminate any Licence granted to you and/or suspend further services for you.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>15.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Condition of Content</div>
                <div className='licAg-info-points '>
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0rem' }}>
                    <span className='mg-rgt'>15.1</span>
                    <span>
                      You should make sure that you examine the Content for possible defects (whether digital or otherwise) before sending the Content for Reproduction. Subject to Clause 6.1, World Archives will not be liable for any loss or damage suffered by you or any third party arising from any alleged or actual defect in any Content or its caption or in any way from its Reproduction.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>16.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Downtime</div>
                <div className='licAg-info-points '>
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0rem' }}>
                    <span className='mg-rgt'>16.1</span>
                    <span>
                      Due to the nature of server provision, downtime and lost transmissions may occur as part of routine maintenance. You are advised to maintain a copy of your account status and details of Content purchased.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>17.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Audit</div>
                <div className='licAg-info-points '>
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0rem' }}>
                    <span className='mg-rgt'>17.1</span>
                    <span>
                      You will keep separate and detailed records of all Reproduction of the Content to enable World Archives to verify your compliance with the Terms. On not less than 10 days prior written notice, World Archives, or any other person authorised by World Archives, may inspect any records, accounts and/or servers during normal business hours relating to the Reproduction of the Content to ensure that the Content is being used in accordance with this Agreement. This right of inspection will remain in effect for a period of one (1) year after the expiry or termination of any Licence granted to you.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>18.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Miscellaneous terms</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>18.1</span>
                    <span>
                      World Archives reserves all rights to claim the royalties, levies, mechanical copying charges and other payments available from collective management organisations or other representative bodies in connection with secondary uses of the Content as incorporated in the end use (“Secondary Licensing Fees”), and you do not acquire any right, title or interest enabling you to claim or collect any Secondary Licensing Fees on our behalf, or on behalf of the authors we represent.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>18.2</span>
                    <span>
                      The Licence is conditional on you not being aware of or having received, prior to licensing any Content, any correspondence, representations, complaints or claims or third parties (collectively ‘Claims’) alleging that the Content in question is in breach of copyright or other third party Intellectual Property rights or is in some other way unauthorised. Any such Claims existing at the time the Content is purported to be Licenced will render any Licence granted void from the beginning. Any use of in-copyright Content in a manner not expressly authorised by this Agreement may constitute copyright infringement, entitling World Archives to exercise all rights and remedies available to it under copyright laws around the world. You will be responsible for any damages resulting from any such copyright infringement, including any Claims by a third party. In addition, and without prejudice to World Archives's other remedies under this Agreement, World Archives reserves the right to charge and you agree to pay a fee equal to up to five (5) times World Archives's standard Licence fee for the unauthorised use of the Content.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>18.3</span>
                    <span>
                      You shall ensure that you provide and maintain a valid and accessible email address supplied to World Archives at registration or as updated and notified to World Archives from time to time. World Archives may contact you or provide any notice to you under these Terms at the email address, postal address, telephone number, or using any other communication method, provided by you when you register.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>18.4</span>
                    <span>
                      Except where expressly stated otherwise in these Terms, no variation of any of these Terms will be effective unless in writing and signed by World Archives and you.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>18.5</span>
                    <span>
                      No action of World Archives, other than an express written waiver, may be construed as a waiver of any Clause of this Agreement. In the event that World Archives waives any specific part of this Agreement, such fact does not mean that any other part is waived.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>18.6</span>
                    <span>
                      This Agreement and the Terms overrides any terms contained in any purchase order or other communication sent by you, and no act or inaction by World Archives can be taken as acceptance of your offered term.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>18.7</span>
                    <span>
                      If any provision of this Agreement is held to be void or unenforceable in whole or in part, this Agreement will continue in force in relation to the unaffected provisions and the remainder of the provision in question.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>18.8</span>
                    <span>
                      Neither party will be liable to the other under or in connection with this Agreement for any failures, interruptions, delays or other matters of a similar nature arising out of circumstances beyond its reasonable control.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>18.9</span>
                    <span>
                      Subject to Clause 3.2.11, neither party will assign, sub-contract, sub-license or otherwise transfer its rights or obligations under this Agreement without the prior written consent of the other party, not to be unreasonably withheld or delayed
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>18.10</span>
                    <span>
                      In the event World Archives retains an attorney or collection agency to collect any outstanding payment due by you, you agree to pay all collection costs, attorneys' fees and court costs relating thereto, in addition to any outstanding amounts due and applicable interest.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>18.11</span>
                    <span>
                      18.11	This Agreement, its validity and effect, will be interpreted under and governed by the laws of India and be subject to the exclusive jurisdiction of courts at Mumbai, India. If World Archives is required to enforce its rights as a result of any breach of these terms, whether legal proceedings are commenced or not, you agree to indemnify World Archives in respect of all reasonable legal fees and costs incurred by World Archives in relation thereto. The terms of Clause 5.3 will not preclude any liability or claim for death or bodily injury or any claim arising from willful default or gross negligence on the part of World Archives or any of its employees, agents or otherwise. Each provision of Clause 5.3 is to be construed as a separate limitation (applying and surviving even if for any reason one or other of such provisions is held to be inapplicable, unreasonable or unenforceable in any circumstances) and will remain in force notwithstanding the termination of this Agreement.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>18.12</span>
                    <span>
                      This Agreement will be binding upon and inure to the benefit of the parties hereto and their respective legal representatives, successors, and assigns.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>18.13</span>
                    <span>
                      This Agreement supersedes all prior understandings both oral and written between the Parties.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>19.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Terms and conditions of sale for credit accounts</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mg_btm_two'>
                    <div className='mg-rgt'>19.1</div>
                    <div>
                      <div className='mg_btm_one'>Interpretation</div>
                      <div className='mg_btm_one'>In these conditions:</div>
                      <div className='flex-columnCmnSty' style={{ marginLeft: '3rem' }}>
                        <div>
                          <span className='mg-rgt'>(a)</span>
                          <span>“Buyer” means the person who makes the purchase of a license of the Image(s)/Video(s) online through the Website or who authorises the Seller to make a purchase on their behalf.</span>
                        </div>
                        <div>
                          <span className='mg-rgt'>(b)</span>
                          <span>“Image(s)/Video(s)”means the digital Image(s)/Video(s) that is supplied via the Website to the Buyer to fulfil their purchase.</span>
                        </div>
                        <div>
                          <span className='mg-rgt'>(c)</span>
                          <span>“Seller”means World Archives - Sales Division, a company registered in India.</span>
                        </div>
                        <div>
                          <span className='mg-rgt'>(d)</span>
                          <span>“Conditions”means the standard terms and conditions of sale for credit account customers set out in this document and includes any special conditions agreed in writing between the Buyer and the Seller.</span>
                        </div>
                        <div>
                          <span className='mg-rgt'>(e)</span>
                          <span>“Contract”means the contract for purchase and sale of Image(s)/Video(s)</span>
                        </div>
                        <div>
                          <span className='mg-rgt'>(f)</span>
                          <span>“Website”Means the website owned and operated by World Archives at  website [•] (as amended by World Archives from time to time).</span>
                        </div>
                        <div>
                          <span className='mg-rgt'>(g)</span>
                          <span>“Writing”includes email or postal service</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>19.2</span>
                    <span>
                      The headings of these Conditions are for convenience only and shall not affect their interpretation.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>20.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Basis of the sale</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>20.1</span>
                    <span>
                      The Image(s)/Video(s) may be purchased in a number of ways, either directly by the Buyer online using the Website or by the Buyer directing an employee of the Seller to purchase the Image(s)/Video(s) for them. In all cases the Buyer acknowledges that the sale has occurred.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>20.2</span>
                    <span>
                      The Buyer agrees that all Image(s)/Video(s) sales are governed by the Licence Agreement. The Licence Agreement can be viewed here.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>20.3</span>
                    <span>
                      The Buyer acknowledges that the Licence Agreement referred to in clause 2.ii is liable to change and the Licence Agreement in place on the Website at the time of each Image(s)/Video(s) sale determines the Licence Agreement applicable to that sale.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>20.4</span>
                    <span>
                      Any typographical, clerical or other error or omission in any sales literature, quotation, price list, acceptance of offer, invoice or other document or information issued by the Seller shall be subject to correction without any liability on the part of the Seller.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>21.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Price of the Image(s)/Video(s)</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>21.1</span>
                    <span>
                      The Seller shall sell and the Buyer shall purchase the Image(s)/Video(s) at a price which can be set in a number of ways. The Buyer may purchase the Image(s)/Video(s) at a price given by the Seller's price calculator on the Website, by the Buyer agreeing a quotation with the Seller, or in line with a price Agreement already agreed upon by the Buyer and the Seller.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>21.2</span>
                    <span>
                      The price may be exclusive of any applicable Goods and Services Tax (GST) or other tax on sales, which the Buyer shall additionally be liable to pay the Seller.
                    </span>
                  </div>

                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>22.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Terms of payment</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>22.1</span>
                    <span>
                      Subject to any special terms agreed between the Buyer and the Seller the Seller will invoice the Buyer once the order process is complete on the Website.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>22.2</span>
                    <span>
                      The Buyer shall pay the price of the Image(s)/Video(s) within 50 days of the date of the Seller's invoice. Any alterations to this payment term have to be agreed in Writing with the Seller. Receipts for payment will only be issued upon request.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>22.3</span>
                    <span>
                      The Seller operates a "Cancellation Policy" the terms of which are available in the licence cancellation clause in the Licence Agreement available through the Website and on each invoice. The Seller reserves the right to change this policy without notice.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>22.4</span>
                    <span>
                      Bank charges. The Buyer will pay for the Buyer’s bank charges and not deduct these from any amount owed to the Seller.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mg_btm_two'>
                    <div className='mg-rgt'>22.5</div>
                    <div>
                      <div className='mg_btm_one'>If the Buyer fails to make payment on the due date then, without prejudice to any other remedy available to the Seller, the Seller shall be entitled to:</div>
                      <div className='flexrowCmnSty'>
                        <span className='mg-rgt'>(i)</span>
                        <span>Cancel the Contract or suspend further services for the Buyer.</span>
                      </div>
                      <div className='flexrowCmnSty'>
                        <span className='mg-rgt'>(ii)</span>
                        <span>Charge interest on overdue Invoices and reasonable recovery costs. The Seller understands and will exercise our statutory right to claim interest and compensation for debt recovery costs under late payment legislation if we are not paid according to agreed terms and interest will be charged at State Bank of India base rate plus 8%.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>23.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Credit Account Terms</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>23.1</span>
                    <span>
                      The Buyer agrees that the Seller has the right to set credit limits on the account as they see fit and to withhold further credit at the Seller’s discretion.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>23.2</span>
                    <span>
                      The Seller has the right at all times to terminate the credit account and not to allow any future purchases.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>24.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Insolvency</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mg_btm_two'>
                    <div className='mg-rgt'>24.1</div>
                    <div>
                      <div className='mg_btm_one'>This clause applies if:</div>
                      <div className='flexrowCmnSty'>
                        <span className='mg-rgt'>(i)</span>
                        <span>The Buyer makes any voluntary arrangement with its creditors or (being an individual or firm) becomes bankrupt or (being a company) becomes subject of an administration order or goes into liquidation (otherwise than for the purpose of amalgamation or reconstruction); or</span>
                      </div>
                      <div className='flexrowCmnSty'>
                        <span className='mg-rgt'>(ii)</span>
                        <span>An encumbrancer takes possession, or a receiver is appointed, of any of the property or assets of the Buyer; or</span>
                      </div>
                      <div className='flexrowCmnSty'>
                        <span className='mg-rgt'>(iii)</span>
                        <span>The Buyer ceases, or threatens to cease, to carry on business; or</span>
                      </div>
                      <div className='flexrowCmnSty'>
                        <span className='mg-rgt'>(iv)</span>
                        <span>The Seller reasonably suspects that any of the events mentioned above is about to occur in relation to the Buyer and notifies the Buyer accordingly.</span>
                      </div>
                    </div>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>24.2</span>
                    <span>
                      If this clause applies then, without prejudice to any other right or remedy available to the Seller, the Seller shall be entitled to cancel the Contract or suspend any further deliveries of Image(s)/Video(s) to the Buyer without liability to the Buyer, and if the Image(s)/Video(s) have been delivered but not paid for then the price for them shall become immediately due and payable notwithstanding any previous agreement or arrangement to the contrary.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>25.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>Data Protection</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>25.1</span>
                    <span>
                      We shall process any personal information submitted by the Buyer to World Archives under these Conditions in accordance with our Privacy Policy.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>21.2</span>
                    <span>
                      The price may be exclusive of any applicable Goods and Services Tax (GST) or other tax on sales, which the Buyer shall additionally be liable to pay the Seller.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='licAg_index_info'>
              <span className='mg-rgt fw-600'>26.</span>
              <div className='licAg_infoContainer'>
                <div className=' fw-600 ft_ul_cmn'>General</div>
                <div className='licAg-info-points '>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>26.1</span>
                    <span>
                      Any notice required or permitted to be given by either party to the other under these Conditions shall be in Writing addressed to that other party at its registered office or principal place of business or other such address as may at the time be relevant having been notified pursuant to this provision to the party giving notice.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>26.2</span>
                    <span>
                      The Seller’s Licence Agreement will apply exclusively to all dealings between the Seller and the Buyer.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>26.3</span>
                    <span>
                      No waiver by the Seller of any breach of the Contract by the Buyer shall be considered as a waiver of any subsequent breach of the same or any other provision.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>26.4</span>
                    <span>
                      If any provision of these Conditions is held by any competent authority to be invalid or unenforceable in whole or in part the validity of the other provisions of these Conditions and the remainder of the provisions in question shall not be affected.
                    </span>
                  </div>
                  <div className='flexrowCmnSty mrg-tp'>
                    <span className='mg-rgt'>26.5</span>
                    <span>
                      The Contract shall be governed by the laws of India and the Buyer agrees to submit to the exclusive jurisdiction of the Indian courts i.,e Courts at Mumbai.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LicenseAgreement
