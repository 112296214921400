import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetAllMedia } from "../../api/create";


const initalState = {
    loading: false,
    storeResponseData: [],
    error: null
}

export const fetchAllMedia = createAsyncThunk(
    "allMedia/fetchAllMedia",
    async (params, { dispatch }) => {
        dispatch(addAllMedia());
        const response = await GetAllMedia(params);
        if (response?.success === true) {
            dispatch(addAllMediaData(response?.data));
            return response;
        } else {
            dispatch(addAllMediaError(response?.error));
            return response;
        }
    }
)



const allMediaSlice = createSlice({
    name: "dashboard",
    initialState: initalState,
    reducers: {
        addAllMedia: (state) => {
            state.loading = true
        },
        addAllMediaData: (state, action) => {
            state.storeResponseData = action.payload;
            state.loading = false;
        },
        addAllMediaError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default allMediaSlice.reducer;

export const { addAllMedia, addAllMediaData, addAllMediaError } = allMediaSlice.actions;
