import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSetsAssets } from "../../api/list";



const initalState = {
    loading: false,
    storeSetsData: [],
    error: null
}

export const fetchSetsAssets = createAsyncThunk(
    "seller/fetchSetsAssets",
    async (params, { dispatch }) => {
        dispatch(fetchSetsAssetsRequest());
        const response = await getSetsAssets(params);
        if (response?.data && !response?.error) dispatch(fetchSetsAssetsData(response?.data));
        dispatch(fetchSetsAssetsError(response?.error));
        return response.data
    }
)



const getSetsAssetsSlice = createSlice({
    name: "getSetsAssets",
    initialState: initalState,
    reducers: {
        fetchSetsAssetsRequest: (state) => {
            state.loading = true
        },
        fetchSetsAssetsData: (state, action) => {
            state.storeSetsData = action.payload;
            state.loading = false;
        },
        fetchSetsAssetsError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default getSetsAssetsSlice.reducer;

export const { fetchSetsAssetsRequest, fetchSetsAssetsData, fetchSetsAssetsError } = getSetsAssetsSlice.actions;
