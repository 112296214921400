const environmentList = [
  "http://localhost:8000/", // develop =0
  "http://192.168.0.111:3000/",
  "https://stage.api.worldarchives.in/",
  "https://api.worldarchives.in/",

];

const BuyerURLList = [
  "http://localhost:5000/", // develop =0
  "http://192.168.0.111:3000/",
  "https://stage.worldarchives.in/",
  "https://demo.worldarchives.in/",
]

export const env = 2// Place your environment number here
export const hostConfig = {
  WEB_URL: process.env.url,
  API_URL: `${environmentList[env]}`,
  BUYER_URL: `${BuyerURLList[env]}`,
  HOSTING_URL: 'https://beyond-dream-development-local.s3.amazonaws.com'
};


