import React from "react";
import "./button.css";

const Index = (props) => {
  const { style, children, disabled, type, onClick, buttonStyles } = props;
  return (
    <button
      disabled={disabled}
      type={type}
      style={style}
      onClick={onClick}
      className={`btn btn-primary custom-button ${buttonStyles}`}
    >
      {children}
    </button>
  );
};

export default Index;
