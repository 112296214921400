import React, { useEffect, useRef, useState } from "react";
import { IoMenu } from "react-icons/io5";
import MainLogo from "../../assets/main-logo.svg";
import "./navbar.css";
import { Link, NavLink, Navigate, } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import HeaderLogo from "../../assets/footer.png";
// import { selectFileType } from "../../redux/slices/user-avatar-slice";
import { CgProfile } from "react-icons/cg";
import { RiLogoutCircleFill } from "react-icons/ri";
import { fetchUserInfo } from "../../redux/slices/get-user-info";
import { useLocation } from "react-router-dom";
import { hostConfig } from "../../config";
import { RxAvatar } from "react-icons/rx";


const Index = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [imageError, setImageError] = useState(false);
  const avatar = useSelector((state) => state.userAvatar.imageUrl);

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const location = useLocation();


  useEffect(() => {
    dispatch(fetchUserInfo())
  }, [dispatch])

  const data = useSelector((state) => state?.getUserInfo?.storeUserData)
  // const userAvatar = useSelector(selectFileType);



  return (
    <div>

      <div
        className={
          "layout-nav d-flex justify-content-between align-items-center w-[100%] ps-5 pe-5 pt-2 pb-2 main-nav"
        }
      >
        <div className=" ">
          <Link to="/home">
            <img className="w-[50%]" src={HeaderLogo} alt="world-archives" />
          </Link>
        </div>
        <div className={`menu-items ${menuOpen ? "show" : "hide"}`}>
          <p className="ps-3 pe-3 mb-0">
            <NavLink to="/earnings" className="active-page">
              Earnings
            </NavLink>
          </p>
          <p className="ps-3 pe-3 mb-0">
            <NavLink to="/portfolio/published" className={`${location?.pathname.includes("/portfolio") ? "active" : "active-page"}`}>
              Portfolio
            </NavLink>
          </p>
          <p className="ps-3 pe-3 mb-0">
            <NavLink to="/insights" className="active-page">
              Insights
            </NavLink>
          </p>
        </div>

        <div className="">
          <p className="menu-button cursor-pointer mb-0" onClick={toggleMenu}>
            <IoMenu size={30} onClick={toggleMenu} />
          </p>
          <div style={{ userSelect: "none" }} className={`menu-items mb-0 ${menuOpen ? "show" : "hide"} d-flex align-items-center justify-content-center`}>

            <a className="text-decoration-none text-[14px] text-black" rel="noreferrer" href={hostConfig?.BUYER_URL} target="_blank"> <p className="m-0 hidden md:block">Switch To Purchase</p></a>



            <div style={{ borderRadius: "50%" }} className="dropdown hidden md:block ms-2 cursor-pointer" onClick={() => setShow(!show)} >
              <div className="ms-1 d-flex  justify-center  items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">

                <div style={{ border: "2px solid #E0E0E0", overflow: "hidden" }} className="w-[40px] me-1 d-flex justify-center items-center h-[40px] rounded-full bg-[#DCDBFF] ">

                  {/* {data ?
                   <img className=" w-[100%] h-[100%] object-cover" src={`${hostConfig?.HOSTING_URL}/seller/profile/${data?.seller?.id}`} alt="Selected" /> :
                    <span className="uppercase text-[16px] font-bold text-[#4946C3]">{data?.seller ? data?.seller?.firstName.slice(0, 1) + data?.seller?.lastName.slice(0, 1) : "US"}
                    </span>
                  } */}

                  {avatar ? <img className=" w-[100%] h-[100%] object-cover" src={avatar} alt="" /> : !imageError ?
                    <img onError={() => setImageError(true)} className=" w-[100%] h-[100%] object-cover" src={hostConfig?.HOSTING_URL + `/seller/profile/${data?.seller?.id}`} alt="US" /> :
                    // <span className="uppercase text-[16px] font-bold text-[#4946C3]">{data?.seller ? data?.seller?.firstName.slice(0, 1) + data?.seller?.lastName.slice(0, 1) : "US"}
                    // </span>
                    <RxAvatar className='w-full h-full text-gray-600' />
                  }
                </div>


                <span className="capitalize font-semibold text-[#ed652b]">{data?.seller?.firstName ? data?.seller?.firstName.slice(0, 8) : "user"}</span>
                <MdOutlineKeyboardArrowDown color="#ed652b" className={`${show ? "rotate-[-180deg]" : "rotate-0"} transform duration-200`} size={20} />
              </div>
              <ul className={`dropdown-menu position-absolute end-2 ${show ? "d-block" : "d-none"}`}>
                <li><Link to={"/my-account"} className="dropdown-item d-flex items-center gap-2">
                  {/* <CgProfile size={20} />  */}
                  <span>My Account</span></Link></li>
                <li><Link to={"/log-in"} onClick={() => localStorage.clear()} className="dropdown-item d-flex items-center gap-2">
                  {/* <RiLogoutCircleFill color="" className="text-[#ed652b]" size={20} /> */}
                  <span className="">Logout</span></Link></li>
              </ul>

            </div>
          </div>


          {menuOpen && (

            <div className="dropdown-menu-items  pt-3 pb-3 d-flex flex-column gap-4 block  lg:hidden">
              <div className="bg-gray-600 shadow py-3">
                <span className="text-white capitalize text-[20px]">{data?.seller?.firstName ? data?.seller?.firstName : "user"}</span>
              </div>
              <p className="ps-3 pe-3 mb-0  text-[#666666] " onClick={() => setMenuOpen(false)}>
                <NavLink
                  to="/my-account"
                  className="active-page hover:text-black  fw-bold fs-5"
                  onClick={() => setMenuOpen(false)}
                >
                  Profile
                </NavLink>
              </p>

              <p className="ps-3 pe-3 mb-0" onClick={() => setMenuOpen(false)}>
                <NavLink
                  to="/earnings"
                  className="active-page hover:text-black fw-bold fs-5"
                  onClick={() => setMenuOpen(false)}
                >
                  Earnings
                </NavLink>
              </p>
              <p className="ps-3 pe-3 mb-0" onClick={() => setMenuOpen(false)}>
                <NavLink
                  to="/portfolio/published"
                  className="active-page hover:text-black fw-bold fs-5"
                  onClick={() => setMenuOpen(false)}
                >
                  Portfolio
                </NavLink>
              </p>
              <p className="ps-3 pe-3 mb-0" onClick={() => setMenuOpen(false)}>
                <NavLink
                  to="/insights"
                  className="active-page hover:text-black fw-bold fs-5"
                  onClick={() => setMenuOpen(false)}
                >
                  Insights
                </NavLink>
              </p>


              <Link to={hostConfig?.BUYER_URL} target="_blank" className=" hover:text-black dropdown-item cursor-pointer menu-item text-[#666666] mb-0 fw-bold fs-5" >Switch To Purchase</Link>
              <Link to={"/log-in"} onClick={() => localStorage.clear()} className="dropdown-item text-danger fs-5 fw-bold">Logout</Link>
            </div>
          )}
        </div>
      </div>




    </div >
  );
};

export default Index;
