import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import List from "./list";
import "./published-images.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchPublished } from "../../../redux/slices/published.slice";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/loader";


import NoRecordsFound from "../../../components/no-records-found";


const Index = () => {



  const dispatch = useDispatch();

  const dataPublished = useSelector((state) => state.published?.storePublishedData);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchPublished({
      search: { visibility: "PUBLISHED", category: "VIDEO" },
    })).then(() => setLoading(false))
  }, [dispatch])



  const navigate = useNavigate();

  return (
    <div>
      <div className="ps-5 pe-5 portfolio-tabs-1 pt-4 pb-4 position-relative">


        <div>
          <p className="back-images ">
            <IoMdArrowBack className="cursor-pointer" onClick={() => navigate("/portfolio/published")} />
            &nbsp;Videos
          </p>


          {/* {dataPublished?.media?.length ? <List details={dataPublished} /> : <div className='flex flex-col justify-center items-center'>
            <img src={noRecordsFound} alt="no-records-found" className='w-[400px] h-[400px]' />
            <h6 className='text-center text-[#000] font-bold mt-[25px] text-[20px]'>No Results Found</h6>
            <p className='text-[#26385B] text-[16px] mt-[10px]'>No matches found</p>
          </div>}  */}

          {loading ?

            <Loader />



            : dataPublished?.media?.length ? <List details={dataPublished} /> :
              <NoRecordsFound />
          }






        </div>




      </div>


    </div>
  );
};

export default Index;

