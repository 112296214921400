import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getEarningsApi } from "../../api/list";
import { toast } from "react-toastify";


const initialState = {
    loading: false,
    storeAllEarningsData: [],
    error: null
}

export const fetchEarningsData = createAsyncThunk(
    "earnings/fetchIndividualEarnings",
    async (params, { dispatch }) => {
        dispatch(getAllEarningsRequest());
        const response = await getEarningsApi(params);

        if (response?.data && !response?.error) {
            dispatch(getAllEarningsData(response?.data));
            return response?.data
        };
        if (response?.error) toast.error(response?.message);
        dispatch(getAllEarningsError(response?.error));
    }
)

const getDashboardEarningsSlice = createSlice({
    name: "getAllEarnings",
    initialState,
    reducers: {
        getAllEarningsRequest: (state) => {
            state.loading = true;
        },
        getAllEarningsData: (state, action) => {
            state.storeAllEarningsData = action.payload;
            state.loading = false;
        },
        getAllEarningsError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export default getDashboardEarningsSlice.reducer;
export const {
    getAllEarningsRequest,
    getAllEarningsData,
    getAllEarningsError
} = getDashboardEarningsSlice.actions;