import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postImage } from "../../api/create";
import { postBulkImage } from "../../api/create";


const initialState = {
    loading: false,
    storeResponseData: [],
    error: null
}

export const fetchUploadImage = createAsyncThunk(
    "imageUpload/fetchUploadImage",
    async (params, { dispatch }) => {
        dispatch(addUploadImageRequest());
        const response = await postImage(params);
        if (response?.success === true) {
            dispatch(addUploadImageData(response?.data));
            return response;
        } else {
            dispatch(addImageUploadError(response?.error));
            return response;
        }
    }
)

const uploadImageSlice = createSlice({
    name: "imageUpload",
    initialState: initialState,
    reducers: {
        addUploadImageRequest: (state) => {
            state.loading = true
        },
        addUploadImageData: (state, action) => {
            state.storeResponseData = action.payload;
            state.loading = false;
        },
        addImageUploadError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default uploadImageSlice.reducer;

const { addUploadImageRequest, addUploadImageData, addImageUploadError } = uploadImageSlice.actions;
