const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            fn(...args);
        }, delay);
    };
};

const formatAmount = (amount) => {
    if (typeof amount !== 'number') return amount;

    const amountStr = amount.toString();

    if (amountStr?.length === 4) {
        return `${amountStr[0]},${amountStr.slice(1)}`;
    } else if (amountStr.length === 5) {
        return `${amountStr.slice(0, 2)},${amountStr.slice(2)}`;
    } else if (amountStr.length === 6) {
        return `${amountStr[0]},${amountStr.slice(1, 4)},${amountStr.slice(4)}`;
    } else {
        return amountStr; // 
    }
};

const formatDownloads = (downloads) => {
    if (downloads < 1000) {
        return downloads.toString();
    }
    const thousands = Math.floor(downloads / 1000);
    const remainder = downloads % 1000;
    const decimalPart = Math.floor(remainder / 100);

    return decimalPart > 0 ? `${thousands}.${decimalPart}K` : `${thousands}K`;
};

export {
    debounce, formatAmount, formatDownloads
};

