import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../components/button";
import Input from "../../components/input";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchResetPassword } from "../../redux/slices/post-reset-password-slice";
import Main from "../../assets/world-archives-white.png";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const ResetPassword = () => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const history = useNavigate();
    const location = useLocation();


    const params = new URLSearchParams(location.search);
    const resetPasswordToken = params.get("token");

    const formik = useFormik({
        initialValues: {
            password: "",
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            setLoader(true);
            const payload = {
                newPassword: values.password,
                resetPasswordToken, // Use the extracted token here
            };
            dispatch(fetchResetPassword(payload)).then((res) => {
                setLoader(false);
                if (res?.payload?.success === true) {
                    toast.success("Password updated successfully");
                    history("/log-in");
                } else {
                    toast.error(res?.payload?.message);
                }
            });
        },
    });

    return (
        <div className="banner">
            <div>
                <div className="text-center mb-3 d-flex justify-content-center">
                    <img src={Main} alt="main" />
                </div>
                <div className="row w-100 m-0">
                    <div className="col-md-4 col-sm-12 col-lg-4" />
                    <div className="col-md-4 col-sm-12 col-lg-4">
                        <form onSubmit={formik.handleSubmit} className="join-community">
                            <div className="row w-100 m-0">
                                <p className="join-community-text">Reset Your Password</p>
                                <p>Enter new password to change your password.</p>
                                <div className="col-12 mt-3 mb-3">
                                    <Input
                                        style={{
                                            border:
                                                formik.errors.password && formik.touched.password
                                                    ? "1px solid red"
                                                    : "1px solid rgba(200, 199, 199, 1)",
                                        }}
                                        placeholder={"New Password"}
                                        type="text"
                                        onBlur={formik.handleBlur}
                                        name="password"
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                    />
                                </div>

                                <div className="mt-3">
                                    <Button
                                        style={{
                                            opacity:
                                                loader || formik.isSubmitting || !formik.isValid
                                                    ? "0.5"
                                                    : "1",
                                            cursor:
                                                loader || formik.isSubmitting || !formik.isValid
                                                    ? "not-allowed"
                                                    : "pointer",
                                        }}
                                        type="submit"
                                    // disabled={loader || formik.isSubmitting || !formik.isValid}
                                    >
                                        {loader ? (
                                            <div
                                                className="spinner-border text-light mx-[57px]"
                                                role="status"
                                                style={{ width: "18px", height: "18px" }}
                                            >
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            "Change Password"
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4 col-sm-12 col-lg-4" />
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
