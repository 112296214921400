import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Company from "./company";
import Personal from "./personal";
import { postRegister } from "../../api/create";
import Main from "../../assets/world-archives-white.png";
import { toast } from "react-toastify";
import "./banner.css";
import HeaderImg from "../../assets/header.png";
import { useNavigate } from "react-router-dom";
const Index = () => {
  const [userCategory, setUserCategory] = useState("Personal"); // State to track user category
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    formik.resetForm()
    formikCompany.resetForm()
  }, [userCategory])

  const navigate = useNavigate();
  const handleUserCategoryChange = (category) => {
    setUserCategory(category);
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      userName: "",
      phone: "",
      email: "",
      password: "",
      agreeTerms: false,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("First Name is required"),
      userName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()<>?_+\-=`~])[A-Za-z\d!@#$%^&*()<>?_+\-=.`~]{8,}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character and be at least 8 characters long"
        ),
      phone: Yup.string().required("Phone Number is required").min(10).max(10),
      agreeTerms: Yup.boolean().oneOf(
        [true],
        "You must agree to the terms and conditions"
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setLoader(true);
      const data = {
        firstName: values?.fullName,
        lastName: values?.userName,
        userName: `${values.fullName} ${values.userName}`,
        password: values?.password,
        email: values?.email,
        panCardNumber: 899000,
        phone: values?.phone,
        sellerType: "INDIVIDUAL",
        address: "Tirpur",
      };
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      sessionStorage.setItem("email", values?.email);
      postRegister(formData).then((res) => {
        if (res?.data) {
          toast.success("User Registered Successfully");
          if (res?.data?.status === "VERIFIED") {
            setTimeout(() => {
              setLoader(false);
              window.location.href = "/home";
            }, 3000);
          } else {
            // toast.error("Please Verify Your Account");
            setTimeout(() => {
              setLoader(false);
              sessionStorage.setItem("email", values?.email);
              window.location.href = "/verify-account";
            }, 1000);
          }
        } else {
          toast.error(res?.message);
          setLoader(false);
        }
      });
      setSubmitting(false);
    },
  });

  const GSTIN_REGEX = /^[0-9A-Z]{15}$/;

  const formikCompany = useFormik({
    initialValues: {
      fullName: "",
      userName: "",
      email: "",
      companyName: "",
      gstinNumber: "",
      confirmPassword: "",
      phone: "",
      gstinFile: '',
      agreeTerms: false,
    },
    validationSchema: Yup.object().shape({
      gstinFile: Yup.mixed()
        .required("GSTIN file is required")
        .test("fileSize", 'File size should be less than 5MB', (value) => value && value.size <= 5242880)
        .test("fileType", 'Invalid file format', (value) => {
          if (!value) return false;
          const supportedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
          return supportedFormats.includes(value.type);
        }),


      phone: Yup.string().required("Phone Number is required").min(10).max(10),
      fullName: Yup.string().required("First Name is required"),
      userName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      companyName: Yup.string().required("Company Name is required"),
      gstinNumber: Yup.string()
        .matches(GSTIN_REGEX, 'GSTIN must be 15 characters long and include only numbers and uppercase letters')
        .min(15, 'GSTIN must be exactly 15 characters')
        .max(15, 'GSTIN must be exactly 15 characters')
        .required('GSTIN number is required'),
      confirmPassword: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()<>?_+\-=`~])[A-Za-z\d!@#$%^&*()<>?_+\-=.`~]{8,}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character and be at least 8 characters long"
        ),
      agreeTerms: Yup.boolean().oneOf(
        [true],
        "You must agree to the terms and conditions"
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setLoader(true);
      const data = {
        firstName: values?.fullName,
        lastName: values?.userName,
        userName: `${values.fullName} ${values.userName}`,
        password: values?.confirmPassword,
        email: values?.email,
        panCardNumber: 899000,
        phone: values?.phone,
        sellerType: "COMPANY",
        address: "Tirpur",
      };
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      postRegister(formData).then((res) => {
        if (res?.data) {
          toast.success("User Registered Successfully");
          if (res?.data?.status === "VERIFIED") {
            setTimeout(() => {
              setLoader(false);
              window.location.href = "/home";
            }, 3000);
          } else {
            // toast.error("Please Verify Your Account");
            setTimeout(() => {
              setLoader(false);
              sessionStorage.setItem("email", values?.email);
              window.location.href = "/verify-account";
            }, 1000);
          }
        } else {
          toast.error(res?.message);
        }
      });
      setSubmitting(false);
    },
  });

  const numberValidate = (event) => {
    const { name, value } = event.target;
    const numericValue = value.replace(/\D/g, '').slice(0, 10);
    if (userCategory === "Personal") {
      formik.setFieldValue(name, numericValue);
    } else {
      formikCompany.setFieldValue(name, numericValue);
    }
  }

  const spaceValidate = (event) => {
    const { name, value } = event.target;
    const newValue = name === 'gstinNumber' ? value.replace(/\s/g, '') : value.replace(/[0-9\s]/g, '');
    if (userCategory === "Personal") {
      formik.setFieldValue(name, newValue);
    } else {
      formikCompany.setFieldValue(name, newValue);
    }
  };

  const passwordSpaceValidate = (event) => {
    const { name, value } = event.target;
    const newValue = value.replace(/\s/g, '');
    if (userCategory === "Personal") {
      formik.setFieldValue(name, newValue);
    } else {
      formikCompany.setFieldValue(name, newValue);
    }
  };


  return (
    <div className="banner">
      <div>
        <div className="text-center mb-3 d-flex justify-content-center ">
          <img className="w-[100px] h-[50px]" src={HeaderImg} alt="main" />
        </div>
        <div className="row w-100 m-0">
          <div className="col-md-4 col-sm-12 col-lg-4" />
          <div className="col-md-4 col-sm-12 col-lg-4">
            <div className="join-community h-[600px] ">
              <div>
                <p className="join-community-text">Join the Community</p>
                <p className="user-category">User Category</p>
                <div>
                  <input
                    type="radio"
                    onChange={() => handleUserCategoryChange("Personal")} // Set user category to Personal
                    checked={userCategory === "Personal"} // Check if Personal is selected
                  />
                  &nbsp;
                  <span>Personal</span>&nbsp;&nbsp;{" "}
                  <input
                    type="radio"
                    onChange={() => handleUserCategoryChange("Company")} // Set user category to Company
                    checked={userCategory === "Company"} // Check if Company is selected
                  />
                  &nbsp;
                  <span>Company</span>
                </div>

                {userCategory === "Personal" ? (
                  <Personal
                    loader={loader}
                    numberValidate={numberValidate}
                    formik={formik}
                    spaceValidate={spaceValidate}
                    showPassword={showPassword}
                    passwordSpaceValidate={passwordSpaceValidate}
                    setShowPassword={setShowPassword}
                  />
                ) : null}
                {userCategory === "Company" ? (
                  <Company formikCompany={formikCompany}
                    numberValidate={numberValidate}
                    spaceValidate={spaceValidate}

                    passwordSpaceValidate={passwordSpaceValidate}
                    loader={loader} />
                ) : null}

              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-lg-4" />
        </div>
      </div>
    </div>
  );
};

export default Index;
