// ****************** React Lib *************************//
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { useParams } from "react-router-dom";
import Upload from "../../../assets/upload.svg"
// ****************** Formik ***************************//
import { useFormik } from "formik";
import * as Yup from "yup";

//*******************React Icons ************************/
import { MdDelete } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa";
import { BsExclamationCircle } from "react-icons/bs";

// ****************** Assets *****************************//
import BlueTick from "../../../assets/blue-tick.svg";

// ****************** Redux ***************************//
import { fetchNonSets } from "../../../redux/slices/gets-non-sets.slice";
import { fetchSetsAssets } from "../../../redux/slices/get-sets-assets.slice";
import { useSelector, useDispatch } from "react-redux";
import { updateSetsById } from "../../../redux/slices/update-sets-by-id.slice";
import { fetchSetsData } from "../../../redux/slices/get-sets.slice";
import { setsAssetsDelete } from "../../../redux/slices/delete-bulk-sets-assets.slice";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

// ****************** Components ***************************//
import NoRecordsFound from "../../../components/no-records-found";

// ****************** Styles *****************************//
import "./../sets.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Loader from "../../../components/loader/loader";

const UpdateSets = () => {

    const [selectedCount, setSelectedCount] = useState(0);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedSetsData, setSelectedSetsData] = useState([]);
    const [selectedSetsCount, setSelectedSetsCount] = useState(0);
    const [show, setShow] = useState(false)
    const [showDeleteBtn, setShowDeleteBtn] = useState(false)
    const handleClose = () => setShowDeleteBtn(false);
    const handleShow = () => setShowDeleteBtn(true);
    const [showAddBtn, setShowAddBtn] = useState(true)
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    // const [showUpdateButton, setShowUpdateButton] = useState(false)
    const navigate = useNavigate();
    let setsId = useParams()
    const nonSets = useSelector((state) => state.getNonSets?.storeSetsData);
    const setsAssets = useSelector((state) => state.getSetsAssets?.storeSetsData?.setsMapperdata);
    const setsData = useSelector((state) => state.getSets?.storeResponseData);;






    useEffect(() => {
        dispatch(fetchSetsAssets(setsId?.id));
        dispatch(fetchSetsData(setsId?.id));
        handleClearSelection();
    }, [dispatch, setsId?.id, show]);;



    const formik = useFormik({
        initialValues: {
            name: setsData?.sets_name,
            selectedCount: 0,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
        }),

        onSubmit: async (values, { setSubmitting }) => {

            const payload = {

                medias: selectedData?.map((item) => {
                    return {
                        id: item?.id,
                        sets_name: formik.values.name,
                        category: item?.category,
                        media_info: item?.mediainfo,
                        thumbnail: item?.thumbnailSrc
                    }
                }),
            }
            dispatch(updateSetsById({ params: payload, id: setsId?.id })).then((res) => {
                if (res) {
                    navigate(`/update-sets/${setsId?.id}`)
                    setShow(false)
                    setShowAddBtn(true)
                    handleClearSelection()
                }
            })


        },
    })





    //checkBox for update a sets

    const handleCheckboxChange = (e, item) => {
        const selecteddata = [...selectedData];

        if (e.target.checked && selecteddata.indexOf(item) === -1) {
            selecteddata.push(item);
        } else {
            selecteddata.splice(selecteddata.indexOf(item), 1);
        }
        setSelectedData(selecteddata);
        setSelectedCount(selecteddata.length);
    };

    // checkBox for delete a sets in assets

    const handleCheckboxChange2 = (e, item) => {

        const idString = item.toString()

        const selectedSetsdata = [...selectedSetsData];

        if (e.target.checked && selectedSetsdata.indexOf(idString) === -1) {
            selectedSetsdata.push(idString);
        } else {
            selectedSetsdata.splice(selectedSetsdata.indexOf(idString), 1);
        }
        setSelectedSetsData(selectedSetsdata);
        setSelectedSetsCount(selectedSetsdata.length);
    };


    const spaceValidate = (event) => {
        const { name, value } = event.target;
        const newValue = value.replace(/\s+/g, '');
        formik.setFieldValue(name, newValue)
    };

    const handleDelete = () => {
        dispatch(setsAssetsDelete({ Ids: selectedSetsData })).then((res) => {
            setSelectedCount(0);
            setSelectedSetsCount(0);
            setSelectedData([]);
            setSelectedSetsData([]);
            dispatch(fetchSetsAssets(setsId?.id));
            dispatch(fetchNonSets(setsId?.id));
        });



    }



    const handleClearSelection = () => {
        const checkboxes = document.querySelectorAll(".checkbox-style");
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
        setSelectedData([]);
        setSelectedCount(0);
        setSelectedSetsData([]);
        setSelectedSetsCount(0);
    };

    // const handleChangeName = (event, setsName) => {
    //     const { name, value } = event.target;
    //     formik.setFieldValue(name, value);
    //     if (value !== setsName) {
    //         setShowUpdateButton(true)
    //     } else {
    //         setShowUpdateButton(false)
    //     }
    // }

    // const spaceValidate = (event) => {
    //     const { name, value } = event.target;
    //     const newValue = name === 'gstinNumber' ? value.replace(/\s/g, '') : value.replace(/[0-9\s]/g, '');
    //     if (userCategory === "Personal") {
    //       formik.setFieldValue(name, newValue);
    //     } else {
    //       formikCompany.setFieldValue(name, newValue);
    //     }
    //   };


    return (



        <div className="container-fluid">
            <div className="mt-4 responsive-sets">
                <form onSubmit={formik.handleSubmit}>
                    <div className="d-flex justify-content-between ">
                        <p className="back-images">

                            <div className="cursor-pointer" onClick={() => navigate(-1)} style={{ color: "black" }}>
                                <IoMdArrowBack />
                            </div>
                            &nbsp;Update a Set
                        </p>

                        {show ? <button style={selectedCount > 0 ? { backgroundColor: "#ED652B", cursor: "pointer" } : { backgroundColor: "#ffbe76", cursor: "not-allowed" }} disabled={selectedCount === 0} className={`create-set `} type="submit" >
                            Update a Set
                        </button> : null}

                    </div>

                    <div className="input-wrapper ">
                        <input
                            className="add-title-create border-b-2 text-[#2F1409] "
                            type="text"
                            placeholder="Add a Title"
                            name="name"
                            value={formik.values.name}
                            onChange={(e) => spaceValidate(e)}
                            onBlur={formik.handleBlur}
                        />

                    </div>
                    {formik.touched.name && formik.errors.name && (
                        <div className="text-danger ms-2">{formik.errors.name}</div>
                    )}
                </form>


                {/* pop-up model for sure to delete selected pictures */}
                <Modal
                    show={showDeleteBtn}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body className="text-center gap-3 d-flex flex-column justify-content-center" >

                        <div className=" mt-2 d-flex justify-content-center w-full"><BsExclamationCircle className="text-secondary" size={50} /></div>
                        <div className="fs-5">Are you sure you want to delete selected pictures?</div>

                        <div className="d-flex justify-content-center gap-3">
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>

                            <Button onClick={() => { handleDelete(); handleClose() }} variant='danger'  >Delete</Button>
                        </div>
                    </Modal.Body>
                </Modal>



                {selectedCount > 0 || selectedSetsCount > 0 ? (
                    <div className="d-flex justify-content-between align-items-center mt-3 mb-3">

                        {show ? <p className="picture-text d-flex gap-2 align-items-center"> <FaArrowLeft size={20} onClick={() => { setShow(false); setShowAddBtn(true) }} className="cursor-pointer" /> Back</p> : <p className="picture-text">  Pictures</p>}
                        <div className="d-flex align-items-center">
                            <p className="selected-text d-flex">
                                <img src={BlueTick} alt="tick" width="18px" />
                                &nbsp;{selectedCount || selectedSetsCount}&nbsp; {selectedCount > 1 || selectedSetsCount > 1 ? "Pictures are Selected" : "Picture is Selected"}
                            </p>
                            &nbsp;&nbsp;
                            <button onClick={handleClearSelection} className="clear-btn me-3">
                                Clear all
                            </button>
                            {showAddBtn ? <MdDelete onClick={handleShow} className="text-[#ED652B]  me-2 cursor-pointer" size={30} /> : null}

                        </div>
                    </div>
                ) : (

                    <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                        {show ? <p className="picture-text  d-flex gap-2 align-items-center"> <FaArrowLeft size={20} onClick={() => { setShow(false); setShowAddBtn(true); handleClearSelection(); }} className="cursor-pointer" /> Back</p> : <p className="picture-text">Pictures </p>}

                        <div
                            style={{
                                visibility: "hidden",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <p className="selected-text d-flex">
                                <img src={BlueTick} alt="tick" width="18px" />
                                &nbsp;{selectedCount}&nbsp;Pictures is Selected
                            </p>
                            &nbsp;&nbsp;
                            <button onClick={handleClearSelection} className="clear-btn">
                                Clear all
                            </button>
                        </div>


                        {showAddBtn ?

                            <button onClick={() => { setShow(!show); setShowAddBtn(!showAddBtn); dispatch(fetchNonSets(setsId?.id)).then(() => setLoading(false)); }} className={`create-set `} type="" >
                                Add Pictures
                            </button> : null

                        }




                    </div>
                )}



                {/* show selected images */}




                {show ?
                    <>

                        {setsAssets?.length > 0 ?
                            <div className="d-flex  p-4 align-items-center  border-2 mb-4 rounded">
                                <div style={{ width: "80%" }} className="d-flex gap-3">
                                    {setsAssets?.length > 0 ? (
                                        setsAssets?.map((data, index) => (
                                            index <= 4 ?
                                                <div key={index} className="h-[156px]   w-[180px]"><img className="rounded w-full h-full" src={`${"https://beyond-dream-development-local.s3.amazonaws.com/"}${data?.thumbnail}`} alt="" /> </div> : null
                                        ))
                                    ) : (
                                        null
                                    )}
                                </div>
                                {setsAssets?.length > 5 ? <Link className="text-[#ED652B]">+ {setsAssets?.length ? setsAssets?.length - 5 : 0} Images</Link> : null}
                            </div> : null


                        }


                        {loading ?

                            <Loader />
                            :


                            <div className="">

                                <p style={{ fontWeight: "600" }} className="fs-5">Pictures to add into sets</p>


                                <div>
                                    {nonSets?.length > 0 ?

                                        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 5 }}>
                                            <Masonry gutter="10px">
                                                {nonSets?.length > 0 ? (
                                                    nonSets
                                                        .filter(data => data?.category === "IMAGE")
                                                        .map((data, index) => (
                                                            <div key={index} className="relative rounded">
                                                                <input
                                                                    id={`checkbox-${data?.id}`}
                                                                    type="checkbox"
                                                                    className="position-absolute checkbox-style"
                                                                    onChange={(e) => handleCheckboxChange(e, data)}
                                                                />
                                                                <label htmlFor={`checkbox-${data?.id}`} className="">
                                                                    <img
                                                                        className="max-w-full rounded-lg"
                                                                        src={`https://beyond-dream-development-local.s3.amazonaws.com/${data?.thumbnailSrc}`}
                                                                        alt=""
                                                                    />
                                                                </label>
                                                            </div>
                                                        ))
                                                ) : (
                                                    ""
                                                )}
                                            </Masonry>
                                        </ResponsiveMasonry>

                                        :

                                        <div className="w-full h-[30vh] flex flex-col items-center justify-center gap-3">
                                            <span className="text-gray-600">There are no pictures included in the album</span>
                                            <div onClick={() => navigate("/image-upload")} className="px-4 flex items-center w-fit cursor-pointer py-2 bg-[#ed652b] text-white rounded-[10px]"><img src={Upload} alt="upload" />
                                                <span>Upload</span>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>

                        }

                    </>

                    :

                    <div>
                        {setsAssets?.length > 0 ?

                            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 5 }}>
                                <Masonry gutter="10px">
                                    {setsAssets?.length > 0 ? (
                                        setsAssets
                                            .filter(data => data?.category === "IMAGE")
                                            .map((data, index) => (
                                                <div key={index} className="relative rounded">
                                                    <input
                                                        id={`checkbox-${data?.id}`}
                                                        type="checkbox"
                                                        className="position-absolute checkbox-style"
                                                        checked={selectedSetsData?.includes(data?.id.toString())}
                                                        onChange={(e) => handleCheckboxChange2(e, data?.id)}
                                                    />
                                                    <label htmlFor={`checkbox-${data?.id}`} className="">
                                                        <img
                                                            className="max-w-full rounded-lg"
                                                            src={`https://beyond-dream-development-local.s3.amazonaws.com/${data?.thumbnail}`}
                                                            alt=""
                                                        />
                                                    </label>
                                                </div>
                                            ))
                                    ) : (
                                        ""
                                    )}
                                </Masonry>
                            </ResponsiveMasonry>

                            :

                            <div className="w-[100%] flex items-center justify-center">
                                <NoRecordsFound />
                            </div>
                        }

                    </div>



                }

            </div>
        </div>
    );
};

export default UpdateSets;

