import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import { ToastContainer, Zoom } from "react-toastify";
import SignUp from "./pages/sign-up";
import SignIn from "./pages/sign-in";
import ForgotPassword from "./pages/forgot-psw";
import Earnings from "./pages/earnings";
import Portfolio from "./pages/portfolio";
import Layout from "./container/layout";
import VerifyAccount from "./pages/verify-account";
import UnderRevision from "./pages/portfolio/under-revision";
import Published from "./pages/portfolio/published";
import Rejected from "./pages/portfolio/rejected";
import Insight from "./pages/insight";
import PublishedImages from "./pages/portfolio/published-images";
import PublishedVideos from "./pages/portfolio/published-videos";
import PublishedIllustration from "./pages/portfolio/published-illustration";
import DetailedImage from "./pages/detailed-images";
import PublishedFiles from "./pages/portfolio/published-files";
import MyAccount from "./pages/my-account/my-account";
import Sets from "./pages/sets";
import ImageUpload from "./pages/image-upload"
import SubmitContent from "./pages/portfolio/submit-content";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import PrivateRoute from "./private-route";
import UpdateSets from "./pages/sets/update-sets/update-sets";
import SetsList from "./pages/sets/sets-list/sets-list";
import ScrollToTop from "./pages/scroll-to-top";
import ResetPassword from "./pages/reset-password/reset-password";
import ResetPasswordVerification from "./pages/reset-password/reset-password-verification"
import AboutUs from "./pages/footer-pages/about-us";
import Mission from "./pages/footer-pages/mission";
import Support from "./pages/footer-pages/support";
import DataDeletionPolicy from "./pages/footer-pages/data-deletion-policy";
import TermsAndConditions from "./pages/footer-pages/terms-and-conditions";
import PrivacyPolicy from "./pages/footer-pages/privacy-policy";
import LicenseAgreement from "./pages/footer-pages/license-agreement";
import SignUpPrivacyPolicy from ".//pages/footer-pages/signup-links/privacy-policy"
import SignUpTermsAndCondition from ".//pages/footer-pages/signup-links/terms-and-conditions"

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<SignUp />} />
          <Route path="/log-in" element={<SignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-account" element={<VerifyAccount />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password/verification" element={<ResetPasswordVerification />} />
          <Route path="/reset-password/verification" element={<ResetPasswordVerification />} />
          <Route path="/signup/privacy-policy" element={<SignUpPrivacyPolicy />} />
          <Route path="/signup/terms-and-conditions" element={<SignUpTermsAndCondition />} />


          <Route element={<PrivateRoute />}>
            <Route element={<Layout />}>

              <Route path="/home" element={<Home />} />
              <Route path="/earnings" element={<Earnings />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/mission" element={<Mission />} />
              <Route path="/support" element={<Support />} />
              <Route path="/data-deletion-policy" element={<DataDeletionPolicy />} />
              <Route path="/terms-conditions" element={<TermsAndConditions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/license-agreement" element={<LicenseAgreement />} />

              <Route path="/portfolio" element={<Portfolio />} >
                <Route index path="/portfolio/published" element={<Published />} />
                <Route path="/portfolio/under-revision" element={<UnderRevision />} />
                <Route path="/portfolio/rejected" element={<Rejected />} />
                <Route path="/portfolio/submit-content" element={<SubmitContent />} />
                <Route path="/portfolio/published/images" element={<PublishedImages />} />
                <Route path="/portfolio/published/illustration" element={<PublishedIllustration />} ></Route>
                <Route path="/portfolio/published/videos" element={<PublishedVideos />}></Route>
              </Route>


              <Route path="/portfolio/published/images/:id" element={<DetailedImage />} />
              <Route path="/insights" element={<Insight />} />
              <Route path="/published-files" element={<PublishedFiles />} />
              <Route path="/sets" element={<Sets />} />
              <Route path="/image-upload" element={<ImageUpload />} />
              {/* <Route path="/submit-content" element={<SubmitContent />} /> */}
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/update-sets/:id" element={<UpdateSets />} />
              <Route path="/sets-list" element={<SetsList />} />


            </Route>
          </Route>
        </Routes>
      </Router>
      <ToastContainer
        limit={1}
        autoClose={3000}
        transition={Zoom}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        newestOnTop
      />

    </>
  );
}

export default App;
