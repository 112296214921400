import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postSetsMedia } from "../../api/create";
import { toast } from "react-toastify";


const initialState = {
    loading: false,
    storeResponseData: [],
    error: null
}

export const postMediaData = createAsyncThunk(
    "sets/postMediaData",
    async (params, { dispatch }) => {
        dispatch(addSetsRequest());
        const response = await postSetsMedia(params);
        if (response) {
            return response
        }

        dispatch(addSetsError(response?.error));
    }
)

const setsSlice = createSlice({
    name: "sets",
    initialState,
    reducers: {
        addSetsRequest: (state) => {
            state.loading = true;
        },
        addSetsData: (state, action) => {
            state.storeResponseData = action.payload;
            state.loading = false;
        },
        addSetsError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export default setsSlice.reducer;
export const {
    addSetsRequest,
    addSetsData,
    addSetsError
} = setsSlice.actions;