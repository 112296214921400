import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTopSalesApi } from "../../api/list";
import { toast } from "react-toastify";


const initalState = {
    loading: false,
    storeResponseData: [],
    error: null
}

export const fetchTopSales = createAsyncThunk(
    "sales/fetchTopSales",
    async (params, { dispatch }) => {
        dispatch(addAllTopSales());
        const response = await getTopSalesApi(params);
        if (response?.data?.length > 0) {

            dispatch(addAllTopSalesData(response?.data));

            return response;
        } else {
            dispatch(addAllTopSalesError(response?.error));
            return response;
        }
    }
)



const allTopSalesSlice = createSlice({
    name: "topSales",
    initialState: initalState,
    reducers: {
        addAllTopSales: (state) => {
            state.loading = true
        },
        addAllTopSalesData: (state, action) => {
            state.storeResponseData = action.payload;

            state.loading = false;
        },
        addAllTopSalesError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export default allTopSalesSlice.reducer;

export const { addAllTopSales, addAllTopSalesData, addAllTopSalesError } = allTopSalesSlice.actions;
