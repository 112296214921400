import { deleteDataApi, deleteDataByIdApi, postDataApi } from "./actions";

import { URL_CONSTANTS } from "./urls";

// export const deleteOrders = (dataId) =>
//   deleteDataByIdApi(URL_CONSTANTS.Orders, dataId, "JSONSERVER");

// export const deleteFiltersById = (dataId) =>
//   deleteDataByIdApi(URL_CONSTANTS.filters, dataId);

// export const deleteMultipleProducts = (params) =>
//   postDataApi(URL_CONSTANTS.MultipleProductDelete, params);

// export const DeleteProduct = (dataId) =>
//   deleteDataByIdApi(URL_CONSTANTS.postProduct, dataId, "PRODUCT");

// export const deleteUser = (dataId) =>
//   deleteDataByIdApi(URL_CONSTANTS.users, dataId);

// export const deleteCategoryById = (dataId) =>
//   deleteDataByIdApi(URL_CONSTANTS.categories, dataId);

// export const deleteBannerById = (dataId) =>
//   deleteDataByIdApi(URL_CONSTANTS.CMS, dataId);

// export const deleteProduct = (dataId) =>
//   deleteDataByIdApi(URL_CONSTANTS.postProducts, dataId);

// export const deleteBanner = (dataId, params) =>
//   deleteDataByIdApi(URL_CONSTANTS.deleteBanner, dataId, params);

// export const deleteFeatureCategory = (dataId) =>
//   deleteDataByIdApi(URL_CONSTANTS.deleteFaturedCatagory, dataId);
// export const deleteCategoryFilter = (dataId) =>
//   deleteDataByIdApi(URL_CONSTANTS.deleteFilter, dataId);

// export const deleteStock = (dataId) =>
//   deleteDataByIdApi(URL_CONSTANTS.stockDelete, dataId);
// export const deleteMultipleStocks = (params) =>
//   deleteDataApi(URL_CONSTANTS.stockBatchDelete, params);
// export const deleteMainCategory = (dataId) =>
//   deleteDataByIdApi(URL_CONSTANTS.deleteMainCategory, dataId);
export const deleteTeamUser = (dataId) =>
  deleteDataByIdApi(URL_CONSTANTS.deleteUser, dataId);
export const multiDeleteLead = (params) =>
  postDataApi(URL_CONSTANTS.patchDeleteLead, params);
export const deleteLead = (dataId) =>
  deleteDataByIdApi(URL_CONSTANTS.deleteLead, dataId);
export const deleteOrg = (dataId) =>
  deleteDataByIdApi(URL_CONSTANTS.deleteOrg, dataId);
export const multiDeleteOrg = (params) =>
  postDataApi(URL_CONSTANTS.batchDeleteOrg, params);
export const deleteMeeting = (dataId) =>
  deleteDataByIdApi(URL_CONSTANTS.deleteMeeting, dataId);
export const deleteMail = (dataId) =>
  deleteDataByIdApi(URL_CONSTANTS.deleteMail, dataId);


export const deleteAsset = (dataId) =>
  deleteDataByIdApi(URL_CONSTANTS.delete, dataId);